import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import storesService from './storesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  stores: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewStores = createAsyncThunk(
  'stores/viewStores',
  async (_, thunkAPI) => {
    try {
      const response = await storesService.viewStores()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addStore = createAsyncThunk(
  'stores/addStore',
  async (data, thunkAPI) => {
    try {
      const response = await storesService.addStore(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewStore = createAsyncThunk(
  'stores/viewStore',
  async (data, thunkAPI) => {
    try {
      const response = await storesService.viewStore(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateStore = createAsyncThunk(
  'stores/updateStore',
  async (data, thunkAPI) => {
    try {
      const response = await storesService.updateStore(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteStore = createAsyncThunk(
  'stores/deleteStore',
  async (data, thunkAPI) => {
    try {
      const response = await storesService.deleteStore(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchStores = createAsyncThunk(
  'stores/searchStores',
  async (data, thunkAPI) => {
    try {
      const response = await storesService.searchStores(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.stores = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewStores.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.stores = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewStores"
        state.time = Date.now()
      })
      .addCase(viewStores.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = (action.payload)[0].stores
        state.translatedStore = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewStores.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addStore.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addStore"
        state.time = Date.now()
      })
      .addCase(addStore.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = [...state.stores, (action.payload)[0].store]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addStore.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewStore.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewStore"
        state.time = Date.now()
      })
      .addCase(viewStore.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = state.stores.map(store => {
          if (store.id === (action.payload)[0].store.id) {
            return (action.payload)[0].store
          }
          else {
            return store
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewStore.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateStore.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateStore"
        state.time = Date.now()
      })
      .addCase(updateStore.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = state.stores.map(store => {
          if (store.id === (action.payload)[0].store.id) {
            return (action.payload)[0].store
          }
          else {
            return store
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateStore.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteStore.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteStore"
        state.time = Date.now()
      })
      .addCase(deleteStore.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = state.stores.filter(store => {
          return store.id !== (action.payload)[0].store.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteStore.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchStores.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.stores = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchStores"
        state.time = Date.now()
      })
      .addCase(searchStores.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.stores = (action.payload)[0].stores
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchStores.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = storesSlice.actions
export default storesSlice.reducer