import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from '../../Redux/Features/Cart/cartSlice'
import { addFavorite } from '../../Redux/Features/Favorites/favoritesSlice'
import { FaArrowRight, FaStar, FaRegStar, FaSpinner, FaTrashAlt, FaHeartBroken } from 'react-icons/fa'
import { BiShoppingBag, BiHeart } from 'react-icons/bi'
import { toastify } from '../../Helper'

const FavoriteCard = ({ favorite, target }) => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth);
    const favoritesState = useSelector((state) => state.favorites);

    const addProductAlt = () => {
        dispatch(addProduct({ product: favorite }))
    }

    const addFavoriteAlt = () => {
        dispatch(addFavorite({ id: favorite.id }))
    }

    useEffect(() => {
        // if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 200 && favoritesState.message && favoritesState.operation === "addFavorite") {
        //     // toastify("success", favoritesState.status, favoritesState.message)
        // }
        // else if ((Date.now() - favoritesState.time < 100) && (favoritesState.status === 400 || favoritesState.status === 401 || favoritesState.status === 403 || favoritesState.status === 404) && favoritesState.message && (favoritesState.operation === "addFavorite")) {
        //     toastify("error", favoritesState.status, favoritesState.message)
        // }
        // else if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 500 && favoritesState.message && (favoritesState.operation === "addFavorite")) {
        //     toastify("warn", favoritesState.status, favoritesState.message)
        //     setTimeout(() => {
        //         // navigate(0)
        //     }, 5000);
        // }
    }, [favoritesState.status, favoritesState.message, favoritesState.errors])

    return (
        <div className=" text-dark dark:text-light rounded-md border border-queen/25 dark:border-king/25 relative overflow-hidden group shadow-inner shadow-queen/10 dark:shadow-king/10">
            <div className=' flex justify-center items-center h-[250px]'>
                <img className='w-3/4 mx-auto' src={process.env.REACT_APP_API_BASE_URL + "/" + favorite.images[0]} alt={favorite.name} />
            </div>
            <div className='bg-queen/[0.025] dark:bg-king/[0.025] flex gap-1 flex-col px-2 py-4 border-t border-queen/25 dark:border-king/25 h-full'>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-lg font-semibold truncate'>{favorite.name}</span>
                    <Link to={`/products/${favorite.id}`}>
                        <FaArrowRight className='text-base font-normal opacity-75 hover:opacity-100 transition-all duration-150 text-l-extra dark:text-d-extra' />
                    </Link>
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-base font-normal truncate'>{favorite.category.type.brand.name}</span>
                    <span className='text-base font-medium flex justify-between items-center gap-2'>
                        {
                            parseFloat(favorite.price.oldPrice) ?
                                <span className='text-dark/50 dark:text-light/50 relative' style={{ textDecoration: 'line-through' }}>
                                    ${parseFloat(favorite.price.oldPrice)}
                                </span> : null
                        }
                        <span>${favorite.price.newPrice}</span>
                    </span>
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-sm font-normal truncate'>
                        {favorite.rating?.length || 0} Reviews
                    </span>
                    <div className='flex gap-1 items-center text-queen dark:text-king'>
                        {
                            favorite.rating && Array(Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                return <FaStar />;
                            })
                        }
                        {
                            favorite.rating ?
                                Array(5 - Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                    return <FaRegStar />;
                                }) :
                                Array(5).fill(0).map((star) => {
                                    return <FaRegStar />;
                                })
                        }
                    </div>
                </div>
            </div>
            <div className='transition-all duration-150 w-10 h-[100px] absolute top-20 -right-24 group-hover:right-0 bg-queen dark:bg-king flex flex-col items-center justify-center gap-4 text-light'>
                <button onClick={() => addProductAlt()}>
                    <BiShoppingBag className='text-2xl opacity-75 hover:opacity-100 transition-all duration-150' />
                </button>
                <button onClick={() => addFavoriteAlt()}>
                    {
                        favoritesState.isLoading && favoritesState.operation === "addFavorite" ?
                            <FaSpinner className='text-xl animate-spin' /> :
                            <FaTrashAlt className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' />
                    }
                </button>
            </div>
            <div className=' z-10 transition-all duration-150 w-10 h-[100px] absolute -top-[6px] -right-24 group-hover:-right-[20px] bg-queen dark:bg-king rotate-[26deg]'></div>
            <div className=' z-10 transition-all duration-150 w-10 h-[100px] absolute top-[162px] -right-24 group-hover:-right-[22px] bg-queen dark:bg-king -rotate-[30deg]'></div>
            {
                target === "new" ?
                    <div className='absolute -top-10 left-6 w-8 h-40 rotate-[45deg] bg-l-extra dark:bg-d-extra text-end' style={{ letterSpacing: "2px" }}>
                        <p className=' absolute top-[50%] -translate-y-[50%] -translate-x-1 left-0 -rotate-90 text-lg text-light font-medium uppercase'>{target}</p>
                    </div> :
                    target === "offer" ?
                        <div className='absolute -top-10 left-6 w-8 h-40 rotate-[45deg] bg-l-extra dark:bg-d-extra text-end' style={{ letterSpacing: "2px" }}>
                            <p className=' absolute top-[50%] -translate-y-[50%] -translate-x-3 left-0 -rotate-90 text-lg text-light font-medium uppercase'>{target}</p>
                        </div> :
                        null
            }
        </div>
    )
}

export default FavoriteCard