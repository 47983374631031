import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { viewOrders } from '../../Redux/Features/Orders/ordersSlice'
import { FaCaretDown, FaCaretRight, FaCaretUp } from 'react-icons/fa'
import { toastify } from '../../Helper'
import LayoutLoading from '../LayoutLoading';
import LayoutNotFound from '../LayoutNotFound'
import OrderProducts from '../Profile/OrderProducts'

const ProfileOrders = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ordersState = useSelector((state) => state.orders);

    const [openList, setOpenList] = useState("")

    useEffect(() => {
        dispatch(viewOrders())
    }, [])

    useEffect(() => {
        if ((Date.now() - ordersState.time < 100) && ordersState.status === 200 && ordersState.message && ordersState.operation === "viewOrders") {
            // toastify("success", ordersState.status, ordersState.message)
        }
        else if ((Date.now() - ordersState.time < 100) && (ordersState.status === 400 || ordersState.status === 401 || ordersState.status === 403) && ordersState.message && ordersState.operation === "viewOrders") {
            toastify("error", ordersState.status, ordersState.message)
        }
        else if ((Date.now() - ordersState.time < 100) && ordersState.status === 500 && ordersState.message && ordersState.operation === "viewOrders") {
            toastify("warn", ordersState.status, ordersState.message)
            setTimeout(() => {
                navigate(0)
            }, 5000);
        }
    }, [ordersState.status, ordersState.message, ordersState.errors])

    return (
        <>
            {
                ordersState.isLoading ?
                    <LayoutLoading title={"orders"} /> :
                    ordersState.orders.length === 0 ?
                        <LayoutNotFound title={"Orders"} /> :
                        ordersState.orders.length === 0 ?
                            <p className='text-lg md:text-xl text-dark dark:text-light font-medium'>The orders list is currently empty, Please add some products to start your shopping experience.</p> :
                            <table className="w-full text-left text-dark dark:text-light">
                                <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-semibold">
                                    <tr>
                                        <th scope="col" className="px-4 pb-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 pb-2">
                                            Products
                                        </th>
                                        <th scope="col" className="px-4 pb-2">
                                            Total Price
                                        </th>
                                        <th scope="col" className="px-4 pb-2">
                                            Ship Address
                                        </th>
                                        <th scope="col" className="px-4 pb-2">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        ordersState.orders.map((order, idx) => {
                                            return (
                                                <>
                                                    <tr className="border-b border-queen/25 dark:border-king/25">
                                                        <td className="px-4 py-2">
                                                            <div className='flex items-center gap-2'>
                                                                {order.identifier}
                                                                <button onClick={() => setOpenList(openList === order.identifier ? "" : order.identifier)}>
                                                                    {
                                                                        openList === order.identifier ?
                                                                            <FaCaretUp className='text-2xl text-l-extra dark:text-d-extra hover:opacity-80 transition-all duration-150' /> :
                                                                            <FaCaretDown className='text-2xl text-l-extra dark:text-d-extra hover:opacity-80 transition-all duration-150' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td className="px-4 py-2">{order.products.length}</td>
                                                        <td className="px-4 py-2">${order.products.reduce((accumulator, product) => accumulator + (product.quantity * product.unitPrice), 0)}</td>
                                                        <td className="px-4 py-2">{order.ship_address}</td>
                                                        <td className="px-4 py-2 capitalize">
                                                            <span className='text-xs md:text-sm font-normal px-2 py-1 bg-l-extra dark:bg-d-extra text-light shadow-inner shadow-light/25 rounded-sm'>{order.status}</span>
                                                            {/* {order.status} */}
                                                        </td>
                                                    </tr>
                                                    {
                                                        openList === order.identifier ?
                                                            <td colspan="5" className='bg-queen/10 dark:bg-king/10'>
                                                                <OrderProducts products={order.products} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
            }
        </>
    )
}

export default ProfileOrders