import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import Profile from '../Layouts/Profile'
import Favorites from '../Layouts/Favorites/Favorites'
import NotFound from '../Layouts/NotFound'
import Header from '../Components/Header';
import Footer from '../Components/Footer';

const UserRoutes = () => {
    const authState = useSelector((state) => state.auth);
    const routesArray = [
        { path: '/profile', element: Profile },
        { path: '/favorites', element: Favorites },
    ]
    return (
        <>
            {
                authState.auth && authState.auth.user?.role === "user" ?
                    <>
                        <Header />
                        <div className='min-h-[100vh] my-12 lg:my-16'>
                            <Routes>
                                <Route index element={<Profile />} />
                                {
                                    routesArray.map((route) => {
                                        return <Route key={route.path} path={route.path} element=<route.element /> />
                                    })
                                }
                                <Route path="*" element={<NotFound reDirTo={"/user"} />} />
                            </Routes>
                        </div>
                        <Footer />
                    </> :
                    <>
                        <Navigate to="/login" />
                    </>
            }
        </>
    )
}
export default UserRoutes