import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from "../Redux/Features/Auth/authSlice";
import { Input, Ripple, initTE } from "tw-elements";
import { FaCaretRight, FaSpinner, FaTachometerAlt } from 'react-icons/fa';
import { toastify } from '../Helper';
import logo from '../Images/logo.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const emailInput = useRef();
  const passwordInput = useRef();

  const loginAlt = () => {
    let data = {
      email: emailInput.current.value,
      password: passwordInput.current.value,
    }

    dispatch(login(data))
  }

  useEffect(() => {
    initTE({ Input, Ripple });
    if (authState.auth && authState.auth.user?.role) {
      toastify("warn", 403, "Location is locked by your role")
      navigate(`/${(authState.auth.user?.role).toLowerCase()}`)
    }

    if (authState.status === 200 && authState.message && authState.operation === "logout") {
      // toastify("success", authState.status, authState.message)
      dispatch(reset())
    }
  }, [])

  useEffect(() => {
    if ((Date.now() - authState.time < 100) && authState.status === 200 && authState.message && authState.operation === "login") {
      // toastify("success", authState.status, authState.message)
      if (authState.auth.user?.role === "admin") {
        // setTimeout(() => {
        navigate("/admin")
        // }, 1000);
      } else if (authState.auth.user?.role === "user") {
        // setTimeout(() => {
        navigate("/user")
        // }, 1000);
      }
    }
    else if ((Date.now() - authState.time < 100) && (authState.status === 400 || authState.status === 401 || authState.status === 403) && authState.message && authState.operation === "login") {
      toastify("error", authState.status, authState.message)
    }
    else if ((Date.now() - authState.time < 100) && authState.status === 500 && authState.message && authState.operation === "login") {
      toastify("warn", authState.status, authState.message)
      setTimeout(() => {
        navigate(0)
      }, 5000);
    }
  }, [authState.status, authState.message, authState.errors])

  return (
    <div className='mx-auto p-6 md:p-12 lg:p-20'>
      <div className="rounded-md bg-queen/5 dark:bg-king/5 shadow-inner shadow-queen/25 dark:shadow-king/25 mx-auto w-full sm:max-w-sm px-2 md:px-4 py-2 md:py-4">
        <div className="text-queen dark:text-king text-8xl font-semibold flex justify-center items-center my-4">
          {/* <img
            className="mx-auto w-24"
            src={logo}
            alt="logo"
          /> */}
          <FaTachometerAlt className="text-lg md:text-2xl font-medium mr-1" />
          <span className="font-semibold text-lg lg:text-2xl">WMK Tech</span>
        </div>
        <h2 className='text-lg md:text-xl text-dark dark:text-light flex items-center gap-0.5 font-semibold mt-4 md:mt-8 mb-2 md:mb-4'>
          <FaCaretRight className='' />
          Login to your account:
        </h2>
        <div className='flex flex-col gap-2'>
          <div>
            <div className="relative mb-1" data-te-input-wrapper-init="">
              <input
                ref={emailInput}
                type="email"
                className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-transparent px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                id="email"
                placeholder="Email"
              />
              <label
                htmlFor="email"
                className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
              >
                Email
              </label>
              <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
              </div>
            </div>
            {
              authState.errors && authState.errors.email && authState.operation === "login" ?
                <span className='text-danger text-sm font-medium ml-1'>{authState.errors.email}</span> :
                null
            }
          </div>
          <div>
            <div className="relative mb-1" data-te-input-wrapper-init="">
              <input
                ref={passwordInput}
                type="password"
                className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-transparent px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                id="password"
                placeholder="Password"
              />
              <label
                htmlFor="password"
                className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
              >
                Password
              </label>
              <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
              </div>
            </div>
            {
              authState.errors && authState.errors.password && authState.operation === "login" ?
                <span className='text-danger text-sm font-medium ml-1'>{authState.errors.password}</span> :
                null
            }
          </div>
        </div>
        <button
          onClick={() => { loginAlt() }}
          className="my-8 text-base md:text-lg font-semibold w-full px-6 py-2 md:my-8 bg-queen dark:bg-king text-light cursor-pointer shadow-inner shadow-light/75 rounded-sm flex justify-evenly items-center gap-2 hover:opacity-90 transition-all duration-150 group"
          type="button"
          data-te-ripple-init=""
          data-te-ripple-color="light"
          style={{ letterSpacing: "2px" }}
        >
          {
            authState.isLoading && authState.operation === "login" ?
              <FaSpinner className='inline animate-spin text-2xl' />
              :
              <span>Login</span>
          }
        </button>
        <div className="flex items-center justify-between gap-2 flex-wrap">
          <a style={{ letterSpacing: "2px" }} className='text-dark dark:text-light border-b border-dark/10 dark:border-light/10 text-xs md:text-sm font-medium pb-0.5 hover:text-queen hover:border-queen/10 dark:hover:text-king dark:hover:border-king/10 transition-all duration-150' href="/register">Don't have account?</a>
          <a style={{ letterSpacing: "2px" }} className='text-dark dark:text-light border-b border-dark/10 dark:border-light/10 text-xs md:text-sm font-medium pb-0.5 hover:text-queen hover:border-queen/10 dark:hover:text-king dark:hover:border-king/10 transition-all duration-150' href="/forgot-password">Forgot Password?</a>
        </div>
      </div>
    </div>
  )
}

export default Login