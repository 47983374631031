import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewService } from '../../Redux/Features/Services/servicesSlice';
import { toastify } from '../../Helper';
import LayoutBanner from '../../Components/LayoutBanner';
import Container from '../../Components/Container';
import ServiceImage from '../../Components/Services/ServiceImage';
import ServiceDetails from '../../Components/Services/ServiceDetails';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';

const Service = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const servicesState = useSelector((state) => state.services);
    const [service, setService] = useState(null)

    useEffect(() => {
        dispatch(viewService({ id }))
    }, [dispatch, id])

    useEffect(() => {
        const service = (servicesState.services.filter((service) => {
            return parseInt(service.id) === parseInt(id)
        }))[0]
        setService(service)
    }, [servicesState.services])

    useEffect(() => {
        if ((Date.now() - servicesState.time < 100) && servicesState.status === 200 && servicesState.message && servicesState.operation === "viewService") {
            // toastify("success", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && (servicesState.status === 400 || servicesState.status === 401 || servicesState.status === 403 /*|| servicesState.status === 404*/) && servicesState.message && (servicesState.operation === "viewService")) {
            toastify("error", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && servicesState.status === 500 && servicesState.message && (servicesState.operation === "viewService")) {
            toastify("warn", servicesState.status, servicesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [servicesState.status, servicesState.message, servicesState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Services", to: "/services" }, { title: service?.title || "Unknown", to: `/services/${id}` }]} />
            {
                servicesState.isLoading && servicesState.operation === "viewService" ?
                    <LayoutLoading title={"service"} /> :
                    !service ?
                        <LayoutNotFound title={"Service"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Services", to: "/services" }, { title: service.title, to: `/services/${id}` }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4">
                                        <ServiceImage service={service} />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <ServiceDetails service={service} />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Service