import React from 'react'

const FavoriteUsers = ({ users }) => {
    return (
        <div className="mx-auto max-w-6xl overflow-x-auto p-1">
            {
                <table className="w-full text-left bg-queenAlt dark:bg-kingAlt text-dark dark:text-light">
                    <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-bold">
                        <tr>
                            <th scope="col" className="px-4 py-2">
                                Favorited by user
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Phone
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Email
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Address
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Is Trust
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-sm md:text-base'>
                        {
                            users.map((user, idx) => {
                                return (
                                    <tr className="border-b border-queen/25 dark:border-king/25">
                                        <td className="px-4 py-2 ">{user.name}</td>
                                        <td className="px-4 py-2 ">{user.phone}</td>
                                        <td className="px-4 py-2 ">{user.email}</td>
                                        <td className="px-4 py-2 ">{user.address}</td>
                                        <td className="px-4 py-2 ">{user.is_trust == "0" ? "No" : "Yes"}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div >
    )
}

export default FavoriteUsers