import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const SectionLink = ({ title, to }) => {
    return (
        // <Link to={to} className='text-base font-semibold flex gap-2 justify-center items-center text-light bg-queen dark:bg-king border border-transparent w-fit mx-auto px-4 py-2 rounded-md hover:border-queen dark:hover:border-king hover:bg-queen/[0.025] dark:hover:bg-king/[0.025] hover:text-queen dark:hover:text-king duration-150'>
        //     {title}
        //     <FaArrowRight />
        // </Link>
        <Link to={to} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent w-fit mx-auto px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
            {title}
            <FaArrowRight />
        </Link>
    )
}

export default SectionLink