import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct } from '../../Redux/Features/Cart/cartSlice'
import { viewProduct } from '../../Redux/Features/Products/productsSlice'
import { addFavorite } from '../../Redux/Features/Favorites/favoritesSlice'
import { FaArrowRight, FaStar, FaRegStar, FaSpinner, FaHeart, FaRegHeart, FaTimes, FaTree } from 'react-icons/fa'
import { BiShoppingBag, BiHeart } from 'react-icons/bi'
import { toastify } from '../../Helper'

const ProductCard = ({ product, target }) => {
    const dispatch = useDispatch()
    const authState = useSelector((state) => state.auth);
    const productsState = useSelector((state) => state.products);
    const favoritesState = useSelector((state) => state.favorites);

    const addProductAlt = () => {
        dispatch(addProduct({ product }))
    }

    const addFavoriteAlt = () => {
        dispatch(addFavorite({ id: product.id }))
    }

    useEffect(() => {
        if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 200 && favoritesState.message && favoritesState.operation === "addFavorite") {
            if (favoritesState.target == product.id) {
                // toastify("success", favoritesState.status, favoritesState.message)
                setTimeout(() => {
                    dispatch(viewProduct({ id: product.id }))
                }, 1000);
            }
        }
        else if ((Date.now() - favoritesState.time < 100) && (favoritesState.status === 400 || favoritesState.status === 401 || favoritesState.status === 403 || favoritesState.status === 404) && favoritesState.message && (favoritesState.operation === "addFavorite")) {
            toastify("error", favoritesState.status, favoritesState.message)
        }
        else if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 500 && favoritesState.message && (favoritesState.operation === "addFavorite")) {
            toastify("warn", favoritesState.status, favoritesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [favoritesState.status, favoritesState.message, favoritesState.errors])

    return (
        <div className=" text-dark dark:text-light rounded-md border border-queen/25 dark:border-king/25 relative overflow-hidden group shadow-inner shadow-queen/10 dark:shadow-king/10">
            <div className=' flex justify-center items-center min-h-[250px]'>
                <img className='w-3/4 mx-auto' src={process.env.REACT_APP_API_BASE_URL + "/" + product.images[0]} alt={product.name} />
            </div>
            <div className='bg-queen/[0.025] dark:bg-king/[0.025] flex gap-1 flex-col px-2 py-4 border-t border-queen/25 dark:border-king/25 h-full'>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-lg font-semibold truncate'>{product.name}</span>
                    <Link to={`/products/${product.id}`}>
                        <FaArrowRight className='text-base font-normal opacity-75 hover:opacity-100 transition-all duration-150 text-l-extra dark:text-d-extra' />
                    </Link>
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-base font-normal truncate'>{product.category.type.brand.name}</span>
                    <span className='text-base font-medium flex justify-between items-center gap-2'>
                        {
                            parseFloat(product.price.oldPrice) ?
                                <span className='text-dark/50 dark:text-light/50 relative' style={{ textDecoration: 'line-through' }}>
                                    ${parseFloat(product.price.oldPrice)}
                                </span> : null
                        }
                        <span>${product.price.newPrice}</span>
                    </span>
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-sm font-normal truncate'>
                        {product.rating?.length || 0} Reviews
                    </span>
                    <div className='flex gap-1 items-center text-queen dark:text-king'>
                        {
                            product.rating && Array(Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                                return <FaStar />;
                            })
                        }
                        {
                            product.rating ?
                                Array(5 - Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                                    return <FaRegStar />;
                                }) :
                                Array(5).fill(0).map((star) => {
                                    return <FaRegStar />;
                                })
                        }
                    </div>
                </div>
            </div>
            <div className={` ${productsState.isLoading && productsState.operation === "viewProduct" ? "hidden" : ""} transition-all duration-150 w-10 h-[100px] absolute top-20 -right-24 group-hover:right-0 bg-queen dark:bg-king flex flex-col items-center justify-center gap-4 text-light `}>
                <button onClick={() => addProductAlt()}>
                    <BiShoppingBag className='text-2xl opacity-75 hover:opacity-100 transition-all duration-150' />
                </button>
                {
                    authState.auth && authState.auth.user && authState.auth.user.role === "user" ?
                        <button onClick={() => addFavoriteAlt()}>
                            {
                                favoritesState.isLoading && favoritesState.operation === "addFavorite" ?
                                    <FaSpinner className='text-xl animate-spin' /> :
                                    product.users?.find(user => user.id == authState.auth.user.id) ?
                                        // <FaHeart className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' /> :
                                        // <FaRegHeart className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' />
                                        <FaHeart className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' /> :
                                        <FaRegHeart className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' />
                            }
                        </button> :
                        authState.auth && authState.auth.user && authState.auth.user.role === "admin" ?
                            <FaRegHeart className='text-xl opacity-50' /> :
                            <Link to="/login" >
                                <FaRegHeart className='text-xl opacity-75 hover:opacity-100 transition-all duration-150' />
                            </Link>
                }
            </div>
            <div className={` ${productsState.isLoading && productsState.operation === "viewProduct" ? "hidden" : ""} z-10 transition-all duration-150 w-10 h-[100px] absolute -top-[6px] -right-24 group-hover:-right-[20px] bg-queen dark:bg-king rotate-[26deg]`}></div>
            <div className={` ${productsState.isLoading && productsState.operation === "viewProduct" ? "hidden" : ""} z-10 transition-all duration-150 w-10 h-[100px] absolute top-[162px] -right-24 group-hover:-right-[22px] bg-queen dark:bg-king -rotate-[30deg] `}></div>
            {
                target === "new" ?
                    <div className='absolute -top-10 left-6 w-8 h-40 rotate-[45deg] bg-l-extra dark:bg-d-extra text-end' style={{ letterSpacing: "2px" }}>
                        <p className=' absolute top-[50%] -translate-y-[50%] -translate-x-1 left-0 -rotate-90 text-lg text-light font-medium uppercase'>{target}</p>
                    </div> :
                    target === "offer" ?
                        <div className='absolute -top-10 left-6 w-8 h-40 rotate-[45deg] bg-l-extra dark:bg-d-extra text-end' style={{ letterSpacing: "2px" }}>
                            <p className=' absolute top-[50%] -translate-y-[50%] -translate-x-3 left-0 -rotate-90 text-lg text-light font-medium uppercase'>{target}</p>
                        </div> :
                        null
            }
        </div>
    )
}

export default ProductCard