import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { viewSlides } from "../../Redux/Features/Slides/slidesSlice";
import { Carousel, initTE, Ripple } from "tw-elements";
import { toastify } from '../../Helper';
import SectionHead from '../SectionHead';

const Slider = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const slidesState = useSelector((state) => state.slides);

    useEffect(() => {
        dispatch(viewSlides())
    }, [])

    useEffect(() => {
        if ((Date.now() - slidesState.time < 100) && slidesState.status === 200 && slidesState.message && (slidesState.operation === "viewSlides")) {
            // toastify("success", slidesState.status, slidesState.message)
            initTE({ Carousel, Ripple })
        }
        else if ((Date.now() - slidesState.time < 100) && (slidesState.status === 400 || slidesState.status === 401 || slidesState.status === 403 || slidesState.status === 404) && slidesState.message && (slidesState.operation === "viewSlides")) {
            // toastify("error", slidesState.status, slidesState.message)
        }
        else if ((Date.now() - slidesState.time < 100) && slidesState.status === 500 && slidesState.message && (slidesState.operation === "viewSlides")) {
            toastify("warn", slidesState.status, slidesState.message)
            setTimeout(() => {
                navigate(0)
            }, 5000);
        }
    }, [slidesState.status, slidesState.message, slidesState.errors])

    return (
        <>
            {
                slidesState.isLoading ?
                    <div className='pt-[50px]'><SectionHead title={"Awesome Slides"} /></div> :
                    slidesState.slides.length === 0 ?
                        null :
                        <div
                            id="carouselExampleCaptions"
                            className="relative w-full h-[50vh] overflow-hidden mx-auto"
                            data-te-carousel-init=""
                            data-te-ride="carousel"
                        >
                            <div
                                className="absolute bottom-0 left-0 right-0 z-[2] mx-[15%] mb-4 flex list-none justify-center p-0"
                                data-te-carousel-indicators=""
                            >
                                {
                                    slidesState.slides.map((slide, idx) => {
                                        if (idx === 0) {
                                            return (
                                                <button
                                                    type="button"
                                                    data-te-target="#carouselExampleCaptions"
                                                    data-te-slide-to={idx}
                                                    data-te-carousel-active=""
                                                    className="mx-[4px] box-content h-[4px] w-[40px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-dark/75 dark:bg-light/75 bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                                                    aria-current="true"
                                                    aria-label={`Slide ${idx + 1}`}
                                                />
                                            )
                                        } else {
                                            return (
                                                <button
                                                    type="button"
                                                    data-te-target="#carouselExampleCaptions"
                                                    data-te-slide-to={idx}
                                                    className="mx-[4px] box-content h-[4px] w-[40px] flex-initial cursor-pointer border-0 border-y-[10px] border-solid border-transparent bg-dark/75 dark:bg-light/75 bg-clip-padding p-0 -indent-[999px] opacity-50 transition-opacity duration-[600ms] ease-[cubic-bezier(0.25,0.1,0.25,1.0)] motion-reduce:transition-none"
                                                    aria-label={`Slide ${idx + 1}`}
                                                />
                                            )
                                        }
                                    })
                                }
                            </div>
                            <div className="relative w-full h-full overflow-hidden after:clear-both after:block after:content-['']">
                                {
                                    slidesState.slides.map((slide, idx) => {
                                        if (idx === 0) {
                                            return (
                                                <div key={idx}
                                                    className="slide relative float-start -me-[100%] hidden w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
                                                    data-te-carousel-active=""
                                                    data-te-carousel-item=""
                                                    style={{
                                                        backgroundColor: "gray",
                                                        backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + "/" + slide.image})`,
                                                        backfaceVisibility: "hidden",
                                                        backgroundPosition: "50% 50%",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover"
                                                    }}
                                                >
                                                    <div className="min-w-[250px] max-w-[750px] z-[1] absolute inset-x-[0%] md:inset-x-[15%] bottom-4 pt-4 pb-10 text-center text-xs sm:text-sm md:text-base text-dark/75 bg-queenAlt/75 dark:text-light/75 dark:bg-kingAlt/75 rounded-md shadow-inner shadow-dark/10 dark:shadow-light/10 md:w-fit mx-auto px-2 md:px-5 whitespace-break-spaces">
                                                        <p className='text-sm md:text-lg font-medium'>{slide.title}</p>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={idx}
                                                    className={`slide relative float-start -me-[100%] hidden w-full h-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none`}
                                                    data-te-carousel-item=""
                                                    style={{
                                                        backgroundColor: "gray",
                                                        backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + "/" + slide.image})`,
                                                        backfaceVisibility: "hidden",
                                                        backgroundPosition: "50% 50%",
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundSize: "cover"
                                                    }}
                                                >
                                                    <div className="min-w-[250px] max-w-[750px] z-[1] absolute inset-x-[0%] md:inset-x-[15%] bottom-4 pt-4 pb-10 text-center text-xs sm:text-sm md:text-base text-dark/75 bg-queenAlt/75 dark:text-light/75 dark:bg-kingAlt/75 rounded-md shadow-inner shadow-dark/10 dark:shadow-light/10 md:w-fit mx-auto px-2 md:px-5 whitespace-break-spaces">
                                                        <p className='text-sm md:text-lg font-medium'>{slide.title}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                            <button
                                className="hidden md:flex absolute bottom-0 left-0 top-0 z-[1]  w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-dark/75 opacity-50 transition-opacity duration-250 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-dark hover:no-underline hover:opacity-100 hover:outline-none focus:text-gray-700 focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                type="button"
                                data-te-target="#carouselExampleCaptions"
                                data-te-slide="prev"
                            >
                                <span className="w-14 h-14 rounded-md flex justify-center items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-14 h-14"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15.75 19.5L8.25 12l7.5-7.5"
                                        />
                                    </svg>
                                </span>
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Previous
                                </span>
                            </button>
                            <button
                                className="hidden md:flex  absolute bottom-0 right-0 top-0 z-[1] w-[15%] items-center justify-center border-0 bg-none p-0 text-center text-dark/75 opacity-50 transition-opacity duration-250 ease-[cubic-bezier(0.25,0.1,0.25,1.0)] hover:text-dark hover:no-underline hover:opacity-100 hover:outline-none focus:text-gray-700 focus:no-underline focus:opacity-90 focus:outline-none motion-reduce:transition-none"
                                type="button"
                                data-te-target="#carouselExampleCaptions"
                                data-te-slide="next"
                            >
                                <span className="w-14 h-14 rounded-md flex justify-center items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-14 h-14"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                        />
                                    </svg>
                                </span>
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Next
                                </span>
                            </button>
                        </div>
            }
        </>
    )
}

export default Slider