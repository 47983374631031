import { Routes, Route } from 'react-router-dom'
import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Home from '../Layouts/Home'
import Login from '../Layouts/Login'
import Register from '../Layouts/Register'
import ForgotPassword from '../Layouts/ForgotPassword'
import ResetPassword from '../Layouts/ResetPassword'
import About from '../Layouts/About'
import Products from '../Layouts/Products/Products'
import Product from '../Layouts/Products/Product'
import Services from '../Layouts/Services/Services'
import Service from '../Layouts/Services/Service'
import Jobs from '../Layouts/Jobs/Jobs'
import Job from '../Layouts/Jobs/Job'
import Advertises from '../Layouts/Advertises/Advertises'
// import Favorites from '../Layouts/Favorites/Favorites'
import Cart from '../Layouts/Cart/Cart'
import NotFound from '../Layouts/NotFound'

const WelcomeRoutes = () => {
    const routesArray = [
        { path: '/home', element: Home },
        { path: '/register', element: Register },
        { path: '/login', element: Login },
        { path: '/forgot-password', element: ForgotPassword },
        { path: '/reset-password', element: ResetPassword },
        { path: '/about', element: About },
        { path: '/products', element: Products },
        { path: '/products/:id', element: Product },
        { path: '/services', element: Services },
        { path: '/services/:id', element: Service },
        { path: '/jobs', element: Jobs },
        { path: '/jobs/:id', element: Job },
        { path: '/advertising', element: Advertises },
        // { path: '/favorites', element: Favorites },
        { path: '/cart', element: Cart },
    ];
    return (
        <>
            <Header />
            <div className='min-h-[100vh] my-12 lg:my-16'>
                <Routes>
                    <Route index element={<Home />} />
                    {
                        routesArray.map((route) => {
                            return <Route key={route.path} path={route.path} element=<route.element /> />
                        })
                    }
                    <Route path="*" element={<NotFound reDirTo={"/"} />} />
                </Routes>
            </div>
            <Footer />
        </>
    )
}
export default WelcomeRoutes