import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Chart, initTE } from "tw-elements";
import { FaArrowRight, FaBookmark, FaBriefcase, FaCubes, FaFileInvoice, FaFileInvoiceDollar, FaImages, FaPuzzlePiece, FaSitemap, FaStore, FaTag, FaUsers, FaUsersCog } from 'react-icons/fa';
import Container from '../../Components/Dashboard/Container'

const Dashborad = () => {
  const [data, setData] = useState("")
  const [ordersCount, setOrdersCount] = useState("")
  const [invoicesCount, setInvoicesCount] = useState("")

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/api/dashboard-informations', {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('auth'))?.token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data)
        setOrdersCount(`[${data.data.orders?.pending},${data.data.orders?.processing},${data.data.orders?.shipped},${data.data.orders?.delivered},${data.data.orders?.returned},${data.data.orders?.["out of stock"]}, ${data.data.orders?.rejected}]`)
        setInvoicesCount(`[${data.data.invoices?.paid},${data.data.invoices?.unpaid}]`)
        initTE({ Chart })
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  return (
    <Container>
      <div className='flex flex-col gap-12'>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4'>
          <div className='rounded-sm bg-queenAlt dark:bg-kingAlt shadow-inner shadow-light/25 p-4 flex flex-col gap-2'>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaBriefcase className='text-xl' />Our services: {data?.services || 0}</span>
              <Link to="/admin/services"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaImages className='text-xl' />Our slides: {data?.slides || 0}</span>
              <Link to="/admin/slides"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaStore className='text-xl' />Our stores: {data?.stores || 0}</span>
              <Link to="/admin/stores"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
          </div>
          <div className='rounded-sm bg-queenAlt dark:bg-kingAlt shadow-inner shadow-light/25 p-4 flex flex-col gap-2'>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaBookmark className='text-xl' />Our brands: {data?.brands || 0}</span>
              <Link to="/admin/brands"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaPuzzlePiece className='text-xl' />Our types: {data?.types || 0}</span>
              <Link to="/admin/types"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaTag className='text-xl' />Our categories: {data?.categories || 0}</span>
              <Link to="/admin/categories"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaCubes className='text-xl' />Our products: {data?.products || 0}</span>
              <Link to="/admin/products"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
          </div>
          <div className='rounded-sm bg-queenAlt dark:bg-kingAlt shadow-inner shadow-light/25 p-4 flex flex-col gap-2'>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaUsersCog className='text-xl' />Our admins: {data?.admins || 0}</span>
              <Link to="/admin/users"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaUsers className='text-xl' />Our users: {data?.users || 0}</span>
              <Link to="/admin/users"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaSitemap className='text-xl' />Our favorites: {data?.favorites || 0}</span>
              <Link to="/admin/favorites"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
          </div>
          <div className='rounded-sm bg-queenAlt dark:bg-kingAlt shadow-inner shadow-light/25 p-4 flex flex-col gap-2'>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaFileInvoice className='text-xl' />Yearly orders: ${data?.ordersYearCash || 0}</span>
              <Link to="/admin/orders"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaFileInvoice className='text-xl' />Monthly orders: ${data?.ordersMonthCash || 0}</span>
              <Link to="/admin/orders"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaFileInvoiceDollar className='text-xl' />Yearly invoices: ${data?.invoicesYearCash || 0}</span>
              <Link to="/admin/invoices"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
            <p className='text-base lg:text-lg font-medium text-dark dark:text-light flex justify-between items-center gap-4 group'>
              <span className='group-hover:text-queen group-hover:dark:text-king duration-150 flex items-center gap-2'><FaFileInvoiceDollar className='text-xl' />Monthly invoices: ${data?.invoicesMonthCash || 0}</span>
              <Link to="/admin/invoices"><FaArrowRight className='text-queen dark:text-king hover:opacity-80 duration-150 text-lg mt-1 cursor-pointer' /></Link>
            </p>
          </div>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
          <div class="">
            <canvas
              data-te-chart="bar"
              data-te-dataset-label="Count"
              data-te-labels='["Pending", "Processing", "Shipped", "Delivered", "Returned", "Out of stock", "Rejected"]'
              data-te-dataset-data={ordersCount}
              data-te-dataset-background-color='["#ef444480", "#ef4444"]'
            ></canvas>
            <span class="text-base lg:text-lg text-queen dark:text-king block text-center mt-2">Orders</span>
          </div>
          <div class="">
            <canvas
              data-te-chart="bar"
              data-te-dataset-label="Count"
              data-te-labels='["Paid", "Unpaid"]'
              data-te-dataset-data={invoicesCount}
              data-te-dataset-background-color='["#2196f380", "#2196f3"]'
            ></canvas>
            <span class="text-base lg:text-lg text-queen dark:text-king block text-center mt-2">Invoices</span>
          </div>
        </div>
      </div>
    </Container>
  )
} 

export default Dashborad