import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewReviews, searchReviews } from "../../../Redux/Features/Reviews/reviewsSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaChevronDown, FaChevronUp, FaSpinner } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';
import ReviewUsers from './ReviewUsers';

const Reviews = () => { 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reviewsState = useSelector((state) => state.reviews);
    const searchInput = useRef();
    const [openReview, setOpenReview] = useState(null)

    const searchReviewsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchReviews(data))
    }

    useEffect(() => {
        setOpenReview(reviewsState?.reviews[0]?.id)
    }, [reviewsState.reviews])

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewReviews())
    }, [])

    useEffect(() => {
        if ((Date.now() - reviewsState.time < 100) && reviewsState.status === 200 && reviewsState.message && (reviewsState.operation === "viewReviews" || reviewsState.operation === "searchReviews" || reviewsState.operation === "updateReview")) {
            // toastify("success", reviewsState.status, reviewsState.message)
        }
        else if ((Date.now() - reviewsState.time < 100) && (reviewsState.status === 400 || reviewsState.status === 401 || reviewsState.status === 403 /*|| reviewsState.status === 404*/) && reviewsState.message && (reviewsState.operation === "viewReviews" || reviewsState.operation === "searchReviews" || reviewsState.operation === "updateReview")) {
            toastify("error", reviewsState.status, reviewsState.message)
        }
        else if ((Date.now() - reviewsState.time < 100) && reviewsState.status === 500 && reviewsState.message && (reviewsState.operation === "viewReviews" || reviewsState.operation === "searchReviews" || reviewsState.operation === "updateReview")) {
            toastify("warn", reviewsState.status, reviewsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [reviewsState.status, reviewsState.message, reviewsState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Reviewes
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by user email or job identifier, position or description"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => { searchReviewsAlt() }}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    reviewsState.isLoading && reviewsState.operation !== "updateReview" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Identifier
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Position
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="5"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Reviews
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        reviewsState.reviews.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Position
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Description
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="5"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Reviews Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Position
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Description
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        reviewsState.reviews.map((review, idx) => {
                                            return (
                                                <>
                                                    <tr className="border-b border-queen/50 dark:border-king/50">
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{review.identifier}</td>
                                                        <td className="px-4 py-2">{review.position}</td>
                                                        <td className="px-4 py-2">{review.description}</td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                <button
                                                                    onClick={() => setOpenReview(openReview === review.id ? null : review.id)}
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                >
                                                                    {
                                                                        openReview === review.id ?
                                                                            <FaChevronUp className='text-base md:text-lg' /> :
                                                                            <FaChevronDown className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        openReview === review.id ?
                                                            <td colspan="5" className='bg-light dark:bg-dark'>
                                                                <ReviewUsers users={review.users} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Reviews