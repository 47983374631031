import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewJobs, searchJobs, deleteJob, updateJob, addJob } from "../../../Redux/Features/Jobs/jobsSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Jobs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const jobsState = useSelector((state) => state.jobs);
    const searchInput = useRef();
    const positionInput = useRef();
    const descriptionInput = useRef();
    const avgSalaryInput = useRef();
    const locationInput = useRef();
    const timeInput = useRef();
    const levelInput = useRef();
    const knowledgeInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchJobsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchJobs(data))
    }

    const addJobAlt = () => {
        let data = {
            position: positionInput.current.value,
            description: descriptionInput.current.value,
            avg_salary: avgSalaryInput.current.value,
            location: locationInput.current.value,
            time: timeInput.current.value,
            level: levelInput.current.value,
            knowledge: knowledgeInput.current.value,
        }

        dispatch(addJob(data))
    }

    const updateJobAlt = (job_id) => {
        let data = {
            position: positionInput.current.value,
            description: descriptionInput.current.value,
            avg_salary: avgSalaryInput.current.value,
            location: locationInput.current.value,
            time: timeInput.current.value,
            level: levelInput.current.value,
            knowledge: knowledgeInput.current.value,
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateJob({ data, id: job_id }))
        }
    }

    const deleteJobAlt = (job_id) => {
        const data = {
            id: job_id
        }

        dispatch(deleteJob(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewJobs())
    }, [])

    useEffect(() => {
        if ((Date.now() - jobsState.time < 100) && (jobsState.status === 200 || jobsState.status === 201) && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs" || jobsState.operation === "addJob" || jobsState.operation === "updateJob" || jobsState.operation === "deleteJob")) {
            // toastify("success", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && (jobsState.status === 400 || jobsState.status === 401 || jobsState.status === 403 /*|| jobsState.status === 404*/) && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs" || jobsState.operation === "addJob" || jobsState.operation === "updateJob" || jobsState.operation === "deleteJob")) {
            toastify("error", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && jobsState.status === 500 && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs" || jobsState.operation === "addJob" || jobsState.operation === "updateJob" || jobsState.operation === "deleteJob")) {
            toastify("warn", jobsState.status, jobsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [jobsState.status, jobsState.message, jobsState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Jobs
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by identifier, position or description"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchJobsAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    jobsState.isLoading && jobsState.operation !== "addJob" && jobsState.operation !== "updateJob" && jobsState.operation !== "deleteJob" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Identifier
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Position
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Average Salary
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Location
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Time
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Level
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Knowledge
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="10"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Jobs
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        jobsState.jobs.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={positionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Position' type='text' /> :
                                                    "Position"
                                            }
                                        </th>
                                        {/* <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th> */}
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <textarea rows={1} ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={avgSalaryInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Average Salary' type='number' /> :
                                                    "Average Salary"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={locationInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Location</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="on site">On Site</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="remotly">Remotly</option>
                                                    </select> :
                                                    "Location"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={timeInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Time</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="full time">Full Time</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="part time">Part Time</option>
                                                    </select> :
                                                    "Time"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={levelInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Level</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="senior">Senior</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="mid level">Mid Level</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="junior">Junior</option>
                                                    </select> :
                                                    "Level"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={knowledgeInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Knowledge' type='text' /> :
                                                    "Knowledge"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addJobAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && jobsState.isLoading && jobsState.operation === "addJob" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && jobsState.errors && jobsState.operation === "addJob" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.position && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.position : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.description && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.avg_salary && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.avg_salary : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.location && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.location : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.time && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.time : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.level && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.level : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.knowledge && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.knowledge : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="10"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Jobs Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={positionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Position' type='text' /> :
                                                    "Position"
                                            }
                                        </th>
                                        {/* <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th> */}
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <textarea rows={1} ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={avgSalaryInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Average Salary' type='number' /> :
                                                    "Average Salary"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={locationInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Location</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="on site">On Site</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="remotly">Remotly</option>
                                                    </select> :
                                                    "Location"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={timeInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Time</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="full time">Full Time</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="part time">Part Time</option>
                                                    </select> :
                                                    "Time"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={levelInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Level</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="senior">Senior</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="mid level">Mid Level</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="junior">Junior</option>
                                                    </select> :
                                                    "Level"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={knowledgeInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Knowledge' type='text' /> :
                                                    "Knowledge"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addJobAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && jobsState.isLoading && jobsState.operation === "addJob" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && jobsState.errors && jobsState.operation === "addJob" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.position && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.position : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.description && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.avg_salary && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.avg_salary : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.location && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.location : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.time && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.time : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.level && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.level : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        jobsState.errors && jobsState.errors.knowledge && jobsState.operation === "addJob" ?
                                                            jobsState.errors && jobsState.errors.knowledge : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        jobsState.jobs.map((job, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === job.id && jobsState.errors && jobsState.operation === "updateJob" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{job.identifier}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <input ref={positionInput} defaultValue={job.position} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                                    job.position
                                                            }
                                                        </td>
                                                        {/* <td className="px-4 py-2">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <input ref={descriptionInput} defaultValue={job.description} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                                    job.description
                                                            }
                                                        </td> */}
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <textarea rows={1} ref={descriptionInput} defaultValue={job.description} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                                    job.description
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <input ref={avgSalaryInput} defaultValue={job.avg_salary} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Average Salary' type='number' /> :
                                                                    "$"+job.avg_salary
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 capitalize">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <select ref={locationInput} defaultValue={job.location} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Location</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="on site">On Site</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="remotly">Remotly</option>
                                                                    </select> :
                                                                    job.location
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 capitalize">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <select ref={timeInput} defaultValue={job.time} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Time</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="full time">Full Time</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="part time">Part Time</option>
                                                                    </select> :
                                                                    job.time
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 capitalize">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <select ref={levelInput} defaultValue={job.level} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Level</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="senior">Senior</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="mid level">Mid Level</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="junior">Junior</option>
                                                                    </select> :
                                                                    job.level
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === job.id ?
                                                                    <input ref={knowledgeInput} defaultValue={job.knowledge} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Knowledge' type='text' /> :
                                                                    job.knowledge
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === job.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(job.id); updateJobAlt(job.id); setShowUpdateErrors(job.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(job.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === job.id && jobsState.isLoading && jobsState.operation === "updateJob" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(job.id); deleteJobAlt(job.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === job.id && jobsState.isLoading && jobsState.operation === "deleteJob" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === job.id && jobsState.errors && jobsState.operation === "updateJob" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.position && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.position : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.description && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.description : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.avg_salary && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.avg_salary : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.location && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.location : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.time && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.time : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.level && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.level : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        jobsState.errors && jobsState.errors.knowledge && jobsState.operation === "updateJob" ?
                                                                            jobsState.errors && jobsState.errors.knowledge : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Jobs