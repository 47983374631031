import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBanner from '../../Components/LayoutBanner';
import { toastify } from '../../Helper';
import Container from '../../Components/Container';
import JobsSearch from '../../Components/Jobs/JobsSearch';
import JobsCards from '../../Components/Jobs/JobsCards';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';
import { viewJobs } from '../../Redux/Features/Jobs/jobsSlice';

const Jobs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const jobsState = useSelector((state) => state.jobs);

    useEffect(() => {
        dispatch(viewJobs())
    }, [])

    useEffect(() => {
        if ((Date.now() - jobsState.time < 100) && jobsState.status === 200 && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs")) {
            // toastify("success", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && (jobsState.status === 400 || jobsState.status === 401 || jobsState.status === 403 /*|| jobsState.status === 404*/) && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs")) {
            toastify("error", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && jobsState.status === 500 && jobsState.message && (jobsState.operation === "viewJobs" || jobsState.operation === "searchJobs")) {
            toastify("warn", jobsState.status, jobsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [jobsState.status, jobsState.message, jobsState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Jobs", to: "/jobs" }]} />
            {
                jobsState.isLoading && jobsState.operation === "viewJobs" ?
                    <LayoutLoading title={"jobs"} /> :
                    jobsState.jobs.length === 0 && jobsState.operation === "viewJobs" ?
                        <LayoutNotFound title={"Jobs"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Jobs", to: "/jobs" }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4">
                                        <JobsSearch />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <JobsCards />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Jobs