import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewUsers } from "../../../Redux/Features/Users/usersSlice";
import { viewAdvertises, searchAdvertises, deleteAdvertise, updateAdvertise, addAdvertise } from "../../../Redux/Features/Advertises/advertisesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Advertises = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usersState = useSelector((state) => state.users);
    const advertisesState = useSelector((state) => state.advertises);
    const searchInput = useRef();
    const captionInput = useRef();
    const userInput = useRef();
    const imageInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchAdvertisesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchAdvertises(data))
    }

    const addAdvertiseAlt = () => {
        let data = {
            caption: captionInput.current.value,
            image: imageInput.current.files[0],
            user_id: userInput.current.value,
        }

        dispatch(addAdvertise(data))
    }

    const updateAdvertiseAlt = (advertise_id) => {
        let data = {
            caption: captionInput.current.value,
            image: imageInput.current.files[0]
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateAdvertise({ data, id: advertise_id }))
        }
    }

    const deleteAdvertiseAlt = (advertise_id) => {
        const data = {
            id: advertise_id
        }

        dispatch(deleteAdvertise(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewUsers());
        dispatch(viewAdvertises());
    }, [])

    useEffect(() => {
        if ((Date.now() - advertisesState.time < 100) && (advertisesState.status === 200 || advertisesState.status === 201) && advertisesState.message && (advertisesState.operation === "viewAdvertises" || advertisesState.operation === "searchAdvertises" || advertisesState.operation === "addAdvertise" || advertisesState.operation === "updateAdvertise" || advertisesState.operation === "deleteAdvertise")) {
            // toastify("success", advertisesState.status, advertisesState.message)
        }
        else if ((Date.now() - advertisesState.time < 100) && (advertisesState.status === 400 || advertisesState.status === 401 || advertisesState.status === 403 /*|| advertisesState.status === 404*/) && advertisesState.message && (advertisesState.operation === "viewAdvertises" || advertisesState.operation === "searchAdvertises" || advertisesState.operation === "addAdvertise" || advertisesState.operation === "updateAdvertise" || advertisesState.operation === "deleteAdvertise")) {
            toastify("error", advertisesState.status, advertisesState.message)
        }
        else if ((Date.now() - advertisesState.time < 100) && advertisesState.status === 500 && advertisesState.message && (advertisesState.operation === "viewAdvertises" || advertisesState.operation === "searchAdvertises" || advertisesState.operation === "addAdvertise" || advertisesState.operation === "updateAdvertise" || advertisesState.operation === "deleteAdvertise")) {
            toastify("warn", advertisesState.status, advertisesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [advertisesState.status, advertisesState.message, advertisesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Advertises
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by user email or caption"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchAdvertisesAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    advertisesState.isLoading && advertisesState.operation !== "addAdvertise" && advertisesState.operation !== "updateAdvertise" && advertisesState.operation !== "deleteAdvertise" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        User
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Caption
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Image
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="5"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Advertises
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        advertisesState.advertises.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={userInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">User</option>
                                                        {
                                                            usersState.users.map((user) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={user.id}>{user.name} - {user.email}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "User"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={captionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Caption' type='text' /> :
                                                    "Caption"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addAdvertiseAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && advertisesState.isLoading && advertisesState.operation === "addAdvertise" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && advertisesState.errors && advertisesState.operation === "addAdvertise" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.user_id && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.user_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.caption && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.caption : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.image && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="5"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Advertising Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={userInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">User</option>
                                                        {
                                                            usersState.users.map((user) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={user.id}>{user.name} - {user.email}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "User"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={captionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Caption' type='text' /> :
                                                    "Caption"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addAdvertiseAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && advertisesState.isLoading && advertisesState.operation === "addAdvertise" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && advertisesState.errors && advertisesState.operation === "addAdvertise" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.user_id && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.user_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.caption && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.caption : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        advertisesState.errors && advertisesState.errors.image && advertisesState.operation === "addAdvertise" ?
                                                            advertisesState.errors && advertisesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        advertisesState.advertises.map((advertise, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === advertise.id && advertisesState.errors && advertisesState.operation === "updateAdvertise" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{advertise.user.email}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === advertise.id ?
                                                                    <input ref={captionInput} defaultValue={advertise.caption} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Caption' type='text' /> :
                                                                    advertise.caption
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === advertise.id ?
                                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                                    advertise.image ?
                                                                        <a target='_blank' href={process.env.REACT_APP_API_BASE_URL +"/"+ advertise.image} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View</a> :
                                                                        <span className='px-1'>...</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === advertise.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(advertise.id); updateAdvertiseAlt(advertise.id); setShowUpdateErrors(advertise.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(advertise.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === advertise.id && advertisesState.isLoading && advertisesState.operation === "updateAdvertise" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(advertise.id); deleteAdvertiseAlt(advertise.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === advertise.id && advertisesState.isLoading && advertisesState.operation === "deleteAdvertise" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === advertise.id && advertisesState.errors && advertisesState.operation === "updateAdvertise" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        advertisesState.errors && advertisesState.errors.caption && advertisesState.operation === "updateAdvertise" ?
                                                                            advertisesState.errors && advertisesState.errors.caption : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        advertisesState.errors && advertisesState.errors.image && advertisesState.operation === "updateAdvertise" ?
                                                                            advertisesState.errors && advertisesState.errors.image : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Advertises