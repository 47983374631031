import React from 'react'
import Container from './Container'
import { FaSpinner } from 'react-icons/fa'

const LayoutNotFound = ({ title }) => {
    return (
        <Container>
            <div className='flex justify-center items-center gap-1 md:gap-2 h-[250px]'>
                <div className='text-queen dark:text-king text-xl md:text-2xl animate-spin'>
                    <FaSpinner />
                </div>
                <h1 className='text-dark dark:text-light text-xl md:text-2xl'>
                    Loading {title}
                </h1>
            </div>
        </Container>
    )
}

export default LayoutNotFound