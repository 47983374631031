import React from 'react'
import { useSelector } from 'react-redux'
import LayoutBanner from '../../Components/LayoutBanner'
import Container from '../../Components/Container'
import CartItems from '../../Components/Cart/CartItems'
import CartDetails from '../../Components/Cart/CartDetails'
import LayoutNotFound from '../../Components/LayoutNotFound';

const Cart = () => {
    const cartState = useSelector((state) => state.cart);

    return (
        <div>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Cart", to: "/cart" }]} />
            {
                cartState.cart.length === 0 ?
                    <LayoutNotFound title={"Products"} /> :
                    <Container>
                        <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                            <div className="sm:col-span-3">
                                <CartDetails cart={cartState?.cart} />
                            </div>
                            <div className="sm:col-span-9 overflow-x-auto">
                                <CartItems cart={cartState?.cart} />
                            </div>
                        </div>
                    </Container>
            }
        </div>
    )
}

export default Cart