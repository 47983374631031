import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import typesService from './typesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  types: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewTypes = createAsyncThunk(
  'types/viewTypes',
  async (_, thunkAPI) => {
    try {
      const response = await typesService.viewTypes()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addType = createAsyncThunk(
  'types/addType',
  async (data, thunkAPI) => {
    try {
      const response = await typesService.addType(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewType = createAsyncThunk(
  'types/viewType',
  async (data, thunkAPI) => {
    try {
      const response = await typesService.viewType(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateType = createAsyncThunk(
  'types/updateType',
  async (data, thunkAPI) => {
    try {
      const response = await typesService.updateType(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteType = createAsyncThunk(
  'types/deleteType',
  async (data, thunkAPI) => {
    try {
      const response = await typesService.deleteType(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchTypes = createAsyncThunk(
  'types/searchTypes',
  async (data, thunkAPI) => {
    try {
      const response = await typesService.searchTypes(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.types = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewTypes.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.types = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewTypes"
        state.time = Date.now()
      })
      .addCase(viewTypes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = (action.payload)[0].types
        state.translatedType = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewTypes.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addType.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addType"
        state.time = Date.now()
      })
      .addCase(addType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = [...state.types, (action.payload)[0].type]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewType.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewType"
        state.time = Date.now()
      })
      .addCase(viewType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = state.types.map(type => {
          if (type.id === (action.payload)[0].type.id) {
            return (action.payload)[0].type
          }
          else {
            return type
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateType.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateType"
        state.time = Date.now()
      })
      .addCase(updateType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = state.types.map(type => {
          if (type.id === (action.payload)[0].type.id) {
            return (action.payload)[0].type
          }
          else {
            return type
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteType.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteType"
        state.time = Date.now()
      })
      .addCase(deleteType.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = state.types.filter(type => {
          return type.id !== (action.payload)[0].type.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteType.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchTypes.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.types = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchTypes"
        state.time = Date.now()
      })
      .addCase(searchTypes.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.types = (action.payload)[0].types
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchTypes.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = typesSlice.actions
export default typesSlice.reducer