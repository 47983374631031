import axios from 'axios'

const viewUsers = async () => {
  const response = await axios.get("/api/view-users")
  return response
}

const addUser = async (data) => {
  const response = await axios.post('/api/add-user', data)
  return response
}

const viewUser = async ({ id }) => {
  const response = await axios.post('/api/view-user/' + id)
  return response
}

const updateUser = async ({ id, data }) => {
  const response = await axios.put('/api/update-user/' + id, data)
  return response
}

const deleteUser = async ({ id }) => {
  const response = await axios.delete('/api/delete-user/' + id)
  return response
}

const searchUsers = async ({ key }) => {
  const response = await axios.get('/api/search-users?key=' + key)
  return response
}

const usersService = {
  viewUsers,
  addUser,
  viewUser,
  updateUser,
  deleteUser,
  searchUsers,
}

export default usersService