import axios from 'axios'

const viewTypes = async () => {
  const response = await axios.get('/api/view-types')
  return response
}

const addType = async (data) => {
  const response = await axios.post('/api/add-type', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewType = async ({ id }) => {
  const response = await axios.post('/api/view-type/' + id)
  return response
}

const updateType = async ({ id, data }) => {
  const response = await axios.put('/api/update-type/' + id, data)
  return response
}

const deleteType = async ({ id }) => {
  const response = await axios.delete('/api/delete-type/' + id)
  return response
}

const searchTypes = async ({ key }) => {
  const response = await axios.get('/api/search-types?key=' + key)
  return response
}

const typesService = {
  viewTypes,
  addType,
  viewType,
  updateType,
  deleteType,
  searchTypes
}

export default typesService