import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Features/Auth/authSlice'
import themeReducer from './Features/Theme/themeSlice'
import usersReducer from './Features/Users/usersSlice'
import slidesReducer from './Features/Slides/slidesSlice'
import servicesReducer from './Features/Services/servicesSlice'
import requestsReducer from './Features/Requests/requestsSlice'
import storesReducer from './Features/Stores/storesSlice'
import brandsReducer from './Features/Brands/brandsSlice'
import typesReducer from './Features/Types/typesSlice'
import categoriesReducer from './Features/Categories/categoriesSlice'
import productsReducer from './Features/Products/productsSlice'
import favoritesReducer from './Features/Favorites/favoritesSlice'
import ordersReducer from './Features/Orders/ordersSlice'
import invoicesReducer from './Features/Invoices/invoicesSlice'
import jobsReducer from './Features/Jobs/jobsSlice'
import reviewsReducer from './Features/Reviews/reviewsSlice'
import advertisesReducer from './Features/Advertises/advertisesSlice'
import cartReducer from './Features/Cart/cartSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
    users: usersReducer,
    slides: slidesReducer,
    services: servicesReducer,
    requests: requestsReducer,
    stores: storesReducer,
    brands: brandsReducer,
    types: typesReducer,
    categories: categoriesReducer,
    products: productsReducer,
    favorites: favoritesReducer,
    orders: ordersReducer,
    invoices: invoicesReducer,
    jobs: jobsReducer,
    reviews: reviewsReducer,
    advertises: advertisesReducer,
    cart: cartReducer,
  },
})