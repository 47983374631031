import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Input, Ripple, initTE } from "tw-elements";
import { FaArrowLeft, FaCaretRight, FaCheckDouble, FaSave, FaSpinner } from 'react-icons/fa'
import { FileUploader } from "react-drag-drop-files";
import ColorPicker from 'react-pick-color';
import Select from 'react-select';
import Container from '../../../Components/Dashboard/Container'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { viewCategories } from '../../../Redux/Features/Categories/categoriesSlice';
import { updateProduct, viewProduct } from '../../../Redux/Features/Products/productsSlice';
import { toastify } from '../../../Helper';

const ProductUpdate = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const themeState = useSelector((state) => state.theme);
    const categoriesState = useSelector((state) => state.categories);
    const productsState = useSelector((state) => state.products);
    const [product, setProduct] = useState({});
    const nameInput = useRef();
    const oldPriceInput = useRef();
    const newPriceInput = useRef();
    const descriptionInput = useRef();
    const sizesInput = useRef();
    const colorsInput = useRef();
    const [selectedGender, setSelectedGender] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [files, setFiles] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [isOffer, setIsOffer] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);

    function capitalize(string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    const updateProductAlt = () => {
        let sizes = sizesInput.current.value.split(",")
        sizes = [...new Set(sizes.map((size) => size.trim()).filter((size) => size !== ""))];

        let colors = colorsInput.current.value.split(",")
        colors = [...new Set(colors.map((color) => color.trim()).filter((color) => color !== ""))];

        const data = {
            name: nameInput.current.value,
            description: descriptionInput.current.value,
            price: {
                "oldPrice": oldPriceInput.current.value || 0,
                "newPrice": newPriceInput.current.value,
            },
            sizes: sizes,
            colors: colors,
            gender: selectedGender?.value,
            category_id: selectedCategory?.value,
            images: files ? [...files] : [], //convert a FileList object to an array, i can use Array.from(files)
            is_new: isNew ? 1 : 0,
            is_offer: isOffer ? 1 : 0,
            is_available: isAvailable ? 1 : 0,
        }
        console.log("Product: ", data)
        dispatch(updateProduct({ data, id }));
    }

    useEffect(() => {
        const product = (productsState.products.filter((product) => {
            return product.id == id
        }))[0]
        setIsNew(product?.is_new)
        setIsOffer(product?.is_offer)
        setIsAvailable(product?.is_available)
        setSelectedGender({ value: product?.gender, label: capitalize(product?.gender) })
        setSelectedCategory({ value: product?.category_id, label: `${product?.category.type.brand.name} - ${product?.category.type.name} - ${product?.category.name}` })
        setProduct(product)
    }, [productsState.isLoading, id])

    useEffect(() => {
        initTE({ Input, Ripple });
        dispatch(viewProduct({ id }))
        dispatch(viewCategories())
    }, [])

    useEffect(() => {
        if ((Date.now() - productsState.time < 100) && productsState.status === 200 && productsState.message && productsState.operation === "updateProduct") {
            toastify("success", productsState.status, productsState.message)
            navigate("/admin/products")
        }
        else if ((Date.now() - productsState.time < 100) && (productsState.status === 400 || productsState.status === 401 || productsState.status === 403 || productsState.status === 404) && productsState.message && (productsState.operation === "updateProduct")) {
            toastify("error", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && productsState.status === 500 && productsState.message && (productsState.operation === "updateProduct")) {
            toastify("warn", productsState.status, productsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [productsState.status, productsState.message, productsState.errors])

    return (
        <Container>
            <h2 className='mb-4 md:mb-8 text-lg md:text-xl text-dark dark:text-light flex items-center gap-0.5 font-semibold '>
                <FaCaretRight className='' />
                Update Product:
            </h2>
            <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={nameInput}
                            type="text"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="title"
                            placeholder="Product Name"
                            defaultValue={product?.name}
                            data-te-input-state-active={product?.name}
                            data-te-input-focused={product?.name}
                        />
                        <label
                            htmlFor="title"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product Name
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors.name && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.name}</span> :
                            null
                    }
                </div>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={descriptionInput}
                            type="text"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="description"
                            placeholder="Product Description"
                            defaultValue={product?.description}
                            data-te-input-state-active={product?.description}
                            data-te-input-focused={product?.description}
                        />
                        <label
                            htmlFor="description"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product Description
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors.description && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.description}</span> :
                            null
                    }
                </div>
                <div className='flex gap-1 items-center text-dark dark:text-light'>
                    <input class="me-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-dark/25 dark:bg-light/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-queen dark:after:bg-king after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-queen/75 dark:checked:bg-king/75 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-queen dark:checked:after:bg-king checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-queen checked:focus:bg-queen/75 dark:checked:focus:border-king dark:checked:focus:bg-king/75 checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:after:bg-surface-dark"
                        type="checkbox"
                        id="isNew"
                        checked={isNew}
                        onChange={() => setIsNew((prev) => !prev)}
                    />
                    <label
                        class="inline-block ps-[0.15rem] hover:cursor-pointer"
                        for="isNew"
                    >Is New Product?</label>
                </div>
            </div>
            <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={oldPriceInput}
                            type="number"
                            min="0"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="oldPrice"
                            placeholder="Product Old Price"
                            defaultValue={product?.price?.oldPrice}
                            data-te-input-state-active={product?.price?.oldPrice}
                            data-te-input-focused={product?.price?.oldPrice}
                        />
                        <label
                            htmlFor="oldPrice"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product Old Price
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors["price.oldPrice"] && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors["price.oldPrice"]}</span> :
                            null
                    }
                </div>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={newPriceInput}
                            type="number"
                            min="0"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="nextPrice"
                            placeholder="Product New Price"
                            defaultValue={product?.price?.newPrice}
                            data-te-input-state-active={product?.price?.newPrice}
                            data-te-input-focused={product?.price?.newPrice}
                        />
                        <label
                            htmlFor="nextPrice"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product New Price
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors["price.newPrice"] && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors["price.newPrice"]}</span> :
                            null
                    }
                </div>
                <div className='flex gap-1 items-center text-dark dark:text-light'>
                    <input class="me-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-dark/25 dark:bg-light/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-queen dark:after:bg-king after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-queen/75 dark:checked:bg-king/75 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-queen dark:checked:after:bg-king checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-queen checked:focus:bg-queen/75 dark:checked:focus:border-king dark:checked:focus:bg-king/75 checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:after:bg-surface-dark"
                        type="checkbox"
                        role="switch"
                        id="isOffer"
                        checked={isOffer}
                        onChange={() => setIsOffer((prev) => !prev)}
                    />
                    <label
                        class="inline-block ps-[0.15rem] hover:cursor-pointer"
                        for="isOffer"
                    >Is Offer Product?</label>
                </div>
            </div>
            <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={sizesInput}
                            type="text"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="sizes"
                            placeholder="Product Sizes Seperate With Comma"
                            defaultValue={product?.sizes}
                            data-te-input-state-active={product?.sizes}
                            data-te-input-focused={product?.sizes}
                        />
                        <label
                            htmlFor="sizes"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product Sizes <span className='text-sm'>(Seperated With Comma)</span>
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors.sizes && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.sizes}</span> :
                            null
                    }
                </div>
                <div>
                    <div className="relative mb-1" data-te-input-wrapper-init="">
                        <input
                            ref={colorsInput}
                            type="text"
                            className="text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 peer block min-h-[auto] w-full border-0 bg-queen/10 dark:bg-king/10 px-2 py-2 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 "
                            id="colors"
                            placeholder="Product Colors Seperate With Comma"
                            defaultValue={product?.colors}
                            data-te-input-state-active={product?.colors}
                            data-te-input-focused={product?.colors}
                        />
                        <label
                            htmlFor="colors"
                            className={`start-3 text-dark/75 dark:text-light/75 peer-focus:text-queen dark:peer-focus:text-king pointer-events-none absolute top-1 mb-0 max-w-[90%] origin-[0_0] truncate pt-[0.3rem] leading-[1.6] transition-all duration-250 ease-out peer-focus:-translate-y-[1.1rem] peer-focus:scale-[0.8] peer-data-[te-input-state-active]:-translate-y-[1.1rem] peer-data-[te-input-state-active]:scale-[0.8] motion-reduce:transition-none`}
                        >
                            Product Colors <span className='text-sm'>(Seperated With Comma)</span>
                        </label>
                        <div class="group flex absolute start-0 top-0 w-full max-w-full h-full text-start pointer-events-none" data-te-input-notch-ref="">
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none start-0 top-0 h-full w-2 border-e-0 group-data-[te-input-focused]:border-e-0 group-data-[te-input-state-active]:border-e-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-leading-ref="" style={{ width: "9px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow-0 shrink-0 basis-auto w-auto max-w-[calc(100%-1rem)] h-full border-e-0 border-s-0 group-data-[te-input-focused]:border-x-0 group-data-[te-input-state-active]:border-x-0 group-data-[te-input-focused]:border-t group-data-[te-input-state-active]:border-t group-data-[te-input-focused]:border-solid group-data-[te-input-state-active]:border-solid group-data-[te-input-focused]:border-t-transparent dark:group-data-[te-input-focused]:border-t-transparent group-data-[te-input-state-active]:border-t-transparent dark:group-data-[te-input-state-active]:border-t-transparent border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-middle-ref="" style={{ width: "65.6px" }}></div>
                            <div class="pointer-events-none border border-solid box-border bg-transparent transition-all duration-200 ease-linear motion-reduce:transition-none grow h-full border-s-0 group-data-[te-input-focused]:border-s-0 group-data-[te-input-state-active]:border-s-0 border-queen/50 dark:border-king/50 group-data-[te-input-focused]:border-queen dark:group-data-[te-input-focused]:border-king" data-te-input-notch-trailing-ref=""></div>
                        </div>
                    </div>
                    {
                        productsState.errors && productsState.errors.colors && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.colors}</span> :
                            null
                    }
                </div>
                <div className='flex gap-1 items-center text-dark dark:text-light'>
                    <input class="me-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-dark/25 dark:bg-light/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-queen dark:after:bg-king after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-queen/75 dark:checked:bg-king/75 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-queen dark:checked:after:bg-king checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-queen checked:focus:bg-queen/75 dark:checked:focus:border-king dark:checked:focus:bg-king/75 checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:after:bg-surface-dark"
                        type="checkbox"
                        role="switch"
                        id="isAvailable"
                        checked={isAvailable}
                        onChange={() => setIsAvailable((prev) => !prev)}
                    />
                    <label
                        class="inline-block ps-[0.15rem] hover:cursor-pointer"
                        for="isAvailable"
                    >Is Available Product?</label>
                </div>
            </div>
            <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 items-start'>
                <div>
                    <Select
                        className='mb-1'
                        classNamePrefix={themeState.theme === 'light' ? 'select-style-light' : 'select-style-dark'}
                        value={selectedGender}
                        onChange={setSelectedGender}
                        options={
                            [
                                { value: 'male', label: 'Male' },
                                { value: 'female', label: 'Female' },
                                { value: 'kid', label: 'Kid' },
                            ]
                        }
                        placeholder="Select Gender"
                    />
                    {
                        productsState.errors && productsState.errors.gender && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.gender}</span> :
                            null
                    }
                </div>
                <div>
                    <Select
                        className='mb-1'
                        classNamePrefix={themeState.theme === 'light' ? 'select-style-light' : 'select-style-dark'}
                        value={selectedCategory}
                        onChange={setSelectedCategory}
                        options={
                            categoriesState.categories.map((category) => {
                                return { value: category.id, label: `${category.type.brand.name} - ${category.type.name} - ${category.name}` }
                            })
                        }
                        placeholder="Select Category"
                    />
                    {
                        productsState.errors && productsState.errors.category_id && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.category_id}</span> :
                            null
                    }
                </div>
                <div className='hidden lg:flex items-center gap-2'>
                    <Link to={`./../..`} className='px-6 py-[5px] h-fit text-base md:text-lg bg-queen dark:bg-king text-light cursor-pointer shadow-inner shadow-light/75 rounded-sm flex justify-center items-center gap-2 hover:opacity-90 transition-all duration-150'>
                        <FaArrowLeft />Back
                    </Link>
                    <button onClick={() => updateProductAlt()} className='px-6 py-[5px] h-fit text-base md:text-lg bg-queen dark:bg-king text-light cursor-pointer shadow-inner shadow-light/75 rounded-sm flex justify-center items-center gap-2 hover:opacity-90 transition-all duration-150'>
                        {
                            productsState.isLoading && productsState.operation === "updateProduct" ?
                                <>
                                    <FaSpinner className='animate-spin' /> Wait
                                </> :
                                <>
                                    <FaSave /> Save
                                </>
                        }
                    </button>
                </div>
            </div>
            <div className='mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                <div className='w-full'>
                    <FileUploader multiple classes={themeState.theme === 'light' ? 'drop-style-light' : 'drop-style-dark'} handleChange={(files) => setFiles(files)} name="file" types={["JPG", "PNG"]} />
                    {
                        productsState.errors && productsState.errors.images && productsState.operation === "updateProduct" ?
                            <span className='text-queen dark:text-king text-sm font-medium ml-1'>{productsState.errors.images}</span> :
                            null
                    }
                </div>
                <div>
                    {/* <ColorPicker color={color} onChange={color => setColor(color.hex)} /> */}
                    <ColorPicker
                        color={themeState.theme === 'light' ? '#7dbef2' : '#3f4f56'}
                        theme={{
                            background: themeState.theme === 'light' ? '#dae6f0' : '#1d2021',
                            inputBackground: themeState.theme === 'light' ? '#dae6f0' : '#1d2021',
                            borderColor: themeState.theme === 'light' ? '#7dbef2' : '#3f4f56',
                            color: themeState.theme === 'light' ? '#151515' : '#efefef',
                            borderRadius: '0px',
                            width: '100%'
                        }}
                    />
                </div>
                <div className='hidden  lg:block text-base text-dark dark:text-light'>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">You should specify name of the product, while description is optional.</span>
                    </p>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">You should specify new price of the product, while old price is optional.</span>
                    </p>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">Specify sizes and colors, separating each pair with a comma.</span>
                    </p>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">Specify customer's gender and target category.</span>
                    </p>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">You can update up to five images, with at least one image required.</span>
                    </p>
                    <p className='grid grid-cols-12 mb-1'>
                        <FaCheckDouble className='col-span-1 lg:text-xl text-queen dark:text-king inline mr-2 mt-2' />
                        <span className="col-span-10">You can extract colors from a color picker and enter them in hexadecimal format like <span className='text-light bg-queen dark:bg-king px-1 py-0.5 text-sm shadow-inner shadow-light/25'>#f1f1f1</span>.</span>
                    </p>
                </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:hidden'>
                <Link to={`./../..`} className='px-6 py-[6px] h-fit text-base md:text-lg bg-queen dark:bg-king text-light cursor-pointer shadow-inner shadow-light/75 rounded-sm flex justify-center items-center gap-2 hover:opacity-90 transition-all duration-150'>
                    <FaArrowLeft />Back
                </Link>
                <button onClick={() => updateProductAlt()} className='px-6 py-[6px] h-fit text-base md:text-lg bg-queen dark:bg-king text-light cursor-pointer shadow-inner shadow-light/75 rounded-sm flex justify-center items-center gap-2 hover:opacity-90 transition-all duration-150'>
                    {
                        productsState.isLoading && productsState.operation === "updateProduct" ?
                            <>
                                <FaSpinner className='animate-spin' /> Wait
                            </> :
                            <>
                                <FaSave /> Save
                            </>
                    }
                </button>
            </div>
        </Container>
    )
}

export default ProductUpdate