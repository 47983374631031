import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewServices, searchServices, deleteService, updateService, addService } from "../../../Redux/Features/Services/servicesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Services = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const servicesState = useSelector((state) => state.services);
    const searchInput = useRef();
    const titleInput = useRef();
    const descriptionInput = useRef();
    const imageInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchServicesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchServices(data))
    }

    const addServiceAlt = () => {
        let data = {
            title: titleInput.current.value,
            description: descriptionInput.current.value,
            image: imageInput.current.files[0]
        }

        dispatch(addService(data))
    }

    const updateServiceAlt = (service_id) => {
        let data = {
            title: titleInput.current.value,
            description: descriptionInput.current.value,
            image: imageInput.current.files[0]
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateService({ data, id: service_id }))
        }
    }

    const deleteServiceAlt = (service_id) => {
        const data = {
            id: service_id
        }

        dispatch(deleteService(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewServices())
    }, [])

    useEffect(() => {
        if ((Date.now() - servicesState.time < 100) && (servicesState.status === 200 || servicesState.status === 201) && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices" || servicesState.operation === "addService" || servicesState.operation === "updateService" || servicesState.operation === "deleteService")) {
            // toastify("success", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && (servicesState.status === 400 || servicesState.status === 401 || servicesState.status === 403 /*|| servicesState.status === 404*/) && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices" || servicesState.operation === "addService" || servicesState.operation === "updateService" || servicesState.operation === "deleteService")) {
            toastify("error", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && servicesState.status === 500 && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices" || servicesState.operation === "addService" || servicesState.operation === "updateService" || servicesState.operation === "deleteService")) {
            toastify("warn", servicesState.status, servicesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [servicesState.status, servicesState.message, servicesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Services
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by title or description"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchServicesAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    servicesState.isLoading && servicesState.operation !== "addService" && servicesState.operation !== "updateService" && servicesState.operation !== "deleteService" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Title
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Image
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="5"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Services
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        servicesState.services.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={titleInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                    "Title"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addServiceAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && servicesState.isLoading && servicesState.operation === "addService" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && servicesState.errors && servicesState.operation === "addService" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.title && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.title : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.description && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.image && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="5"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Services Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={titleInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                    "Title"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addServiceAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && servicesState.isLoading && servicesState.operation === "addService" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && servicesState.errors && servicesState.operation === "addService" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.title && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.title : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.description && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        servicesState.errors && servicesState.errors.image && servicesState.operation === "addService" ?
                                                            servicesState.errors && servicesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        servicesState.services.map((service, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === service.id && servicesState.errors && servicesState.operation === "updateService" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === service.id ?
                                                                    <input ref={titleInput} defaultValue={service.title} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                                    service.title
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === service.id ?
                                                                    <input ref={descriptionInput} defaultValue={service.description} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                                    service.description || "..."
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === service.id ?
                                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                                    service.image ?
                                                                        <a target='_blank' href={process.env.REACT_APP_API_BASE_URL +"/"+ service.image} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View</a> :
                                                                        <span className='px-1'>...</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === service.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(service.id); updateServiceAlt(service.id); setShowUpdateErrors(service.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(service.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === service.id && servicesState.isLoading && servicesState.operation === "updateService" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(service.id); deleteServiceAlt(service.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === service.id && servicesState.isLoading && servicesState.operation === "deleteService" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === service.id && servicesState.errors && servicesState.operation === "updateService" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        servicesState.errors && servicesState.errors.title && servicesState.operation === "updateService" ?
                                                                            servicesState.errors && servicesState.errors.title : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        servicesState.errors && servicesState.errors.description && servicesState.operation === "updateService" ?
                                                                            servicesState.errors && servicesState.errors.description : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        servicesState.errors && servicesState.errors.image && servicesState.operation === "updateService" ?
                                                                            servicesState.errors && servicesState.errors.image : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Services