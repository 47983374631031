import React, { useEffect, useState } from 'react'
import { FaArrowUp, FaEnvelope, FaPhone } from 'react-icons/fa'
import Container from '../Components/Container'

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setShowButton(scrollTop > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer className="text-dark dark:text-light bg-queen/10 dark:bg-king/10 text-center lg:text-left">
      {/* <Container>
        <div className="text-center md:text-left">
          <div className="grid-1 grid gap-4 sm:grid-cols-2 md:grid-cols-3">
            <div className="">
              <h6 style={{ letterSpacing: "2.5px" }} className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Links
              </h6>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="#!"
                >
                  Link 1
                </a>
              </p>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="#!"
                >
                  Link 2
                </a>
              </p>
            </div>
            <div className="">
              <h6 style={{ letterSpacing: "2.5px" }} className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Uesful Links
              </h6>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="#!"
                >
                  Useful Link 1
                </a>
              </p>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="#!"
                >
                  Useful Link 2
                </a>
              </p>
            </div>
            <div className="">
              <h6 style={{ letterSpacing: "2.5px" }} className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
                Contact
              </h6>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="mailto:info@eccomerce.com"
                >
                  <FaEnvelope className='text-lg inline mr-2 mb-1' />
                  info@eccomerce.com
                </a>
              </p>
              <p className="mb-2">
                <a
                  className="text-sm md:text-base hover:opacity-80 duration-150"
                  href="tel:+96181543453"
                >
                  <FaPhone className='text-lg inline mr-2 mb-1' />
                  +963997791807
                </a>
              </p>
            </div>
          </div>
        </div>
      </Container> */}
      <div className="text-dark bg-light dark:text-light bg-queen/10 dark:bg-king/10 text-sm p-2 text-center border-t border-dark/25 dark:border-light/25">
        <span>
          © 2024 Copyright:{" "}
        </span>
        <a
          className="font-semibold text-dark dark:text-light"
          href="https://tw-elements.com/"
        >
          WMK Tech
        </a>
      </div>
      {   /*${window.location.pathname === "/" ? "start-7" : "end-7"}*/
        showButton &&
        <button
          className={`z-10 fixed bottom-5 right-5 bg-queen dark:bg-king w-12 h-12 rounded-full flex justify-center items-center opacity-50 hover:opacity-100 transition-all duration-150 ease-in-out`}
          onClick={() => { scrollToTop() }}>
          <FaArrowUp className='text-xl text-light' />
        </button>
      }
    </footer>
  )
}

export default Footer