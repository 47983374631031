import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewOrders, searchOrders, updateOrder } from "../../../Redux/Features/Orders/ordersSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaChevronDown, FaChevronUp, FaEdit, FaSave, FaSpinner, FaTimes } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';
import OrderProducts from './OrderProducts';

const Orders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ordersState = useSelector((state) => state.orders);
    const searchInput = useRef();
    const statusInput = useRef();
    const [openOrder, setOpenOrder] = useState(null)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadUpdate, setLoadUpdate] = useState(null)

    const searchOrdersAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchOrders(data))
    }

    const updateOrderAlt = (order_id) => {
        let data = {
            status: statusInput.current.value
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateOrder({ data, id: order_id }))
        }
    }

    useEffect(() => {
        setOpenOrder(ordersState?.orders[0]?.id)
    }, [ordersState.orders])

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewOrders())
    }, [])

    useEffect(() => {
        if ((Date.now() - ordersState.time < 100) && ordersState.status === 200 && ordersState.message && (ordersState.operation === "viewOrders" || ordersState.operation === "searchOrders" || ordersState.operation === "updateOrder")) {
            // toastify("success", ordersState.status, ordersState.message)
        }
        else if ((Date.now() - ordersState.time < 100) && (ordersState.status === 400 || ordersState.status === 401 || ordersState.status === 403 /*|| ordersState.status === 404*/) && ordersState.message && (ordersState.operation === "viewOrders" || ordersState.operation === "searchOrders" || ordersState.operation === "updateOrder")) {
            toastify("error", ordersState.status, ordersState.message)
        }
        else if ((Date.now() - ordersState.time < 100) && ordersState.status === 500 && ordersState.message && (ordersState.operation === "viewOrders" || ordersState.operation === "searchOrders" || ordersState.operation === "updateOrder")) {
            toastify("warn", ordersState.status, ordersState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [ordersState.status, ordersState.message, ordersState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Orders
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by identifier, ship address or status"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => { searchOrdersAlt() }}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    ordersState.isLoading && ordersState.operation !== "updateOrder" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Identifier
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        User
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Products
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Total Price
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Ship Address
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Status
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="8"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Orders
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        ordersState.orders.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Products
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Total Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Ship Address
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Status
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="8"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Orders Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Identifier
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Products
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Total Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Ship Address
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Status
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        ordersState.orders.map((order, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === order.id && ordersState.errors && ordersState.operation === "updateOrder" ? "" : "border-b border-queen/50 dark:border-king/50 "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{order.identifier}</td>
                                                        <td className="px-4 py-2">{order.user.email}</td>
                                                        <td className="px-4 py-2">{order.products.reduce((accumulator, product) => accumulator + parseInt(product.quantity), 0)}</td>
                                                        <td className="px-4 py-2">${order.products.reduce((accumulator, product) => accumulator + (parseInt(product.quantity) * parseFloat(product.unitPrice)), 0)}</td>
                                                        <td className="px-4 py-2">{order.ship_address} </td>
                                                        <td className="px-4 py-2">
                                                            {/* {
                                                                openUpdate === order.id ?
                                                                    <input ref={statusInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New status...' type='text' /> :
                                                                    order.status
                                                            } */}
                                                            {
                                                                openUpdate === order.id ?
                                                                    <select ref={statusInput} defaultValue={order.status} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Status</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="pending">Pending</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="processing">Processing</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="shipped">Shipped</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="delivered">Delivered</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="returned">Returned</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="out of stock">Out of stock</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="rejected">Rejected</option>
                                                                    </select> :
                                                                    <span className='capitalize'>{order.status}</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                <button
                                                                    onClick={() => setOpenOrder(openOrder === order.id ? null : order.id)}
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                >
                                                                    {
                                                                        openOrder === order.id ?
                                                                            <FaChevronUp className='text-base md:text-lg' />:
                                                                            <FaChevronDown className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                                {
                                                                    openUpdate === order.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(order.id); updateOrderAlt(order.id); setShowUpdateErrors(order.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(order.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === order.id && ordersState.isLoading && ordersState.operation === "updateOrder" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === order.id && ordersState.errors && ordersState.operation === "updateOrder" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50 text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        ordersState.errors && ordersState.errors.status && ordersState.operation === "updateOrder" ?
                                                                            ordersState.errors && ordersState.errors.status : "..."
                                                                    }
                                                                </td>
                                                                <td className="pl-4 pr-0 md:px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                    {
                                                        openOrder === order.id ?
                                                            <td colspan="8" className='bg-light dark:bg-dark'>
                                                                <OrderProducts products={order.products} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Orders