import React, { useState } from 'react'

const ProductImages = ({ product }) => {
    const [images, setImages] = useState(product.images)
    const [selectedImage, setSelectedeImage] = useState(product.images[0])

    return (
        <div className='flex flex-col items-center justify-center gap-4'>
            <div className='border border-queen/50 dark:border-king/50 rounded-md mx-auto px-4 py-8 w-full'>
                <img src={process.env.REACT_APP_API_BASE_URL + "/" + selectedImage} alt="" className='w-full' /> 
            </div>
            {
                images.length > 1 &&
                <div className='mx-auto flex items-center gap-2'>
                    {
                        images.map((image) => {
                            return (
                                <div onClick={() => setSelectedeImage(image)} className='bg-queen/[0.025] dark:bg-king/[0.025] border border-queen/50 dark:border-king/50 px-1 py-4 rounded-md opacity-80 cursor-pointer hover:opacity-100 transition-all duration-150'>
                                    <img src={process.env.REACT_APP_API_BASE_URL + "/" + image} alt="" className='w-full' />
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default ProductImages