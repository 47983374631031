import React from 'react'

const ContainerAlt = ({ children }) => {
    return (
        <div className="mx-auto px-4 md:px-8 lg:px-16 py-2 md:py-4 lg:py-8 max-w-[1500px] bg-queen/[0.05] dark:bg-king/[0.05]">
            {children}
        </div>
    )
}

export default ContainerAlt