import axios from 'axios'

const viewAdvertises = async () => {
  const response = await axios.get('/api/view-advertises')
  return response
}

const addAdvertise = async (data) => {
  const response = await axios.post('/api/add-advertise', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewAdvertise = async ({ id }) => {
  const response = await axios.post('/api/view-advertise/' + id)
  return response
}

const updateAdvertise = async ({ id, data }) => {
  const response = await axios.post('/api/update-advertise/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteAdvertise = async ({ id }) => {
  const response = await axios.delete('/api/delete-advertise/' + id)
  return response
}

const searchAdvertises = async ({ key }) => {
  const response = await axios.get('/api/search-advertises?key=' + key)
  return response
}

const advertisesService = {
  viewAdvertises,
  addAdvertise,
  viewAdvertise,
  updateAdvertise,
  deleteAdvertise,
  searchAdvertises
}

export default advertisesService