import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewTypes } from "../../../Redux/Features/Types/typesSlice";
import { viewCategories, searchCategories, deleteCategory, updateCategory, addCategory } from "../../../Redux/Features/Categories/categoriesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Categories = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const typesState = useSelector((state) => state.types);
    const categoriesState = useSelector((state) => state.categories);
    const searchInput = useRef();
    const nameInput = useRef();
    const typeInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchCategoriesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchCategories(data))
    }

    const addCategoryAlt = () => {
        let data = {
            name: nameInput.current.value,
            type_id: typeInput.current.value
        }

        dispatch(addCategory(data))
    }

    const updateCategoryAlt = (category_id) => {
        let data = {
            name: nameInput.current.value,
            type_id: typeInput.current.value
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateCategory({ data, id: category_id }))
        }
    }

    const deleteCategoryAlt = (category_id) => {
        const data = {
            id: category_id
        }

        dispatch(deleteCategory(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewTypes())
        dispatch(viewCategories())
    }, [])

    useEffect(() => {
        if ((Date.now() - categoriesState.time < 100) && (categoriesState.status === 200 || categoriesState.status === 201) && categoriesState.message && (categoriesState.operation === "viewCategories" || categoriesState.operation === "searchCategories" || categoriesState.operation === "addCategory" || categoriesState.operation === "updateCategory" || categoriesState.operation === "deleteCategory")) {
            // toastify("success", categoriesState.status, categoriesState.message)
        }
        else if ((Date.now() - categoriesState.time < 100) && (categoriesState.status === 400 || categoriesState.status === 401 || categoriesState.status === 403 /*|| categoriesState.status === 404*/) && categoriesState.message && (categoriesState.operation === "viewCategories" || categoriesState.operation === "searchCategories" || categoriesState.operation === "addCategory" || categoriesState.operation === "updateCategory" || categoriesState.operation === "deleteCategory")) {
            toastify("error", categoriesState.status, categoriesState.message)
        }
        else if ((Date.now() - categoriesState.time < 100) && categoriesState.status === 500 && categoriesState.message && (categoriesState.operation === "viewCategories" || categoriesState.operation === "searchCategories" || categoriesState.operation === "addCategory" || categoriesState.operation === "updateCategory" || categoriesState.operation === "deleteCategory")) {
            toastify("warn", categoriesState.status, categoriesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [categoriesState.status, categoriesState.message, categoriesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Categories
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        category="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by brand, type or name"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchCategoriesAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    categoriesState.isLoading && categoriesState.operation !== "addCategory" && categoriesState.operation !== "updateCategory" && categoriesState.operation !== "deleteCategory" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Brand
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Type
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            category="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="5"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Categories
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        categoriesState.categories.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={typeInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Type</option>
                                                        {
                                                            typesState.types.map((type) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={type.id}>{type.brand?.name} - {type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "Type"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' category='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addCategoryAlt(); setShowAddErrors(true) }}
                                                        category="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        category="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && categoriesState.isLoading && categoriesState.operation === "addCategory" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && categoriesState.errors && categoriesState.operation === "addCategory" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        categoriesState.errors && categoriesState.errors.type_id && categoriesState.operation === "addCategory" ?
                                                            categoriesState.errors && categoriesState.errors.type_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        categoriesState.errors && categoriesState.errors.name && categoriesState.operation === "addCategory" ?
                                                            categoriesState.errors && categoriesState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        category="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="5"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Categories Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={typeInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Type</option>
                                                        {
                                                            typesState.types.map((type) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={type.id}>{type.brand?.name} - {type.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "Type"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' category='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addCategoryAlt(); setShowAddErrors(true) }}
                                                        category="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        category="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && categoriesState.isLoading && categoriesState.operation === "addCategory" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && categoriesState.errors && categoriesState.operation === "addCategory" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        categoriesState.errors && categoriesState.errors.type_id && categoriesState.operation === "addCategory" ?
                                                            categoriesState.errors && categoriesState.errors.type_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        categoriesState.errors && categoriesState.errors.name && categoriesState.operation === "addCategory" ?
                                                            categoriesState.errors && categoriesState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        category="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        categoriesState.categories.map((category, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === category.id && categoriesState.errors && categoriesState.operation === "updateCategory" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2 ">{category.type?.brand?.name}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === category.id ?
                                                                    <select ref={typeInput} defaultValue={category.type?.id} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Type</option>
                                                                        {
                                                                            typesState.types.map((type) => {
                                                                                return (
                                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={type.id}>{type.brand?.name} - {type.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select> :
                                                                    category.type?.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === category.id ?
                                                                    <input ref={nameInput} defaultValue={category.name} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' category='text' /> :
                                                                    category.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === category.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(category.id); updateCategoryAlt(category.id); setShowUpdateErrors(category.id) }}
                                                                            category="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(category.id)}
                                                                            category="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === category.id && categoriesState.isLoading && categoriesState.operation === "updateCategory" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    category="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(category.id); deleteCategoryAlt(category.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === category.id && categoriesState.isLoading && categoriesState.operation === "deleteCategory" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === category.id && categoriesState.errors && categoriesState.operation === "updateCategory" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        categoriesState.errors && categoriesState.errors.type_id && categoriesState.operation === "updateCategory" ?
                                                                            categoriesState.errors && categoriesState.errors.type_id : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        categoriesState.errors && categoriesState.errors.name && categoriesState.operation === "updateCategory" ?
                                                                            categoriesState.errors && categoriesState.errors.name : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        category="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Categories