import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reset, set } from "../../Redux/Features/Auth/authSlice";
import { deleteUser, updateUser } from "../../Redux/Features/Users/usersSlice";
import { FaCaretRight, FaEdit, FaSave, FaShoppingBag, FaSpinner, FaTimes, FaTrashAlt } from 'react-icons/fa'
import { confirmModal, toastify } from '../../Helper';

const ProfileDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const nameInput = useRef();
    const phoneInput = useRef();
    const emailInput = useRef();
    const addressInput = useRef();
    const passwordInput = useRef();
    const usersState = useSelector((state) => state.users);
    const authState = useSelector((state) => state.auth);
    const [openUpdate, setOpenUpdate] = useState(false)
    const [showUpdateErrors, setShowUpdateErrors] = useState(false)
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)

    const updateUserAlt = () => {
        let data = {
            name: nameInput.current.value,
            phone: phoneInput.current.value,
            email: emailInput.current.value,
            address: addressInput.current.value,
            password: passwordInput.current.value,
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateUser({ data, id: authState.auth.user.id }))
            setShowUpdateErrors(true);
        }
    }

    const deleteUserAlt = () => {
        const data = {
            id: authState.auth.user.id
        }

        dispatch(deleteUser(data))
    }

    useEffect(() => {
        if ((Date.now() - usersState.time < 100) && (usersState.status === 200) && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            // toastify("success", usersState.status, usersState.message)
            // Update Auth State
            if (usersState.operation === "updateUser") {
                const users = (usersState.users).filter(user => {
                    return user.id === authState.auth.user.id
                })
                dispatch(set({ user: users[0] }));
            }
            if (usersState.operation === "deleteUser") {
                dispatch(reset());
                localStorage.clear();
            }
        }
        else if ((Date.now() - usersState.time < 100) && (usersState.status === 400 || usersState.status === 401 || usersState.status === 403 || usersState.status === 404) && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            toastify("error", usersState.status, usersState.message)
        }
        else if ((Date.now() - usersState.time < 100) && usersState.status === 500 && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            toastify("warn", usersState.status, usersState.message)
            setTimeout(() => {
                navigate(0)
            }, 5000);
        }
    }, [usersState.status, usersState.message, usersState.errors])

    return (
        <div className='flex flex-col gap-2'>
            <p className='text-base md:text-lg font-normal text-dark dark:text-light max-w-full'>
                Here, you can update your profile information such as the
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>address</span>
                you prefer for shipping your products, and you can review your
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>orders</span>
                and their status.
            </p>

            <div className='flex flex-col gap-1 text-dark dark:text-light'>
                <div>
                    <div className='flex gap-1'>
                        <span className='text-base md:text-lg font-medium'>Name:</span>
                        <span className="text-base md:text-lg font-normal">
                            {
                                openUpdate ?
                                    <input ref={nameInput} defaultValue={authState.auth.user.name} className='border-b  border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New name' type='text' /> :
                                    authState.auth.user.name
                            }
                        </span>
                    </div>
                    {
                        showUpdateErrors && usersState.errors && usersState.errors.name && usersState.operation === "updateUser" ?
                            <span className='text-sm md:text-base text-danger font-normal'>{usersState.errors && usersState.errors.name}</span> : null
                    }
                </div>
                <div>
                    <div className='flex gap-1'>
                        <span className='text-base md:text-lg font-medium'>Phone:</span>
                        <span className="text-base md:text-lg font-normal">
                            {
                                openUpdate ?
                                    <input ref={phoneInput} defaultValue={authState.auth.user.phone} className='border-b  border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New phone' type='text' /> :
                                    authState.auth.user.phone
                            }
                        </span>
                    </div>
                    {
                        showUpdateErrors && usersState.errors && usersState.errors.phone && usersState.operation === "updateUser" ?
                            <span className='text-sm md:text-base text-danger font-normal'>{usersState.errors && usersState.errors.phone}</span> : null
                    }
                </div>
                <div>
                    <div className='flex gap-1'>
                        <span className='text-base md:text-lg font-medium'>Email:</span>
                        <span className="text-base md:text-lg font-normal">
                            {
                                openUpdate ?
                                    <input ref={emailInput} defaultValue={authState.auth.user.email} className='border-b  border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New email' type='email' /> :
                                    authState.auth.user.email
                            }
                        </span>
                    </div>
                    {
                        showUpdateErrors && usersState.errors && usersState.errors.email && usersState.operation === "updateUser" ?
                            <span className='text-sm md:text-base text-danger font-normal'>{usersState.errors && usersState.errors.email}</span> : null
                    }
                </div>
                <div>
                    <div className='flex gap-1'>
                        <span className='text-base md:text-lg font-medium'>Address:</span>
                        <span className="text-base md:text-lg font-normal">
                            {
                                openUpdate ?
                                    <input ref={addressInput} defaultValue={authState.auth.user.address} className='border-b  border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New address' type='text' /> :
                                    authState.auth.user.address
                            }
                        </span>
                    </div>
                    {
                        showUpdateErrors && usersState.errors && usersState.errors.address && usersState.operation === "updateUser" ?
                            <span className='text-sm md:text-base text-danger font-normal'>{usersState.errors && usersState.errors.address}</span> : null
                    }
                </div>
                <div>
                    {
                        openUpdate ?
                            <div className='flex gap-1 mb-2'>
                                <span className='text-base md:text-lg font-medium'>Password:</span>
                                <span className="text-base md:text-lg font-normal">
                                    {
                                        openUpdate ?
                                            <input ref={passwordInput} defaultValue={authState.auth.user.password} className='border-b  border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New password' type='password' /> :
                                            authState.auth.user.password
                                    }
                                </span>
                            </div> :
                            null
                    }
                    {
                        showUpdateErrors && usersState.errors && usersState.errors.password && usersState.operation === "updateUser" ?
                            <span className='text-sm md:text-base text-danger font-normal'>{usersState.errors && usersState.errors.password}</span> : null
                    }
                </div>
            </div>

            <div className='grid xs:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 gap-2 mt-2'>
                {/* <button className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                    <FaShoppingBag className='text-lg' />Add Cart
                </button> */}

                {
                    openUpdate ?
                        <button onClick={() => { setOpenUpdate(false); updateUserAlt(); /*setShowUpdateErrors(true)*/ }} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                            <FaSave className='text-lg' /> Save
                        </button> :
                        <button onClick={() => setOpenUpdate(true)} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                            {
                                usersState.isLoading && usersState.operation === "updateUser" ?
                                    <FaSpinner className=' animate-spin text-lg' /> :
                                    <><FaEdit className='text-lg' /> Edit</>
                            }

                        </button>
                }
                {
                    showUpdateErrors && usersState.errors && usersState.operation === "updateUser" ?
                        <button onClick={() => { setShowUpdateErrors(false) }} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                            <FaTimes className='text-lg' /> Close
                        </button> :
                        null
                }
                <button onClick={() => { setOpenDeleteUserModal(true) }} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                    {
                        usersState.isLoading && usersState.operation === "deleteUser" ?
                            <FaSpinner className=' animate-spin text-lg' /> :
                            <><FaTrashAlt className='text-lg' /> Delete</>
                    }
                </button>
            </div>
            {/* Modals */}
            {
                confirmModal(openDeleteUserModal, setOpenDeleteUserModal, "Delete Account", deleteUserAlt, null, usersState.isLoading)
            }
        </div>
    )
}

export default ProfileDetails