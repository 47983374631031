import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewFavorites, searchFavorites } from "../../../Redux/Features/Favorites/favoritesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaChevronDown, FaChevronUp, FaSpinner, FaStar, FaRegStar } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';
import FavoriteUsers from './FavoriteUsers';

const Favorites = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const favoritesState = useSelector((state) => state.favorites);
    const searchInput = useRef();
    const statusInput = useRef();
    const [openFavorite, setOpenFavorite] = useState(null)

    const searchFavoritesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchFavorites(data))
    }

    useEffect(() => {
        setOpenFavorite(favoritesState?.favorites[0]?.id)
    }, [favoritesState.favorites])

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewFavorites())
    }, [])

    useEffect(() => {
        if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 200 && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites" || favoritesState.operation === "updateFavorite")) {
            // toastify("success", favoritesState.status, favoritesState.message)
        }
        else if ((Date.now() - favoritesState.time < 100) && (favoritesState.status === 400 || favoritesState.status === 401 || favoritesState.status === 403 /*|| favoritesState.status === 404*/) && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites" || favoritesState.operation === "updateFavorite")) {
            toastify("error", favoritesState.status, favoritesState.message)
        }
        else if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 500 && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites" || favoritesState.operation === "updateFavorite")) {
            toastify("warn", favoritesState.status, favoritesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [favoritesState.status, favoritesState.message, favoritesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Favorite Products
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by user email, product brand, type, category or name"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => { searchFavoritesAlt() }}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    favoritesState.isLoading && favoritesState.operation !== "updateFavorite" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Brand
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Type
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Category
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Users
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Rating
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="8"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Favorites
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        favoritesState.favorites.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Type
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Category
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Name
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Users
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Rating
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="8"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Favorites Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Type
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Category
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Name
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Users
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Rating
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        favoritesState.favorites.map((favorite, idx) => {
                                            return (
                                                <>
                                                    <tr className="border-b border-queen/50 dark:border-king/50">
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{favorite.category?.type?.brand?.name}</td>
                                                        <td className="px-4 py-2">{favorite.category?.type?.name}</td>
                                                        <td className="px-4 py-2">{favorite.category?.name}</td>
                                                        <td className="px-4 py-2">{favorite.name}</td>
                                                        <td className="px-4 py-2">{favorite.users?.length}</td>
                                                        {/* <td className="px-4 py-2 flex items-center gap-1 text-queen dark:text-king">
                                                            {
                                                                favorite.rating ?
                                                                    <span className="mr-2 text-dark dark:text-light">{favorite.rating?.length} Reviews</span> :
                                                                    <span className="mr-2 text-dark dark:text-light">0 Reviews</span>
                                                            }
                                                            {
                                                                favorite.rating && Array(Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                                                    return <FaStar />;
                                                                })
                                                            }
                                                            {
                                                                favorite.rating ?
                                                                    Array(5 - Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                                                        return <FaRegStar />;
                                                                    }) :
                                                                    Array(5).fill(0).map((star) => {
                                                                        return <FaRegStar />;
                                                                    })
                                                            }
                                                        </td> */}
                                                        <td className="px-4 py-2">
                                                            <div className='flex gap-1 items-center text-queen dark:text-king mb-1'>
                                                                {
                                                                    favorite.rating && Array(Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                                                        return <FaStar />;
                                                                    })
                                                                }
                                                                {
                                                                    favorite.rating ?
                                                                        Array(5 - Math.ceil(favorite.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / favorite.rating?.length)).fill(0).map((star) => {
                                                                            return <FaRegStar />;
                                                                        }) :
                                                                        Array(5).fill(0).map((star) => {
                                                                            return <FaRegStar />;
                                                                        })
                                                                }
                                                            </div>
                                                            {
                                                                favorite.rating ?
                                                                    <span className="mr-2 text-dark dark:text-light">{favorite.rating?.length} Reviews</span> :
                                                                    <span className="mr-2 text-dark dark:text-light">0 Reviews</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                <button
                                                                    onClick={() => setOpenFavorite(openFavorite === favorite.id ? null : favorite.id)}
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                >
                                                                    {
                                                                        openFavorite === favorite.id ?
                                                                            <FaChevronUp className='text-base md:text-lg' /> :
                                                                            <FaChevronDown className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        openFavorite === favorite.id ?
                                                            <td colspan="8" className='bg-light dark:bg-dark'>
                                                                <FavoriteUsers users={favorite.users} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Favorites