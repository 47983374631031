import { Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import SideNav from '../Components/SideNav'
import Dashboard from '../Layouts/Dashboard/Dashborad'
import Users from '../Layouts/Dashboard/Users/Users'
import Slides from '../Layouts/Dashboard/Slides/Slides'
import Services from '../Layouts/Dashboard/Services/Services'
import Requests from '../Layouts/Dashboard/Requests/Requests'
import Stores from '../Layouts/Dashboard/Stores/Stores'
import Brands from '../Layouts/Dashboard/Brands/Brands'
import Types from '../Layouts/Dashboard/Types/Types'
import Categories from '../Layouts/Dashboard/Categories/Categories'
import Products from '../Layouts/Dashboard/Products/Products'
import ProductAdd from '../Layouts/Dashboard/Products/ProductAdd'
import Product from '../Layouts/Dashboard/Products/Product'
import ProductUpdate from '../Layouts/Dashboard/Products/ProductUpdate'
import Favorites from '../Layouts/Dashboard/Favorites/Favorites'
import Orders from '../Layouts/Dashboard/Orders/Orders'
import Invoices from '../Layouts/Dashboard/Invoices/Invoices'
import Jobs from '../Layouts/Dashboard/Jobs/Jobs'
import Reviews from '../Layouts/Dashboard/Reviews/Reviews'
import Advertises from '../Layouts/Dashboard/Advertises/Advertises'
import NotFound from '../Layouts/NotFound'

const AdminRoutes = () => {
    const authState = useSelector((state) => state.auth);
    const routesArray = [
        { path: '/dashboard', element: Dashboard },
        { path: '/users', element: Users },
        { path: '/slides', element: Slides },
        { path: '/services', element: Services },
        { path: '/requests', element: Requests },
        { path: '/stores', element: Stores },
        { path: '/brands', element: Brands },
        { path: '/types', element: Types },
        { path: '/categories', element: Categories },
        { path: '/products', element: Products },
        { path: '/products/add', element: ProductAdd },
        { path: '/products/:id', element: Product },
        { path: '/products/:id/update', element: ProductUpdate },
        { path: '/favorites', element: Favorites },
        { path: '/orders', element: Orders },
        { path: '/invoices', element: Invoices },
        { path: '/jobs', element: Jobs },
        { path: '/reviews', element: Reviews },
        { path: '/advertises', element: Advertises },
    ]
    return (
        <>
            {
                authState.auth && authState.auth.user?.role === "admin" ?
                    <>
                        <SideNav />
                        <Routes>
                            <Route index element={<Dashboard />} />
                            {
                                routesArray.map((route) => {
                                    return <Route key={route.path} path={route.path} element=<route.element /> />
                                })
                            }
                            <Route path="*" element={<NotFound reDirTo={"/admin"} />} />
                        </Routes>
                    </> :
                    <>
                        <Navigate to="/login" />
                    </>
            }
        </>
    )
}
export default AdminRoutes