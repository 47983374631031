import React from 'react'

const SectionContainer = ({ children }) => {
    return (
        <div className='my-2.5 md:my-5 py-5 md:py-10'>
            {children}
        </div>
    )
}

export default SectionContainer