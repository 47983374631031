import axios from 'axios'

const viewBrands = async () => {
  const response = await axios.get('/api/view-brands')
  return response
}

const addBrand = async (data) => {
  const response = await axios.post('/api/add-brand', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewBrand = async ({ id }) => {
  const response = await axios.post('/api/view-brand/' + id)
  return response
}

const updateBrand = async ({ id, data }) => {
  const response = await axios.post('/api/update-brand/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteBrand = async ({ id }) => {
  const response = await axios.delete('/api/delete-brand/' + id)
  return response
}

const searchBrands = async ({ key }) => {
  const response = await axios.get('/api/search-brands?key=' + key)
  return response
}

const brandsService = {
  viewBrands,
  addBrand,
  viewBrand,
  updateBrand,
  deleteBrand,
  searchBrands
}

export default brandsService