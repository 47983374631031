import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ordersService from './ordersService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  orders: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewOrders = createAsyncThunk(
  'orders/viewOrders',
  async (_, thunkAPI) => {
    try {
      const response = await ordersService.viewOrders()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addOrder = createAsyncThunk(
  'orders/addOrder',
  async (data, thunkAPI) => {
    try {
      const response = await ordersService.addOrder(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewOrder = createAsyncThunk(
  'orders/viewOrder',
  async (data, thunkAPI) => {
    try {
      const response = await ordersService.viewOrder(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateOrder = createAsyncThunk(
  'orders/updateOrder',
  async (data, thunkAPI) => {
    try {
      const response = await ordersService.updateOrder(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (data, thunkAPI) => {
    try {
      const response = await ordersService.deleteOrder(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchOrders = createAsyncThunk(
  'orders/searchOrders',
  async (data, thunkAPI) => {
    try {
      const response = await ordersService.searchOrders(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.orders = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewOrders.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.orders = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewOrders"
        state.time = Date.now()
      })
      .addCase(viewOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = (action.payload)[0].orders
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addOrder.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addOrder"
        state.time = Date.now()
      })
      .addCase(addOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = [...state.orders, (action.payload)[0].order]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewOrder.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewOrder"
        state.time = Date.now()
      })
      .addCase(viewOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.orders.length === 0) {
          state.orders = [(action.payload)[0].order]
        }
        else {
          state.orders = state.orders.map(order => {
            if (order.id === (action.payload)[0].order.id) {
              return (action.payload)[0].order
            }
            else {
              return order
            }
          })
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateOrder.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateOrder"
        state.time = Date.now()
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.orders.length === 0) {
          state.orders = [(action.payload)[0].order]
        }
        else {
          state.orders = state.orders.map(order => {
            if (order.id === (action.payload)[0].order.id) {
              return (action.payload)[0].order
            }
            else {
              return order
            }
          })
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteOrder.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteOrder"
        state.time = Date.now()
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = state.orders.filter(order => {
          return order.id !== (action.payload)[0].order.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchOrders.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.orders = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchOrders"
        state.time = Date.now()
      })
      .addCase(searchOrders.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.orders = (action.payload)[0].orders
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchOrders.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = ordersSlice.actions
export default ordersSlice.reducer