import React from 'react'

const Container = ({ children }) => {
    return (
        <div className="mx-auto px-4 md:px-8 lg:px-16 py-8 md:py-16 lg:py-16 max-w-[1250px]">
            {children}
        </div>
    )
}

export default Container