import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewProducts } from '../../Redux/Features/Products/productsSlice';
import { FaCaretDown, FaCaretRight, FaCheckSquare, FaLightbulb, FaSquare } from 'react-icons/fa';

const ProductsFilter = ({ setProducts }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const productsState = useSelector((state) => state.products);
    // const [products, setProducts] = useState(productsState.products);

    const [brands, setBrands] = useState([])
    const [openBrands, setOpenBrands] = useState(true);
    const [checkedBrands, setCheckedBrands] = useState([]);

    const [types, setTypes] = useState([])
    const [openTypes, setOpenTypes] = useState(false);
    const [checkedTypes, setCheckedTypes] = useState([]);

    const [categories, setCategories] = useState([])
    const [openCategories, setOpenCategories] = useState(false);
    const [checkedCategories, setCheckedCategories] = useState([]);

    const [colors, setColors] = useState([])
    const [openColors, setOpenColors] = useState(false);
    const [checkedColors, setCheckedColors] = useState([]);

    const [sizes, setSizes] = useState([])
    const [openSizes, setOpenSizes] = useState(false);
    const [checkedSizes, setCheckedSizes] = useState([]);

    const toggleBrand = (brand) => {
        const brandIndex = checkedBrands.includes(brand);

        let updatedBrands = [...checkedBrands]

        if (brandIndex) {
            const brandIndex = checkedBrands.indexOf((_brand) => _brand === brand);
            updatedBrands.splice(brandIndex, 1);
            setCheckedBrands(updatedBrands);
        } else {
            updatedBrands = [...checkedBrands, brand]
            setCheckedBrands(updatedBrands);
        }

        let filteredProducts = []
        if (updatedBrands.length > 0) {
            filteredProducts = productsState.products.filter(product => updatedBrands.includes(product.category.type.brand.name));
        } else {
            filteredProducts = productsState.products;
        }

        if (checkedTypes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedTypes.includes(product.category.type.name));
        }

        if (checkedCategories.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedCategories.includes(product.category.name));
        }

        if (checkedColors.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedColors.some(color => product.colors.includes(color));
            });
        }

        if (checkedSizes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedSizes.some(size => product.sizes.includes(size));
            });
        }

        setProducts(filteredProducts)
    }

    const toggleType = (type) => {
        const typeIndex = checkedTypes.includes(type);

        let updatedTypes = [...checkedTypes]

        if (typeIndex) {
            const typeIndex = checkedTypes.indexOf((_type) => _type === type);
            updatedTypes.splice(typeIndex, 1);
            setCheckedTypes(updatedTypes);
        } else {
            updatedTypes = [...checkedTypes, type]
            setCheckedTypes(updatedTypes);
        }

        let filteredProducts = []
        if (updatedTypes.length > 0) {
            filteredProducts = productsState.products.filter(product => updatedTypes.includes(product.category.type.name));
        } else {
            filteredProducts = productsState.products;
        }

        if (checkedBrands.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedBrands.includes(product.category.type.brand.name));
        }

        if (checkedCategories.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedCategories.includes(product.category.name));
        }

        if (checkedColors.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedColors.some(color => product.colors.includes(color));
            });
        }

        if (checkedSizes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedSizes.some(size => product.sizes.includes(size));
            });
        }

        setProducts(filteredProducts)
    }

    const toggleCategory = (category) => {
        const categoryIndex = checkedCategories.includes(category);

        let updatedCategories = [...checkedCategories]

        if (categoryIndex) {
            const categoryIndex = checkedCategories.indexOf((_category) => _category === category);
            updatedCategories.splice(categoryIndex, 1);
            setCheckedCategories(updatedCategories);
        } else {
            updatedCategories = [...checkedCategories, category]
            setCheckedCategories(updatedCategories);
        }

        let filteredProducts = []
        if (updatedCategories.length > 0) {
            filteredProducts = productsState.products.filter(product => updatedCategories.includes(product.category.name));
        } else {
            filteredProducts = productsState.products;
        }

        if (checkedBrands.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedBrands.includes(product.category.type.brand.name));
        }

        if (checkedTypes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedTypes.includes(product.category.type.name));
        }

        if (checkedColors.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedColors.some(color => product.colors.includes(color));
            });
        }

        if (checkedSizes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedSizes.some(size => product.sizes.includes(size));
            });
        }

        setProducts(filteredProducts)
    }

    const toggleColor = (color) => {
        const colorIndex = checkedColors.includes(color);

        let updatedColors = [...checkedColors]

        if (colorIndex) {
            const colorIndex = checkedColors.indexOf((_color) => _color === color);
            updatedColors.splice(colorIndex, 1);
            setCheckedColors(updatedColors);
        } else {
            updatedColors = [...checkedColors, color]
            setCheckedColors(updatedColors);
        }

        let filteredProducts = []
        if (updatedColors.length > 0) {
            filteredProducts = productsState.products.filter(product => {
                return updatedColors.some(color => product.colors.includes(color));
            });
        } else {
            filteredProducts = productsState.products;
        }

        if (checkedBrands.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedBrands.includes(product.category.type.brand.name));
        }

        if (checkedTypes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedTypes.includes(product.category.type.name));
        }

        if (checkedCategories.length > 0) {
            filteredProducts = filteredProducts.filter(product => checkedCategories.includes(product.category.name));
        }

        if (checkedSizes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedSizes.some(size => product.sizes.includes(size));
            });
        }

        setProducts(filteredProducts)
    }

    const toggleSize = (size) => {
        const sizeIndex = checkedSizes.includes(size);

        let updatedSizes = [...checkedSizes]

        if (sizeIndex) {
            const sizeIndex = checkedSizes.indexOf((_size) => _size === size);
            updatedSizes.splice(sizeIndex, 1);
            setCheckedSizes(updatedSizes);
        } else {
            updatedSizes = [...checkedSizes, size]
            setCheckedSizes(updatedSizes);
        }

        let filteredProducts = []
        if (updatedSizes.length > 0) {
            filteredProducts = productsState.products.filter(product => {
                return updatedSizes.some(size => product.sizes.includes(size));
            });
        } else {
            filteredProducts = productsState.products;
        }

        if (checkedBrands.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedBrands.includes(product.category.type.brand.name));
        }

        if (checkedTypes.length > 0) {
            filteredProducts = filteredProducts?.filter(product => checkedTypes.includes(product.category.type.name));
        }

        if (checkedCategories.length > 0) {
            filteredProducts = filteredProducts.filter(product => checkedCategories.includes(product.category.name));
        }

        if (checkedColors.length > 0) {
            filteredProducts = filteredProducts?.filter(product => {
                return checkedColors.some(color => product.colors.includes(color));
            });
        }

        setProducts(filteredProducts)
    }

    useEffect(() => {
        setProducts(productsState.products)

        // Brands
        const uniqueBrands = [...new Set(productsState.products.map(product => product.category.type.brand.name))];
        setBrands(uniqueBrands.sort())

        // Types
        const uniqueTypes = [...new Set(productsState.products.map(product => product.category.type.name))];
        setTypes(uniqueTypes.sort())

        // Categories
        const uniqueCategories = [...new Set(productsState.products.map(product => product.category.name))];
        setCategories(uniqueCategories.sort())

        // Colors
        const uniqueColors = [...new Set(productsState.products.flatMap(product => product.colors))];
        const sortedColors = uniqueColors.sort((color1, color2) => {
            const val1 = parseInt(color1.slice(1), 16);
            const val2 = parseInt(color2.slice(1), 16);

            return val1 - val2;
        });
        setColors(sortedColors)

        // Sizes
        const uniqueSizes = [...new Set(productsState.products.flatMap(product => product.sizes))];
        const unitMap = {};
        for (const item of uniqueSizes) {
            const match = item.match(/[a-zA-Z]+$/);
            if (match) {
                const unit = match[0];
                if (!unitMap[unit]) {
                    unitMap[unit] = [];
                }
                unitMap[unit].push(item);
            }
        }
        for (const unit in unitMap) {
            unitMap[unit].sort();
        }
        const sortedUnits = Object.keys(unitMap).sort();
        const mergedArray = sortedUnits.reduce((result, unit) => result.concat(unitMap[unit]), []);
        setSizes(mergedArray)

    }, [productsState.products])

    // useEffect(() => {
    //     let data = {
    //         brands: checkedBrands.join(","),
    //         types: checkedTypes.join(","),
    //         categories: checkedCategories.join(","),
    //         colors: checkedColors.join(",").replace("#", "%23"), // (without URLSearchParams)
    //         sizes: checkedSizes.join(","),
    //     }

    //     data = Object.entries(data).reduce((acc, [key, value]) => {
    //         if (value !== '' && value !== undefined) {
    //             acc[key] = value;
    //         }
    //         return acc;
    //     }, {});

    //     if (Object.keys(data).length > 0) {
    //         // 2 (is not apparent in the URL address)
    //         // let queryString = Object.entries(data)
    //         //     .map(([key, value]) => `${key}=${value}`)
    //         //     .join('&');

    //         // if (queryString) {
    //         //     queryString = '?' + queryString;
    //         // }

    //         // const data_ = {
    //         //     params: queryString
    //         // }

    //         // dispatch(viewProducts(data_))

    //         //3 (is apparent in the URL address)
    //         // const params = new URLSearchParams();
    //         // for (const key in data) {
    //         //     params.append(key, data[key]);
    //         // }

    //         // const queryString = params.toString();
    //         // navigate(`/products?${queryString}`);
    //     }
    // }, [checkedBrands, checkedTypes, checkedCategories, checkedColors, checkedSizes])

    return (
        <div className='flex flex-col gap-1 sm:gap-2 md:gap-4'>
            <div>
                <h2 onClick={() => setOpenBrands((prev) => !prev)} className='cursor-pointer hover:opacity-80 duration-150 text-base md:text-lg text-dark dark:text-light flex items-center gap-1 font-medium mb-2'>
                    {
                        openBrands ?
                            <FaCaretDown className='-ml-1 mt-0.5' /> :
                            <FaCaretRight className='-ml-1 mt-0.5' />
                    }
                    Brands:
                </h2>
                {
                    openBrands &&
                    <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-1'>
                        {brands?.map((brand) => {
                            return (
                                <button
                                    onClick={() => toggleBrand(brand)}
                                    className="text-sm md:text-base text-dark dark:text-light flex items-center gap-1.5 font-normal mb-1"
                                    key={brand}
                                >
                                    {
                                        checkedBrands.includes(brand) ?
                                            <FaCheckSquare className="text-xl text-l-extra dark:text-d-extra border border-light dark:border-dark" /> :
                                            <FaSquare className="text-[17px] ml-[1px] text-queen/5 dark:text-king/5 border border-l-extra/75 dark:border-d-extra/75 rounded-sm" />
                                    }
                                    {brand}
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
            <div>
                <h2 onClick={() => setOpenTypes((prev) => !prev)} className='cursor-pointer hover:opacity-80 duration-150 text-base md:text-lg text-dark dark:text-light flex items-center gap-1 font-medium mb-2'>
                    {
                        openTypes ?
                            <FaCaretDown className='-ml-1 mt-0.5' /> :
                            <FaCaretRight className='-ml-1 mt-0.5' />
                    }
                    Types:
                </h2>
                {
                    openTypes &&
                    <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-1'>
                        {types?.map((type) => {
                            return (
                                <button
                                    onClick={() => toggleType(type)}
                                    className="text-sm md:text-base text-dark dark:text-light flex items-center gap-1.5 font-normal mb-1"
                                    key={type}
                                >
                                    {
                                        checkedTypes.includes(type) ?
                                            <FaCheckSquare className="text-xl text-l-extra dark:text-d-extra border border-light dark:border-dark" /> :
                                            <FaSquare className="text-[17px] ml-[1px] text-queen/5 dark:text-king/5 border border-l-extra/75 dark:border-d-extra/75 rounded-sm" />
                                    }
                                    {type}
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
            <div>
                <h2 onClick={() => setOpenCategories((prev) => !prev)} className='cursor-pointer hover:opacity-80 duration-150 text-base md:text-lg text-dark dark:text-light flex items-center gap-1 font-medium mb-2'>
                    {
                        openCategories ?
                            <FaCaretDown className='-ml-1 mt-0.5' /> :
                            <FaCaretRight className='-ml-1 mt-0.5' />
                    }
                    Categories:
                </h2>
                {
                    openCategories &&
                    <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-1'>
                        {categories?.map((category) => {
                            return (
                                <button
                                    onClick={() => toggleCategory(category)}
                                    className="text-sm md:text-base text-dark dark:text-light flex items-center gap-1.5 font-normal mb-1"
                                    key={category}
                                >
                                    {
                                        checkedCategories.includes(category) ?
                                            <FaCheckSquare className="text-xl text-l-extra dark:text-d-extra border border-light dark:border-dark" /> :
                                            <FaSquare className="text-[17px] ml-[1px] text-queen/5 dark:text-king/5 border border-l-extra/75 dark:border-d-extra/75 rounded-sm" />
                                    }
                                    {category}
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
            <div>
                <h2 onClick={() => setOpenColors((prev) => !prev)} className='cursor-pointer hover:opacity-80 duration-150 text-base md:text-lg text-dark dark:text-light flex items-center gap-1 font-medium mb-2'>
                    {
                        openColors ?
                            <FaCaretDown className='-ml-1 mt-0.5' /> :
                            <FaCaretRight className='-ml-1 mt-0.5' />
                    }
                    Colors:
                </h2>
                {
                    openColors &&
                    <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-1'>
                        {colors?.map((color) => {
                            return (
                                <button
                                    onClick={() => toggleColor(color)}
                                    className="text-sm md:text-base text-dark dark:text-light flex items-center gap-1.5 font-normal mb-1"
                                    key={color}
                                >
                                    {
                                        checkedColors.includes(color) ?
                                            <FaCheckSquare className="text-xl text-l-extra dark:text-d-extra border border-light dark:border-dark" /> :
                                            <FaSquare className="text-[17px] ml-[1px] text-queen/5 dark:text-king/5 border border-l-extra/75 dark:border-d-extra/75 rounded-sm" />
                                    }
                                    {/* {color} */}
                                    <FaLightbulb title={color} style={{ color: color }} className='inline text-lg md:text-xl font-normal rotate-45' />
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
            <div>
                <h2 onClick={() => setOpenSizes((prev) => !prev)} className='cursor-pointer hover:opacity-80 duration-150 text-base md:text-lg text-dark dark:text-light flex items-center gap-1 font-medium mb-2'>
                    {
                        openSizes ?
                            <FaCaretDown className='-ml-1 mt-0.5' /> :
                            <FaCaretRight className='-ml-1 mt-0.5' />
                    }
                    Sizes:
                </h2>
                {
                    openSizes &&
                    <div className='grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-1'>
                        {sizes?.map((size) => {
                            return (
                                <button
                                    onClick={() => toggleSize(size)}
                                    className="text-sm md:text-base text-dark dark:text-light flex items-center gap-1.5 font-normal mb-1"
                                    key={size}
                                >
                                    {
                                        checkedSizes.includes(size) ?
                                            <FaCheckSquare className="text-xl text-l-extra dark:text-d-extra border border-light dark:border-dark" /> :
                                            <FaSquare className="text-[17px] ml-[1px] text-queen/5 dark:text-king/5 border border-l-extra/75 dark:border-d-extra/75 rounded-sm" />
                                    }
                                    {size}
                                </button>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    )
}

export default ProductsFilter