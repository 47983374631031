import axios from 'axios'

const viewOrders = async () => {
  const response = await axios.get("/api/view-orders")
  return response
}

const addOrder = async (data) => {
  const response = await axios.post('/api/add-order', data)
  return response
}

const viewOrder = async ({ id }) => {
  const response = await axios.post('/api/view-order/' + id)
  return response
}

const updateOrder = async ({ id, data }) => {
  console.log({ id, data })
  const response = await axios.put('/api/update-order/' + id, data)
  return response
}

const deleteOrder = async ({ id }) => {
  const response = await axios.delete('/api/delete-order/' + id)
  return response
}

const searchOrders = async ({ key }) => {
  const response = await axios.get('/api/search-orders?key=' + key)
  return response
}

const ordersService = {
  viewOrders,
  addOrder,
  viewOrder,
  updateOrder,
  deleteOrder,
  searchOrders,
}

export default ordersService