import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewSlides, searchSlides, deleteSlide, updateSlide, addSlide } from "../../../Redux/Features/Slides/slidesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Slides = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const slidesState = useSelector((state) => state.slides);
    const searchInput = useRef();
    const titleInput = useRef();
    const imageInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchSlidesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchSlides(data))
    }

    const addSlideAlt = () => {
        let data = {
            title: titleInput.current.value,
            image: imageInput.current.files[0]
        }

        dispatch(addSlide(data))
    }

    const updateSlideAlt = (slide_id) => {
        let data = {
            title: titleInput.current.value,
            image: imageInput.current.files[0]
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateSlide({ data, id: slide_id }))
        }
    }

    const deleteSlideAlt = (slide_id) => {
        const data = {
            id: slide_id
        }

        dispatch(deleteSlide(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewSlides())
    }, [])

    useEffect(() => {
        if ((Date.now() - slidesState.time < 100) && (slidesState.status === 200 || slidesState.status === 201) && slidesState.message && (slidesState.operation === "viewSlides" || slidesState.operation === "searchSlides" || slidesState.operation === "addSlide" || slidesState.operation === "updateSlide" || slidesState.operation === "deleteSlide")) {
            // toastify("success", slidesState.status, slidesState.message)
        }
        else if ((Date.now() - slidesState.time < 100) && (slidesState.status === 400 || slidesState.status === 401 || slidesState.status === 403 /*|| slidesState.status === 404*/) && slidesState.message && (slidesState.operation === "viewSlides" || slidesState.operation === "searchSlides" || slidesState.operation === "addSlide" || slidesState.operation === "updateSlide" || slidesState.operation === "deleteSlide")) {
            toastify("error", slidesState.status, slidesState.message)
        }
        else if ((Date.now() - slidesState.time < 100) && slidesState.status === 500 && slidesState.message && (slidesState.operation === "viewSlides" || slidesState.operation === "searchSlides" || slidesState.operation === "addSlide" || slidesState.operation === "updateSlide" || slidesState.operation === "deleteSlide")) {
            toastify("warn", slidesState.status, slidesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [slidesState.status, slidesState.message, slidesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Slides
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by title"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchSlidesAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    slidesState.isLoading && slidesState.operation !== "addSlide" && slidesState.operation !== "updateSlide" && slidesState.operation !== "deleteSlide" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Title
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Image
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="4"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Slides
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        slidesState.slides.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={titleInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                    "Title"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addSlideAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && slidesState.isLoading && slidesState.operation === "addSlide" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && slidesState.errors && slidesState.operation === "addSlide" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        slidesState.errors && slidesState.errors.title && slidesState.operation === "addSlide" ?
                                                            slidesState.errors && slidesState.errors.title : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        slidesState.errors && slidesState.errors.image && slidesState.operation === "addSlide" ?
                                                            slidesState.errors && slidesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="4"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Slides Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={titleInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                    "Title"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addSlideAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && slidesState.isLoading && slidesState.operation === "addSlide" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && slidesState.errors && slidesState.operation === "addSlide" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        slidesState.errors && slidesState.errors.title && slidesState.operation === "addSlide" ?
                                                            slidesState.errors && slidesState.errors.title : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        slidesState.errors && slidesState.errors.image && slidesState.operation === "addSlide" ?
                                                            slidesState.errors && slidesState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        slidesState.slides.map((slide, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === slide.id && slidesState.errors && slidesState.operation === "updateSlide" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === slide.id ?
                                                                    <input ref={titleInput} defaultValue={slide.title} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Title' type='text' /> :
                                                                    slide.title
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === slide.id ?
                                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                                    slide.image ?
                                                                        <a target='_blank' href={process.env.REACT_APP_API_BASE_URL +"/"+ slide.image} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View</a> :
                                                                        <span className='px-1'>...</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === slide.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(slide.id); updateSlideAlt(slide.id); setShowUpdateErrors(slide.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(slide.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === slide.id && slidesState.isLoading && slidesState.operation === "updateSlide" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(slide.id); deleteSlideAlt(slide.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === slide.id && slidesState.isLoading && slidesState.operation === "deleteSlide" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === slide.id && slidesState.errors && slidesState.operation === "updateSlide" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        slidesState.errors && slidesState.errors.title && slidesState.operation === "updateSlide" ?
                                                                            slidesState.errors && slidesState.errors.title : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        slidesState.errors && slidesState.errors.image && slidesState.operation === "updateSlide" ?
                                                                            slidesState.errors && slidesState.errors.image : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Slides