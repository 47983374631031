import { toast } from "react-toastify";
import Modal from 'react-modal';
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";

export const toastify = (type, code, message) => {
    const toastOptions = {
        position: "top-center",
        autoClose: 1500,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        // theme: localStorage.getItem("theme") === "light" ? "light" : "dark",
    };
    switch (type) {
        case "success":
            toast.success(
                /* "Code " + code + " | " + */message,
                toastOptions
            );
            break;
        case "info":
            toast.info(
                /* "Code " + code + " | " + */message,
                toastOptions
            );
            break;
        case "warn":
            toast.warn(
                /* "Code " + code + " | " + */message,
                toastOptions
            );
            break;
        case "error":
            toast.error(
                /* "Code " + code + " | " + */message,
                toastOptions
            );
            break;
        default:
            toast.dark(
                /* "Code " + code + " | " + */message,
                toastOptions
            );
            break;
    }
}

export const confirmModal = (openModal, setOpenModal, proccessName, action, actionParameter, loading) => {
    const modalStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '320px',
            height: '160px',
            borderWidth: '1px',
            padding: '0px',
            fontSize: '16px',
            borderRadius: '0px',
            background: localStorage.getItem("theme") === "dark" ? "#202124" : "#d3ebef",
            color: localStorage.getItem("theme") === "dark" ? "#efefef" : "#151515",
            borderColor: localStorage.getItem("theme") === "dark" ? "#607d8b" : "#2196f3",
        }
    }
    const okSubmit = () => {
        action(actionParameter)
        setTimeout(() => {
            setOpenModal(false)
        }, 1000);
    }
    return (
        <Modal isOpen={openModal} style={modalStyle}>
            <div className="p-4">
                <p className="text-center text-lg px-4 mb-8">
                    {`Please confirm ${proccessName} proccess !`}
                </p>
                <div className="flex justify-around items-center">
                    <button onClick={() => setOpenModal(false)} style={{ background: localStorage.getItem("theme") === "dark" ? "#607d8b" : "#2196f3" }} className="text-sm flex gap-1 justify-center items-center px-4 py-2 text-light cursor-pointer shadow-inner shadow-light/50 rounded-sm hover:opacity-90 transition-all duration-150 w-24">
                        <FaTimes className="inline mr-1" />
                        Cancel
                    </button>
                    <button onClick={() => { okSubmit() }} style={{ background: localStorage.getItem("theme") === "dark" ? "#607d8b" : "#2196f3" }} className="text-sm flex gap-1 justify-center items-center px-4 py-2 text-light cursor-pointer shadow-inner shadow-light/50 rounded-sm hover:opacity-90 transition-all duration-150 w-24">
                        {
                            loading ?
                                <FaSpinner className="inline mr-1 animate-spin" /> :
                                <FaCheck className="inline mr-1" />
                        }
                        Ok
                    </button>
                </div>
            </div>
        </Modal>
    )
}