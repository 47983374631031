import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import slidesService from './slidesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  slides: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewSlides = createAsyncThunk(
  'slides/viewSlides',
  async (_, thunkAPI) => {
    try {
      const response = await slidesService.viewSlides()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addSlide = createAsyncThunk(
  'slides/addSlide',
  async (data, thunkAPI) => {
    try {
      const response = await slidesService.addSlide(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewSlide = createAsyncThunk(
  'slides/viewSlide',
  async (data, thunkAPI) => {
    try {
      const response = await slidesService.viewSlide(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateSlide = createAsyncThunk(
  'slides/updateSlide',
  async (data, thunkAPI) => {
    try {
      const response = await slidesService.updateSlide(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteSlide = createAsyncThunk(
  'slides/deleteSlide',
  async (data, thunkAPI) => {
    try {
      const response = await slidesService.deleteSlide(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchSlides = createAsyncThunk(
  'slides/searchSlides',
  async (data, thunkAPI) => {
    try {
      const response = await slidesService.searchSlides(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const slidesSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.slides = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewSlides.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.slides = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewSlides"
        state.time = Date.now()
      })
      .addCase(viewSlides.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = (action.payload)[0].slides
        state.translatedSlide = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewSlides.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addSlide.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addSlide"
        state.time = Date.now()
      })
      .addCase(addSlide.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = [...state.slides, (action.payload)[0].slide]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addSlide.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewSlide.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewSlide"
        state.time = Date.now()
      })
      .addCase(viewSlide.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = state.slides.map(slide => {
          if (slide.id === (action.payload)[0].slide.id) {
            return (action.payload)[0].slide
          }
          else {
            return slide
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewSlide.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateSlide.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateSlide"
        state.time = Date.now()
      })
      .addCase(updateSlide.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = state.slides.map(slide => {
          if (slide.id === (action.payload)[0].slide.id) {
            return (action.payload)[0].slide
          }
          else {
            return slide
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateSlide.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteSlide.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteSlide"
        state.time = Date.now()
      })
      .addCase(deleteSlide.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = state.slides.filter(slide => {
          return slide.id !== (action.payload)[0].slide.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteSlide.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchSlides.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.slides = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchSlides"
        state.time = Date.now()
      })
      .addCase(searchSlides.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.slides = (action.payload)[0].slides
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchSlides.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = slidesSlice.actions
export default slidesSlice.reducer