import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { FaArrowLeft, FaArrowRight, FaEllipsisH } from 'react-icons/fa';
import LayoutLoading from '../LayoutLoading';
import LayoutNotFound from '../LayoutNotFound';
import FavoriteCard from "./FavoriteCard"

const FavoritesCards = ({ favorites }) => { // it is comming from filtering
    const favoritesState = useSelector((state) => state.favorites);

    const Favorites = ({ currentFavorites }) => {
        return (
            <div className="grid gap-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {
                    currentFavorites.map((favorite) => (
                        <FavoriteCard favorite={favorite} />
                    ))}
            </div>
        );
    }

    const PaginatedFavorites = ({ favoritesPerPage }) => {
        const [favoriteOffset, setFavoriteOffset] = useState(0);
        const endOffset = favoriteOffset + favoritesPerPage;
        const currentFavorites = favorites?.slice(favoriteOffset, endOffset);
        const pageCount = Math.ceil(favorites?.length / favoritesPerPage);
        const handlePageClick = (event) => {
            const newOffset = (event.selected * favoritesPerPage) % favorites.length;
            setFavoriteOffset(newOffset);
        };

        return (
            <div>
                <Favorites currentFavorites={currentFavorites} />
                <ReactPaginate
                    breakLabel={<FaEllipsisH className='text-base text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    nextLabel={<FaArrowRight className='text-xl text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount || 0}
                    previousLabel={<FaArrowLeft className='text-xl text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    renderOnZeroPageCount={null}
                    containerClassName='flex justify-center items-center gap-1 my-4 md:my-8'
                    pageClassName='!text-dark dark:!text-light bg-transparent border border-l-extra/75 dark:border-d-extra/75 shadow-none px-3 py-1 rounded-md hover:opacity-80 duration-150 cursor-pointer'
                    activeClassName='border !border-transparent !bg-l-extra/75 dark:!bg-d-extra/75 shadow-inner !shadow-light px-3 py-1'
                    activeLinkClassName='text-light dark:text-light'
                    previousClassName='px-2'
                    nextClassName='px-2'
                    disabledClassName='opacity-25'
                    disabledLinkClassName='cursor-not-allowed'
                />
            </div>
        );
    }

    return (
        <>
            {
                favoritesState.isLoading && favoritesState.operation === "searchFavorites" ?
                    <LayoutLoading title={"favorites"} /> :
                    (favoritesState.favorites.length === 0 && favoritesState.operation === "searchFavorites") || favorites.length === 0 ?
                        <LayoutNotFound title={"Favorites"} /> :
                        <div id="container">
                            <PaginatedFavorites favoritesPerPage={6} />
                        </div>
            }
        </>
    )
}

export default FavoritesCards