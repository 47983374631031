import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import reviewsService from './reviewsService' 

const initialState = {
  isLoading: null,
  isSuccess: null,
  reviews: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewReviews = createAsyncThunk(
  'reviews/viewReviews',
  async (_, thunkAPI) => {
    try {
      const response = await reviewsService.viewReviews()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

// export const addReview = createAsyncThunk(
//   'reviews/addReview',
//   async (data, thunkAPI) => {
//     try {
//       const response = await reviewsService.addReview(data)
//       return thunkAPI.fulfillWithValue([response.data, response.status])
//     } catch (error) {
//       return thunkAPI.rejectWithValue([error.response.data, error.response.status])
//     }
//   }
// )

export const updateReview = createAsyncThunk(
  'reviews/updateReview',
  async (data, thunkAPI) => {
    try {
      const response = await reviewsService.updateReview(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchReviews = createAsyncThunk(
  'reviews/searchReviews',
  async (data, thunkAPI) => {
    try {
      const response = await reviewsService.searchReviews(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.reviews = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewReviews.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.reviews = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewReviews"
        state.time = Date.now()
      })
      .addCase(viewReviews.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.reviews = (action.payload)[0].reviews
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewReviews.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      // .addCase(addReview.pending, (state) => {
      //   state.isLoading = true
      //   state.isSuccess = null
      //   state.message = null
      //   state.errors = null
      //   state.status = null
      //   state.operation = "addReview"
      //   state.time = Date.now()
      // })
      // .addCase(addReview.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = true
      //   state.reviews = [...state.reviews, (action.payload)[0].review]
      //   state.message = (action.payload)[0].message
      //   state.status = (action.payload)[1]
      //   state.time = Date.now()
      // })
      // .addCase(addReview.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = false
      //   state.message = (action.payload)[0].message
      //   state.errors = (action.payload)[0].errors
      //   state.status = (action.payload)[1]
      //   state.time = Date.now()
      // })
      
      .addCase(updateReview.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateReview"
        state.time = Date.now()
      })
      .addCase(updateReview.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        // if (state.reviews.length === 0) {
        //   state.reviews = [(action.payload)[0].review]
        // }
        // else {
        //   state.reviews = state.reviews.map(review => {
        //     if (review.id === (action.payload)[0].review.id) {
        //       return (action.payload)[0].review
        //     }
        //     else {
        //       return review
        //     }
        //   })
        // }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateReview.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchReviews.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.reviews = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchReviews"
        state.time = Date.now()
      })
      .addCase(searchReviews.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.reviews = (action.payload)[0].reviews
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchReviews.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = reviewsSlice.actions
export default reviewsSlice.reducer