import axios from 'axios' 

const viewInvoices = async () => {
  const response = await axios.get("/api/view-invoices")
  return response
}

const addInvoice = async (data) => {
  const response = await axios.post('/api/add-invoice', data)
  return response
}

const viewInvoice = async ({ id }) => {
  const response = await axios.post('/api/view-invoice/' + id)
  return response
}

const updateInvoice = async ({ id, data }) => {
  console.log({ id, data })
  const response = await axios.put('/api/update-invoice/' + id, data)
  return response
}

const deleteInvoice = async ({ id }) => {
  const response = await axios.delete('/api/delete-invoice/' + id)
  return response
}

const searchInvoices = async ({ key }) => {
  const response = await axios.get('/api/search-invoices?key=' + key)
  return response
}

const invoicesService = {
  viewInvoices,
  addInvoice,
  viewInvoice,
  updateInvoice,
  deleteInvoice,
  searchInvoices,
}

export default invoicesService