import React, { useEffect, useRef, useState } from 'react'
import { updateReview } from '../../../Redux/Features/Reviews/reviewsSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toastify } from '../../../Helper';
import { FaSpinner } from 'react-icons/fa';

const ReviewUsers = ({ users }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reviewsState = useSelector((state) => state.reviews);
    const [updateUser, setUpdateUser] = useState(null)

    const updateReviewAlt = (isAccepted, job_id, user_id) => {
        setUpdateUser(user_id)
        let data = {
            is_accepted: isAccepted
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateReview({ data, job_id, user_id }))
        }
    }

    useEffect(() => {
        if ((Date.now() - reviewsState.time < 100) && reviewsState.status === 200 && reviewsState.message && reviewsState.operation === "updateReview") {
            toastify("success", reviewsState.status, reviewsState.message)
        }
        else if ((Date.now() - reviewsState.time < 100) && (reviewsState.status === 400 || reviewsState.status === 401 || reviewsState.status === 403 || reviewsState.status === 404) && reviewsState.message && reviewsState.operation === "updateReview") {
            toastify("error", reviewsState.status, reviewsState.message)
        }
        else if ((Date.now() - reviewsState.time < 100) && reviewsState.status === 500 && reviewsState.message && reviewsState.operation === "updateReview") {
            toastify("warn", reviewsState.status, reviewsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [reviewsState.status, reviewsState.message, reviewsState.errors])

    return (
        <div className="mx-auto max-w-6xl overflow-x-auto p-1">
            {
                <table className="w-full text-left bg-queenAlt dark:bg-kingAlt text-dark dark:text-light">
                    <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-bold">
                        <tr>
                            <th scope="col" className="px-4 py-2">
                                Reviewd by user
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Phone
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Email
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Address
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Resume
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Is Accepted
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-sm md:text-base'>
                        {
                            users.map((user, idx) => {
                                return (
                                    <tr className="border-b border-queen/25 dark:border-king/25">
                                        <td className="px-4 py-2 ">{user.name}</td>
                                        <td className="px-4 py-2 ">{user.phone}</td>
                                        <td className="px-4 py-2 ">{user.email}</td>
                                        <td className="px-4 py-2 ">{user.address}</td>
                                        <td className="px-4 py-2 ">
                                            {
                                                user.resume ?
                                                    <a target='_blank' href={process.env.REACT_APP_API_BASE_URL + "/" + user.resume} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View</a> :
                                                    <span className='px-1'>...</span>
                                            }
                                        </td>
                                        <td className="px-4 py-2 flex items-center gap-1">
                                            <select onChange={(e) => updateReviewAlt(e.target.value, user.job_id, user.id)} defaultValue={user.job_is_accepted} className="bg-transparent focus:outline-none">
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Accepted</option>
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                            </select>
                                            {
                                                updateUser === user.id && reviewsState.isLoading && reviewsState.operation === "updateReview" ?
                                                    <FaSpinner className='text-lg animate-spin inline' /> :
                                                    null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div >
    )
}

export default ReviewUsers