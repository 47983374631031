import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const JobCard = ({ job }) => {
    return (
        <div className=" text-dark dark:text-light rounded-md border border-queen/25 dark:border-king/25 relative overflow-hidden group shadow-inner shadow-queen/10 dark:shadow-king/10">
            <div className='bg-queen/[0.025] dark:bg-king/[0.025] flex gap-1 flex-col px-2 py-4 border-t border-queen/25 dark:border-king/25 h-full'>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-lg font-semibold truncate'>{job.position}</span>
                    <Link to={`/jobs/${job.id}`}>
                        <FaArrowRight className='text-base font-normal opacity-75 hover:opacity-100 transition-all duration-150 text-l-extra dark:text-d-extra' />
                    </Link>
                </div>
                <div className='flex gap-1 justify-between items-center'>
                    <span className='text-base font-normal line-clamp-2'>{job.description}</span>
                </div>
                <div className='flex gap-1 justify-between items-center text-queen dark:text-king'>
                    <span className='text-sm font-medium capitalize'>{job.location}</span>
                    <span className='text-sm font-medium capitalize'>{job.time}</span>
                </div>
            </div>
        </div>
    )
}

export default JobCard