import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import brandsService from './brandsService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  brands: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewBrands = createAsyncThunk(
  'brands/viewBrands',
  async (_, thunkAPI) => {
    try {
      const response = await brandsService.viewBrands()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addBrand = createAsyncThunk(
  'brands/addBrand',
  async (data, thunkAPI) => {
    try {
      const response = await brandsService.addBrand(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewBrand = createAsyncThunk(
  'brands/viewBrand',
  async (data, thunkAPI) => {
    try {
      const response = await brandsService.viewBrand(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateBrand = createAsyncThunk(
  'brands/updateBrand',
  async (data, thunkAPI) => {
    try {
      const response = await brandsService.updateBrand(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteBrand = createAsyncThunk(
  'brands/deleteBrand',
  async (data, thunkAPI) => {
    try {
      const response = await brandsService.deleteBrand(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchBrands = createAsyncThunk(
  'brands/searchBrands',
  async (data, thunkAPI) => {
    try {
      const response = await brandsService.searchBrands(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.brands = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewBrands.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.brands = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewBrands"
        state.time = Date.now()
      })
      .addCase(viewBrands.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = (action.payload)[0].brands
        state.translatedBrand = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewBrands.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addBrand.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addBrand"
        state.time = Date.now()
      })
      .addCase(addBrand.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = [...state.brands, (action.payload)[0].brand]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addBrand.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewBrand.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewBrand"
        state.time = Date.now()
      })
      .addCase(viewBrand.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = state.brands.map(brand => {
          if (brand.id === (action.payload)[0].brand.id) {
            return (action.payload)[0].brand
          }
          else {
            return brand
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewBrand.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateBrand.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateBrand"
        state.time = Date.now()
      })
      .addCase(updateBrand.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = state.brands.map(brand => {
          if (brand.id === (action.payload)[0].brand.id) {
            return (action.payload)[0].brand
          }
          else {
            return brand
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateBrand.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteBrand.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteBrand"
        state.time = Date.now()
      })
      .addCase(deleteBrand.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = state.brands.filter(brand => {
          return brand.id !== (action.payload)[0].brand.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteBrand.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchBrands.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.brands = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchBrands"
        state.time = Date.now()
      })
      .addCase(searchBrands.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.brands = (action.payload)[0].brands
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchBrands.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = brandsSlice.actions
export default brandsSlice.reducer