import React from 'react'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { decreaseQuantity, deleteProduct, increaseQuantity, changeSize, changeColor } from '../../Redux/Features/Cart/cartSlice';
import { FaMinusCircle, FaPlusCircle, FaTimesCircle } from 'react-icons/fa'
import LayoutNotFound from '../LayoutNotFound';

const CartItems = ({ cart }) => {
    const dispatch = useDispatch();

    return (
        <>
            {
                cart.length === 0 ?
                    <LayoutNotFound title={"Products"} /> :
                    // <p className='text-base md:text-lg text-dark dark:text-light font-medium'>The shopping cart is currently empty, Please add some orders to start your shopping experience.</p> :
                    <table className="w-full text-left text-dark dark:text-light">
                        <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-semibold">
                            <tr>
                                <th scope="col" className="px-4 pb-2">
                                    Information
                                </th>
                                <th scope="col" className="px-4 pb-2">
                                    Name
                                </th>
                                <th scope="col" className="px-4 pb-2">
                                    Size
                                </th>
                                <th scope="col" className="px-4 pb-2">
                                    Quantity
                                </th>
                                <th scope="col" className="px-4 pb-2">
                                    Color
                                </th>
                                <th scope="col" className="px-4 pb-2">
                                    Total Price
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-sm md:text-base'>
                            {
                                cart.map((product, idx) => {
                                    return (
                                        <tr className="border-b border-queen/25 dark:border-king/25">
                                            <td className="px-4 py-2 flex gap-2 items-center">
                                                <div className='flex gap-2 items-center'>
                                                    <button onClick={() => dispatch(deleteProduct({ productIdSizeColor: product.id + product.size + product.color }))}>
                                                        <FaTimesCircle className='text-xl text-l-extra dark:text-d-extra hover:opacity-80 transition-all duration-150' />
                                                    </button>
                                                    {product.category.type.brand.name} - {product.category.type.name} - {product.category.name}
                                                </div>
                                            </td>
                                            <td className="px-4 py-2">
                                                <Link to={`/products/${product.id}`} className='cursor-pointer duration-150 pb-0.5 border-b border-transparent hover:border-queen/25 dark:hover:border-king/25'>
                                                    {product.name}
                                                </Link>
                                            </td>
                                            <td className="px-4 py-2">
                                                <select onChange={(e) => dispatch(changeSize({ productIdSizeColor: product.id + product.size + product.color, size: e.target.value }))} defaultValue={product.size} className="bg-transparent focus:outline-none">
                                                    {
                                                        product.sizes.map((size) => {
                                                            return (
                                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={size}>{size}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td className="px-4 py-2">
                                                <select onChange={(e) => dispatch(changeColor({ productIdSizeColor: product.id + product.size + product.color, color: e.target.value }))} defaultValue={product.color} className="bg-transparent focus:outline-none">
                                                    {
                                                        product.colors.map((color) => {
                                                            return (
                                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm font-bold' style={{ color: color }} value={color}>
                                                                    {color}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>
                                            <td className="px-4 py-2">
                                                <div className='flex gap-2 items-center'>
                                                    <button onClick={() => dispatch(decreaseQuantity({ productIdSizeColor: product.id + product.size + product.color }))}>
                                                        <FaMinusCircle className='text-xl text-l-extra dark:text-d-extra hover:opacity-80 transition-all duration-150' />
                                                    </button>
                                                    {product.quantity}
                                                    <button onClick={() => dispatch(increaseQuantity({ productIdSizeColor: product.id + product.size + product.color }))}>
                                                        <FaPlusCircle className='text-xl text-l-extra dark:text-d-extra hover:opacity-80 transition-all duration-150' />
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="px-4 py-2">${product.price.newPrice * product.quantity}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
            }
        </>
    )
}

export default CartItems