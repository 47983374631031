import React from 'react'
import { GiButterfly } from "react-icons/gi"

const SectionHead = ({ title }) => {
    return (
        <h1 className='flex flex-col gap-0 justify-center items-center text-2xl md:text-4xl font-semibold md:font-bold text-l-extra dark:text-d-extra uppercase' style={{ "letterSpacing": "2px" }}>
            <div className='relative'>
                {title}
                <span className='absolute -top-3 -left-3 md:-top-6 md:-left-6'>
                    <GiButterfly className='text-queen dark:text-king animate-bounce' />
                </span>
            </div>
            <div className='-mt-2 ml-2 opacity-[0.15]' style={{ "transform": "rotateX(150deg) skewX(-25deg)" }}>
                {title}
            </div>
        </h1>
    )
}

export default SectionHead