import axios from 'axios'

const viewJobs = async () => {
  const response = await axios.get('/api/view-jobs')
  return response
}

const addJob = async (data) => {
  const response = await axios.post('/api/add-job', data)
  return response
}

const viewJob = async ({ id }) => {
  const response = await axios.get('/api/view-job/' + id)
  return response
}

const updateJob = async ({ id, data }) => {
  const response = await axios.put('/api/update-job/' + id, data)
  return response
}

const deleteJob = async ({ id }) => {
  const response = await axios.delete('/api/delete-job/' + id)
  return response
}

const searchJobs = async ({ key }) => {
  const response = await axios.get('/api/search-jobs?key=' + key)
  return response
}

const addReview = async ({ id,data }) => {
  const response = await axios.post('/api/add-review/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const jobsService = {
  viewJobs,
  addJob,
  viewJob,
  updateJob,
  deleteJob,
  searchJobs,
  addReview
}

export default jobsService