import React from 'react'
import SectionContainer from "../SectionContainer"
import SectionHead from "../SectionHead"
import JobCard from "../Jobs/JobCard"
import SectionLink from "../SectionLink"
import SectionText from '../SectionText'
import SectionCard from '../SectionCard'

const NewestJobs = ({ newestJobs }) => {
    return (
        <SectionContainer>
            <SectionHead title={"Newest Jobs"} />
            <SectionText text={"Join our team and embark on an exciting career journey. Explore our latest job openings and unlock opportunities for growth in a dynamic environment."} />
            <div className='my-4 md:my-6 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                {
                    newestJobs.length === 0 ?
                        Array(5).fill(0).map((item) => {
                            return (
                                <SectionCard />
                            )
                        }) :
                        newestJobs.slice(0, 5).map((job) => {
                            return (
                                <JobCard job={job} />
                            )
                        })
                }
            </div>
            <SectionLink title={"View More Jobs"} to={"/jobs"} />
        </SectionContainer>
    )
}

export default NewestJobs