import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBanner from '../../Components/LayoutBanner';
import { toastify } from '../../Helper';
import Container from '../../Components/Container';
import FavoritesSearch from '../../Components/Favorites/FavoritesSearch';
import FavoritesFilter from '../../Components/Favorites/FavoritesFilter';
import FavoritesCards from '../../Components/Favorites/FavoritesCards';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';
import { viewFavorites } from '../../Redux/Features/Favorites/favoritesSlice';

const Favorites = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const favoritesState = useSelector((state) => state.favorites);
    const [favorites, setFavorites] = useState(favoritesState.favorites);

    useEffect(() => {
        const data = {
            params: location.search
        }

        dispatch(viewFavorites(data))
    }, [location.search])

    useEffect(() => {
        setFavorites(favoritesState.favorites)
    }, [favoritesState.favorites])

    useEffect(() => {
        if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 200 && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites")) {
            // toastify("success", favoritesState.status, favoritesState.message)
        }
        else if ((Date.now() - favoritesState.time < 100) && (favoritesState.status === 400 || favoritesState.status === 401 || favoritesState.status === 403 /*|| favoritesState.status === 404*/) && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites")) {
            toastify("error", favoritesState.status, favoritesState.message)
        }
        else if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 500 && favoritesState.message && (favoritesState.operation === "viewFavorites" || favoritesState.operation === "searchFavorites")) {
            toastify("warn", favoritesState.status, favoritesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [favoritesState.status, favoritesState.message, favoritesState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Favorites", to: "/favorites" }]} />
            {
                favoritesState.isLoading && favoritesState.operation === "viewFavorites" ?
                    <LayoutLoading title={"favorites"} /> :
                    favoritesState.favorites.length === 0 && favoritesState.operation === "viewFavorites" ?
                        <LayoutNotFound title={"Favorites"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Favorites", to: "/favorites" }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4 flex flex-col gap-4">
                                        <FavoritesSearch />
                                        <FavoritesFilter setFavorites={setFavorites} />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <FavoritesCards favorites={favorites} />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Favorites