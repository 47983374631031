import axios from 'axios'

const viewRequests = async () => {
  const response = await axios.get("/api/view-requests")
  return response
}

// const addRequest = async (data) => { // I wanna update service details so i used in servicesService.js
//   const response = await axios.post('/api/add-request', data)
//   return response
// }

const updateRequest = async ({ service_id, user_id, data }) => {
  const response = await axios.put(`/api/update-request/${service_id}/${user_id}`, data)
  return response
}


const searchRequests = async ({ key }) => {
  const response = await axios.get('/api/search-requests?key=' + key)
  return response
}

const requestsService = {
  viewRequests,
  // addRequest,
  updateRequest,
  searchRequests,
}

export default requestsService