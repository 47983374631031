import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import requestsService from './requestsService' 

const initialState = {
  isLoading: null,
  isSuccess: null,
  requests: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewRequests = createAsyncThunk(
  'requests/viewRequests',
  async (_, thunkAPI) => {
    try {
      const response = await requestsService.viewRequests()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

// export const addRequest = createAsyncThunk(
//   'requests/addRequest',
//   async (data, thunkAPI) => {
//     try {
//       const response = await requestsService.addRequest(data)
//       return thunkAPI.fulfillWithValue([response.data, response.status])
//     } catch (error) {
//       return thunkAPI.rejectWithValue([error.response.data, error.response.status])
//     }
//   }
// )

export const updateRequest = createAsyncThunk(
  'requests/updateRequest',
  async (data, thunkAPI) => {
    try {
      const response = await requestsService.updateRequest(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchRequests = createAsyncThunk(
  'requests/searchRequests',
  async (data, thunkAPI) => {
    try {
      const response = await requestsService.searchRequests(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.requests = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewRequests.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.requests = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewRequests"
        state.time = Date.now()
      })
      .addCase(viewRequests.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.requests = (action.payload)[0].requests
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewRequests.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      // .addCase(addRequest.pending, (state) => {
      //   state.isLoading = true
      //   state.isSuccess = null
      //   state.message = null
      //   state.errors = null
      //   state.status = null
      //   state.operation = "addRequest"
      //   state.time = Date.now()
      // })
      // .addCase(addRequest.fulfilled, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = true
      //   state.requests = [...state.requests, (action.payload)[0].request]
      //   state.message = (action.payload)[0].message
      //   state.status = (action.payload)[1]
      //   state.time = Date.now()
      // })
      // .addCase(addRequest.rejected, (state, action) => {
      //   state.isLoading = false
      //   state.isSuccess = false
      //   state.message = (action.payload)[0].message
      //   state.errors = (action.payload)[0].errors
      //   state.status = (action.payload)[1]
      //   state.time = Date.now()
      // })

      .addCase(updateRequest.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateRequest"
        state.time = Date.now()
      })
      .addCase(updateRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        // if (state.requests.length === 0) {
        //   state.requests = [(action.payload)[0].request]
        // }
        // else {
        //   state.requests = state.requests.map(request => {
        //     if (request.id === (action.payload)[0].request.id) {
        //       return (action.payload)[0].request
        //     }
        //     else {
        //       return request
        //     }
        //   })
        // }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateRequest.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      
      .addCase(searchRequests.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.requests = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchRequests"
        state.time = Date.now()
      })
      .addCase(searchRequests.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.requests = (action.payload)[0].requests
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchRequests.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = requestsSlice.actions
export default requestsSlice.reducer