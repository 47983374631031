import React from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const ServiceCard = ({ service }) => {
  return (
    <div className=" text-dark dark:text-light rounded-md border border-queen/25 dark:border-king/25 relative overflow-hidden group shadow-inner shadow-queen/20 dark:shadow-king/20">
      <div className='flex justify-center items-center min-h-[250px]'>
        <img className='w-3/4 mx-auto' src={process.env.REACT_APP_API_BASE_URL + "/" + service.image} alt={service.name} />
      </div>
      <div className='bg-queen/[0.05] dark:bg-king/[0.05] flex gap-1 flex-col px-2 py-4 border-t border-queen/25 dark:border-king/25 h-full'>
        <div className='flex gap-1 justify-between items-center'>
          <span className='text-lg font-semibold truncate'>{service.title}</span>
          <Link to={`/services/${service.id}`}>
            <FaArrowRight className='text-base font-normal opacity-75 hover:opacity-100 transition-all duration-150 text-l-extra dark:text-d-extra' />
          </Link>
        </div>
        <div className='flex gap-1 justify-between items-center'>
          <span className='text-base font-normal truncate'>{service.description}</span>
        </div>
      </div>
    </div>
  )
}

export default ServiceCard