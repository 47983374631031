import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import usersService from './usersService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  users: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewUsers = createAsyncThunk(
  'users/viewUsers',
  async (_, thunkAPI) => {
    try {
      const response = await usersService.viewUsers()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addUser = createAsyncThunk(
  'users/addUser',
  async (data, thunkAPI) => {
    try {
      const response = await usersService.addUser(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewUser = createAsyncThunk(
  'users/viewUser',
  async (data, thunkAPI) => {
    try {
      const response = await usersService.viewUser(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (data, thunkAPI) => {
    try {
      const response = await usersService.updateUser(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (data, thunkAPI) => {
    try {
      const response = await usersService.deleteUser(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchUsers = createAsyncThunk(
  'users/searchUsers',
  async (data, thunkAPI) => {
    try {
      const response = await usersService.searchUsers(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.users = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewUsers.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.users = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewUsers"
        state.time = Date.now()
      })
      .addCase(viewUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = (action.payload)[0].users
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addUser.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addUser"
        state.time = Date.now()
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = [...state.users, (action.payload)[0].user]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewUser.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewUser"
        state.time = Date.now()
      })
      .addCase(viewUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.users.length === 0) {
          state.users = [(action.payload)[0].user]
        }
        else {
          state.users = state.users.map(user => {
            if (user.id === (action.payload)[0].user.id) {
              return (action.payload)[0].user
            }
            else {
              return user
            }
          })
        }
        
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewUser.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateUser.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateUser"
        state.time = Date.now()
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.users.length === 0) {
          state.users = [(action.payload)[0].user]
        }
        else {
          state.users = state.users.map(user => {
            if (user.id === (action.payload)[0].user.id) {
              return (action.payload)[0].user
            }
            else {
              return user
            }
          })
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteUser.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteUser"
        state.time = Date.now()
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = state.users.filter(user => {
          return user.id !== (action.payload)[0].user.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchUsers.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.users = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchUsers"
        state.time = Date.now()
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.users = (action.payload)[0].users
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = usersSlice.actions
export default usersSlice.reducer