import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import jobsService from './jobsService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  jobs: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewJobs = createAsyncThunk(
  'jobs/viewJobs',
  async (_, thunkAPI) => {
    try {
      const response = await jobsService.viewJobs()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addJob = createAsyncThunk(
  'jobs/addJob',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.addJob(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewJob = createAsyncThunk(
  'jobs/viewJob',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.viewJob(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateJob = createAsyncThunk(
  'jobs/updateJob',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.updateJob(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteJob = createAsyncThunk(
  'jobs/deleteJob',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.deleteJob(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchJobs = createAsyncThunk(
  'jobs/searchJobs',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.searchJobs(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addReview = createAsyncThunk(
  'jobs/addReview',
  async (data, thunkAPI) => {
    try {
      const response = await jobsService.addReview(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.jobs = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewJobs.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.jobs = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewJobs"
        state.time = Date.now()
      })
      .addCase(viewJobs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.jobs = (action.payload)[0].jobs
        state.translatedJob = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewJobs.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addJob.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addJob"
        state.time = Date.now()
      })
      .addCase(addJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.jobs = [...state.jobs, (action.payload)[0].job]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addJob.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewJob.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewJob"
        state.time = Date.now()
      })
      .addCase(viewJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.jobs = state.jobs.map(job => {
          if (job.id === (action.payload)[0].job.id) {
            return (action.payload)[0].job
          }
          else {
            return job
          }
        })

        const foundJob = state.jobs.find(job => job.id === action.payload[0].job.id);
        if (!foundJob) {
          state.jobs.push(action.payload[0].job);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewJob.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateJob.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateJob"
        state.time = Date.now()
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.jobs = state.jobs.map(job => {
          if (job.id === (action.payload)[0].job.id) {
            return (action.payload)[0].job
          }
          else {
            return job
          }
        })

        const foundJob = state.jobs.find(job => job.id === action.payload[0].job.id);
        if (!foundJob) {
          state.jobs.push(action.payload[0].job);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteJob.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteJob"
        state.time = Date.now()
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.jobs = state.jobs.filter(job => {
          return job.id !== (action.payload)[0].job.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteJob.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchJobs.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.jobs = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchJobs"
        state.time = Date.now()
      })
      .addCase(searchJobs.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.jobs = (action.payload)[0].jobs
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchJobs.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addReview.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addReview"
        state.time = Date.now()
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.jobs = state.jobs.map(job => {
          if (job.id === (action.payload)[0].job.id) {
            return (action.payload)[0].job
          }
          else {
            return job
          }
        })

        const foundJob = state.jobs.find(job => job.id === action.payload[0].job.id);
        if (!foundJob) {
          state.jobs.push(action.payload[0].job);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = jobsSlice.actions
export default jobsSlice.reducer