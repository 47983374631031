import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewUsers, searchUsers, deleteUser, updateUser, addUser } from "../../../Redux/Features/Users/usersSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Users = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usersState = useSelector((state) => state.users);
    const searchInput = useRef();
    const nameInput = useRef();
    const phoneInput = useRef();
    const emailInput = useRef();
    const addressInput = useRef();
    const roleInput = useRef();
    const isTrustInput = useRef();
    const passwordInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchUsersAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchUsers(data))
    }

    const addUserAlt = () => {
        let data = {
            name: nameInput.current.value,
            phone: phoneInput.current.value,
            email: emailInput.current.value,
            address: addressInput.current.value,
            role: roleInput.current.value,
            is_trust: isTrustInput.current.value,
            password: passwordInput.current.value,
        }

        dispatch(addUser(data))
    }

    const updateUserAlt = (user_id) => {
        let data = {
            name: nameInput.current.value,
            phone: phoneInput.current.value,
            email: emailInput.current.value,
            address: addressInput.current.value,
            role: roleInput.current.value,
            is_trust: isTrustInput.current.value,
            password: passwordInput.current.value,
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateUser({ data, id: user_id }))
        }
    }

    const deleteUserAlt = (user_id) => {
        const data = {
            id: user_id
        }

        dispatch(deleteUser(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewUsers())
    }, [])

    useEffect(() => {
        if ((Date.now() - usersState.time < 100) && (usersState.status === 200 || usersState.status === 201) && usersState.message && (usersState.operation === "viewUsers" || usersState.operation === "searchUsers" || usersState.operation === "addUser" || usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            // toastify("success", usersState.status, usersState.message)
        }
        else if ((Date.now() - usersState.time < 100) && (usersState.status === 400 || usersState.status === 401 || usersState.status === 403 /*|| usersState.status === 404*/) && usersState.message && (usersState.operation === "viewUsers" || usersState.operation === "searchUsers" || usersState.operation === "addUser" || usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            toastify("error", usersState.status, usersState.message)
        }
        else if ((Date.now() - usersState.time < 100) && usersState.status === 500 && usersState.message && (usersState.operation === "viewUsers" || usersState.operation === "searchUsers" || usersState.operation === "addUser" || usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
            toastify("warn", usersState.status, usersState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [usersState.status, usersState.message, usersState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Users
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by name, phone, email or address"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchUsersAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    usersState.isLoading && usersState.operation !== "addUser" && usersState.operation !== "updateUser" && usersState.operation !== "deleteUser" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Phone
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Email
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Address
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Role
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Is Trust
                                    </th>
                                    <th scope="col" className="px-4 py-2 opacity-50">
                                        Password
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="9"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Users
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        usersState.users.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={phoneInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Phone' type='text' /> :
                                                    "Phone"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <input ref={emailInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Email' type='email' /> :
                                                    "Email"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <input ref={addressInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Address' type='text' /> :
                                                    "Address"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={roleInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Role</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="admin">Admin</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="user">User</option>
                                                    </select> :
                                                    "Role"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={isTrustInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Trust</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                                    </select> :
                                                    "Is Trust"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <input ref={passwordInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Password' type='password' /> :
                                                    <span className=' opacity-50'>Password</span>
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addUserAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && usersState.isLoading && usersState.operation === "addUser" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && usersState.errors && usersState.operation === "addUser" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.name && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.phone && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.phone : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.email && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.email : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.address && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.address : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.role && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.role : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.is_trust && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.is_trust : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 opacity-50">
                                                    {
                                                        usersState.errors && usersState.errors.password && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.password : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="9"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Users Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={phoneInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Phone' type='text' /> :
                                                    "Phone"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <input ref={emailInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Email' type='email' /> :
                                                    "Email"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={addressInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Address' type='text' /> :
                                                    "Address"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={roleInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Role</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="admin">Admin</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="user">User</option>
                                                    </select> :
                                                    "Role"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <select ref={isTrustInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Trust</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                                    </select> :
                                                    "Is Trust"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            {
                                                openAdd ?
                                                    <input ref={passwordInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Password...' type='password' /> :
                                                    <span className=' opacity-50'>Password</span>
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addUserAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && usersState.isLoading && usersState.operation === "addUser" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && usersState.errors && usersState.operation === "addUser" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.name && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.phone && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.phone : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.email && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.email : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.address && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.address : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.role && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.role : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        usersState.errors && usersState.errors.is_trust && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.is_trust : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 opacity-50">
                                                    {
                                                        usersState.errors && usersState.errors.password && usersState.operation === "addUser" ?
                                                            usersState.errors && usersState.errors.password : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        usersState.users.map((user, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === user.id && usersState.errors && usersState.operation === "updateUser" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <input ref={nameInput} defaultValue={user.name} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                                    user.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <input ref={phoneInput} defaultValue={user.phone} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Phone' type='text' /> :
                                                                    user.phone
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <input ref={emailInput} defaultValue={user.email} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Email' type='email' /> :
                                                                    user.email
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <input ref={addressInput} defaultValue={user.address} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Address' type='text' /> :
                                                                    user.address
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 capitalize">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <select ref={roleInput} defaultValue={user.role} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Role</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="admin">Admin</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="user">User</option>
                                                                    </select> :
                                                                    user.role
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 capitalize">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <select ref={isTrustInput} defaultValue={user.is_trust} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Trust</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                                                    </select> :
                                                                    user.is_trust == "0" ? "No" : "Yes"
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === user.id ?
                                                                    <input ref={passwordInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Password' type='password' /> :
                                                                    < span className=' opacity-50'>Hidden</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === user.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(user.id); updateUserAlt(user.id); setShowUpdateErrors(user.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(user.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === user.id && usersState.isLoading && usersState.operation === "updateUser" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(user.id); deleteUserAlt(user.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === user.id && usersState.isLoading && usersState.operation === "deleteUser" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === user.id && usersState.errors && usersState.operation === "updateUser" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.name && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.name : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.phone && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.phone : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.email && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.email : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.address && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.address : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.role && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.role : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        usersState.errors && usersState.errors.is_trust && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.is_trust : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 opacity-50">
                                                                    {
                                                                        usersState.errors && usersState.errors.password && usersState.operation === "updateUser" ?
                                                                            usersState.errors && usersState.errors.password : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Users