import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import favoritesService from './favoritesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  favorites: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null,
  target: null
}

export const viewFavorites = createAsyncThunk(
  'favorites/viewFavorites',
  async (_, thunkAPI) => {
    try {
      const response = await favoritesService.viewFavorites()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addFavorite = createAsyncThunk(
  'favorites/addFavorite',
  async (data, thunkAPI) => {
    try {
      const response = await favoritesService.addFavorite(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchFavorites = createAsyncThunk(
  'favorites/searchFavorites',
  async (data, thunkAPI) => {
    try {
      const response = await favoritesService.searchFavorites(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.favorites = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
      state.target = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewFavorites.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.favorites = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewFavorites"
        state.time = Date.now()
        state.target = null
      })
      .addCase(viewFavorites.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.favorites = (action.payload)[0].favorites
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewFavorites.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addFavorite.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addFavorite"
        state.time = Date.now()
        state.target = null
      })
      .addCase(addFavorite.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        // if exists remove it, otherwise add it, remember that favorites is a relation table
        // state.favorites = [...state.favorites, (action.payload)[0].favorite] // i don't need this

        const foundFavorite = state.favorites.find(favorite => favorite.id == action.payload[0].favorite.id);

        if (!foundFavorite || state.favorites.length === 0) {
          state.favorites.push(action.payload[0].favorite);
        } else {
          state.favorites = state.favorites.filter(favorite => favorite.id != action.payload[0].favorite.id)
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
        state.target = action.payload[0].favorite.id
      })
      .addCase(addFavorite.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchFavorites.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.favorites = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchFavorites"
        state.time = Date.now()
        state.target = null
      })
      .addCase(searchFavorites.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.favorites = (action.payload)[0].favorites
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchFavorites.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = favoritesSlice.actions
export default favoritesSlice.reducer