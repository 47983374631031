import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewProducts, searchProducts, deleteProduct} from "../../../Redux/Features/Products/productsSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaRegStar, FaSpinner, FaStar, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Products = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productsState = useSelector((state) => state.products);
    const searchInput = useRef();
    const [loadDelete, setLoadDelete] = useState(null)

    const searchProductsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchProducts(data))
    }

    const deleteProductAlt = (product_id) => {
        const data = {
            id: product_id
        }

        dispatch(deleteProduct(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewProducts())
    }, [])

    useEffect(() => {
        if ((Date.now() - productsState.time < 100) && (productsState.status === 200 || productsState.status === 201) && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "deleteProduct")) {
            // toastify("success", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && (productsState.status === 400 || productsState.status === 401 || productsState.status === 403 /*|| productsState.status === 404*/) && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "deleteProduct")) {
            toastify("error", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && productsState.status === 500 && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "deleteProduct")) {
            toastify("warn", productsState.status, productsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [productsState.status, productsState.message, productsState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Products
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by brand, type, category or name"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchProductsAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    productsState.isLoading && productsState.operation !== "addProduct" && productsState.operation !== "updateProduct" && productsState.operation !== "deleteProduct" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Brand
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Type
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Category
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Colors
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Sizes
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Gender
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Price
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Rating
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Images
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <Link
                                            to={`./add`}
                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                        >
                                            <FaPlus className='text-base md:text-lg' />
                                        </Link>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="12"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Products
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        productsState.products.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Type
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Category
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Name
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Colors
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Sizes
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Gender
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Rating
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Images
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            <Link
                                                to={`./add`}
                                                className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                            >
                                                <FaPlus className='text-base md:text-lg' />
                                            </Link>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="12"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Products Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Brand
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Type
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Category
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Name
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Colors
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Sizes
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Gender
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Rating
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Images
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            <Link
                                                to={`./add`}
                                                className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                            >
                                                <FaPlus className='text-base md:text-lg' />
                                            </Link>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        productsState.products.map((product, idx) => {
                                            return (
                                                <tr className="border-b border-queen/50 dark:border-king/50">
                                                    <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                    <td className="px-4 py-2">{product.category.type.brand.name}</td>
                                                    <td className="px-4 py-2">{product.category.type.name}</td>
                                                    <td className="px-4 py-2">{product.category.name}</td>
                                                    <td className="px-4 py-2">{product.name}</td>
                                                    <td className="px-4 py-2">
                                                        {
                                                            product.colors.map((color) => {
                                                                return (
                                                                    <div className='flex gap-1 items-center'>
                                                                        <div className="w-3 h-3" style={{ backgroundColor: color }}></div>
                                                                        {color}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td className="px-4 py-2">
                                                        {
                                                            product.sizes.map((size) => {
                                                                return (
                                                                    <div className='flex gap-1 items-center'>
                                                                        - {size}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td className="px-4 py-2 capitalize">{product.gender}</td>
                                                    <td className="px-4 py-2">${product.price.newPrice}</td>
                                                    <td className="px-4 py-2">
                                                        <div className='flex gap-1 items-center text-queen dark:text-king mb-1'>
                                                            {
                                                                product.rating && Array(Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                                                                    return <FaStar />;
                                                                })
                                                            }
                                                            {
                                                                product.rating ?
                                                                    Array(5 - Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                                                                        return <FaRegStar />;
                                                                    }) :
                                                                    Array(5).fill(0).map((star) => {
                                                                        return <FaRegStar />;
                                                                    })
                                                            }
                                                        </div>
                                                        {
                                                            product.rating ?
                                                                <span className="mr-2 text-dark dark:text-light">{product.rating?.length} Reviews</span> :
                                                                <span className="mr-2 text-dark dark:text-light">0 Reviews</span>
                                                        }
                                                    </td>
                                                    <td className="px-4 py-2">
                                                        {
                                                            product.images.map((image, idx) => {
                                                                return (
                                                                    <div className='flex gap-1 items-center'>
                                                                        <a target='_blank' href={process.env.REACT_APP_API_BASE_URL + "/" + image} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View {idx + 1}</a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                    <td className="px-4 py-2 text-right">
                                                        <div
                                                            className="flex w-full"
                                                            role="group"
                                                        >
                                                            <Link
                                                                to={`./${product.id}/update`}
                                                                className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                            >
                                                                <FaEdit className='text-base md:text-lg' />
                                                            </Link>
                                                            <button
                                                                type="button"
                                                                className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                onClick={() => { setLoadDelete(product.id); deleteProductAlt(product.id) }}
                                                            >
                                                                {
                                                                    loadDelete === product.id && productsState.isLoading && productsState.operation === "deleteProduct" ?
                                                                        <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                        <FaTrash className='text-base md:text-lg' />
                                                                }
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Products