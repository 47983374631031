import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import servicesService from './servicesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  services: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewServices = createAsyncThunk(
  'services/viewServices',
  async (_, thunkAPI) => {
    try {
      const response = await servicesService.viewServices()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addService = createAsyncThunk(
  'services/addService',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.addService(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewService = createAsyncThunk(
  'services/viewService',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.viewService(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateService = createAsyncThunk(
  'services/updateService',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.updateService(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.deleteService(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchServices = createAsyncThunk(
  'services/searchServices',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.searchServices(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addRequest = createAsyncThunk(
  'services/addRequest',
  async (data, thunkAPI) => {
    try {
      const response = await servicesService.addRequest(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.services = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewServices.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.services = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewServices"
        state.time = Date.now()
      })
      .addCase(viewServices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = (action.payload)[0].services
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewServices.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addService.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addService"
        state.time = Date.now()
      })
      .addCase(addService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = [...state.services, (action.payload)[0].service]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addService.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewService.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewService"
        state.time = Date.now()
      })
      .addCase(viewService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.services = state.services.map(service => {
          if (service.id === (action.payload)[0].service.id) {
            return (action.payload)[0].service
          }
          else {
            return service
          }
        })

        const foundService = state.services.find(service => service.id === action.payload[0].service.id);
        if (!foundService) {
          state.services.push(action.payload[0].service);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewService.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateService.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateService"
        state.time = Date.now()
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.services = state.services.map(service => {
          if (service.id === (action.payload)[0].service.id) {
            return (action.payload)[0].service
          }
          else {
            return service
          }
        })

        const foundService = state.services.find(service => service.id === action.payload[0].service.id);
        if (!foundService) {
          state.services.push(action.payload[0].service);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateService.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteService.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteService"
        state.time = Date.now()
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = state.services.filter(service => {
          return service.id !== (action.payload)[0].service.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchServices.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.services = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchServices"
        state.time = Date.now()
      })
      .addCase(searchServices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.services = (action.payload)[0].services
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchServices.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addRequest.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addRequest"
        state.time = Date.now()
      })
      .addCase(addRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.services = state.services.map(service => {
          if (service.id === (action.payload)[0].service.id) {
            return (action.payload)[0].service
          }
          else {
            return service
          }
        })

        const foundService = state.services.find(service => service.id === action.payload[0].service.id);
        if (!foundService) {
          state.services.push(action.payload[0].service);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addRequest.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = servicesSlice.actions
export default servicesSlice.reducer