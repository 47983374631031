import axios from 'axios'

const viewProducts = async ({ params }) => {
  const response = await axios.get('/api/view-products' + params)
  return response
}

const addProduct = async (data) => {
  const response = await axios.post('/api/add-product', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewProduct = async ({ id }) => {
  const response = await axios.get('/api/view-product/' + id)
  return response
}

const updateProduct = async ({ id, data }) => {
  const response = await axios.post('/api/update-product/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteProduct = async ({ id }) => {
  const response = await axios.delete('/api/delete-product/' + id)
  return response
}

const searchProducts = async ({ key, params }) => {
  const response = await axios.get('/api/search-products?key=' + key + params)
  return response
}

const addRate = async ({ id, data }) => {
  const response = await axios.put('/api/add-rate/' + id, data)
  return response
}

const productsService = {
  viewProducts,
  addProduct,
  viewProduct,
  updateProduct,
  deleteProduct,
  searchProducts,
  addRate
}

export default productsService