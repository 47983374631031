import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addRequest } from '../../Redux/Features/Services/servicesSlice';
import { FaSearchDollar, FaSearchMinus, FaSpinner } from 'react-icons/fa'
import { toastify } from '../../Helper';

const ServiceDetails = ({ service }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const servicesState = useSelector((state) => state.services);
  const [openNote, setOpenNote] = useState(false)

  const addRequestAlt = () => {
    if (!authState.auth) {
      setOpenNote(true)
    } else {
      dispatch(addRequest({ id: service.id }))
    }
  }

  useEffect(() => {
    if ((Date.now() - servicesState.time < 100) && servicesState.status === 200 && servicesState.message && servicesState.operation === "addRequest") {
      // toastify("success", servicesState.status, servicesState.message)
    }
    else if ((Date.now() - servicesState.time < 100) && (servicesState.status === 400 || servicesState.status === 401 || servicesState.status === 403 || servicesState.status === 404) && servicesState.message && (servicesState.operation === "addRequest")) {
      toastify("error", servicesState.status, servicesState.message)
    }
    else if ((Date.now() - servicesState.time < 100) && servicesState.status === 500 && servicesState.message && (servicesState.operation === "addRequest")) {
      toastify("warn", servicesState.status, servicesState.message)
      setTimeout(() => {
        // navigate(0)
      }, 5000);
    }
  }, [servicesState.status, servicesState.message, servicesState.errors])

  return (
    <div>
      <div className='flex flex-col gap-1 text-dark dark:text-light'>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Title:</span>
          <span className='text-base md:text-lg font-medium'>{service.title}</span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>
            Description:
            <span className='ml-1 text-base md:text-lg font-normal'>{service.description}</span>
          </span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>
            Status:
            <span className='ml-1 text-base md:text-lg font-normal text-l-extra dark:text-d-extra'>
              ({
                service.users?.filter((user) => {
                  return user.id == authState?.auth?.user?.id
                })[0]?.id && service.users?.filter((user) => {
                  return user.id == authState?.auth?.user?.id
                })[0]?.pivot?.is_reviewed == 0 ?
                  <span className=''>We will provide you with an answer as soon as possible.</span> :
                  service.users?.filter((user) => {
                    return user.id == authState?.auth?.user?.id
                  })[0]?.id && service.users?.filter((user) => {
                    return user.id == authState?.auth?.user?.id
                  })[0]?.pivot?.is_reviewed == 1 ?
                    <span className=''>We have addressed your inquiry. Kindly check your email for our response.</span> :
                    <span className=''>You have not made a request for this service yet.</span>
              })
            </span>
          </span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Requested by:</span>
          <span className='text-base md:text-lg font-normal'>{service.users?.length} user </span>
        </div>
        <div className='grid md:grid-cols-3 gap-2 mt-4'>
          <button onClick={() => addRequestAlt()} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
            {
              service.users?.filter((user) => {
                return user.id == authState?.auth?.user?.id
              })[0]?.id ?
                <>
                  {servicesState.isLoading && servicesState.operation === "addRequest" ? <FaSpinner className='text-lg animate-spin' /> : <FaSearchMinus className='text-lg' />}
                  Decline Now
                </> :
                <>
                  {servicesState.isLoading && servicesState.operation === "addRequest" ? <FaSpinner className='text-lg animate-spin' /> : <FaSearchDollar className='text-lg' />}
                  Request Now
                </>
            }
          </button>
        </div>
      </div>
      {
        openNote ?
          <div className='mt-4 text-dark dark:text-light text-base md:text-lg font-normal'>
            <div className=''>
              You must login first, from <Link to={"/login"} className='text-l-extra dark:text-d-extra border-b border-l-extra/50 dark:border-d-extra/50 hover:opacity-80 duration-150'>here</Link>
            </div>
          </div> :
          null
      }
    </div>
  )
}

export default ServiceDetails 