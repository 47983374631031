import axios from 'axios'

const viewSlides = async () => {
  const response = await axios.get('/api/view-slides')
  return response
}

const addSlide = async (data) => {
  const response = await axios.post('/api/add-slide', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewSlide = async ({ id }) => {
  const response = await axios.post('/api/view-slide/' + id)
  return response
}

const updateSlide = async ({ id, data }) => {
  const response = await axios.post('/api/update-slide/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteSlide = async ({ id }) => {
  const response = await axios.delete('/api/delete-slide/' + id)
  return response
}

const searchSlides = async ({ key }) => {
  const response = await axios.get('/api/search-slides?key=' + key)
  return response
}

const slidesService = {
  viewSlides,
  addSlide,
  viewSlide,
  updateSlide,
  deleteSlide,
  searchSlides
}

export default slidesService