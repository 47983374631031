import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBanner from '../../Components/LayoutBanner';
import { toastify } from '../../Helper';
import Container from '../../Components/Container';
import ServicesSearch from '../../Components/Services/ServicesSearch';
import ServicesCards from '../../Components/Services/ServicesCards';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';
import { viewServices } from '../../Redux/Features/Services/servicesSlice';

const Services = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const servicesState = useSelector((state) => state.services);

    useEffect(() => {
        dispatch(viewServices())
    }, [])

    useEffect(() => {
        if ((Date.now() - servicesState.time < 100) && servicesState.status === 200 && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices")) {
            // toastify("success", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && (servicesState.status === 400 || servicesState.status === 401 || servicesState.status === 403 /*|| servicesState.status === 404*/) && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices")) {
            toastify("error", servicesState.status, servicesState.message)
        }
        else if ((Date.now() - servicesState.time < 100) && servicesState.status === 500 && servicesState.message && (servicesState.operation === "viewServices" || servicesState.operation === "searchServices")) {
            toastify("warn", servicesState.status, servicesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [servicesState.status, servicesState.message, servicesState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Services", to: "/services" }]} />
            {
                servicesState.isLoading && servicesState.operation === "viewServices" ?
                    <LayoutLoading title={"services"} /> :
                    servicesState.services.length === 0 && servicesState.operation === "viewServices" ?
                        <LayoutNotFound title={"Services"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Services", to: "/services" }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4">
                                        <ServicesSearch />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <ServicesCards />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Services