import React, { useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { searchJobs } from "../../Redux/Features/Jobs/jobsSlice";

const JobsSearch = () => {
    const dispatch = useDispatch();
    const searchInput = useRef();
    const jobsState = useSelector((state) => state.jobs);

    const searchJobsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchJobs(data))
    }

    return (
        <div className="relative w-full md:max-w-md lg:max-w-lg mx-auto flex flex-col gap-2">
            <input
                type="search"
                className="bg-queen/[0.05] dark:bg-king/[0.05] text-dark dark:text-light placeholder:text-dark/75 dark:placeholder:text-light/75 border-queen/25 focus:border-queen dark:border-king/25 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-md border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                placeholder="Search..."
                aria-label="Search"
                aria-describedby="button-addon1"
                ref={searchInput}
                onChange={() => searchJobsAlt()}
            />
            <p className='text-base md:text-lg font-normal text-dark dark:text-light max-w-full'>
                Among our distinguished and new range of job opportunities that we offer, you can search for the job that you desire and feel you will excel in, using the job
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>identifier</span>,
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>positition</span>,
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>description</span>,
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>location</span>,
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>time</span>,
                or
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>level</span>.
            </p>
            {
                searchInput?.current?.value && jobsState.operation === "searchJobs" ?
                    <div className='text-base md:text-lg font-normal text-dark dark:text-light'>
                        Your search using the keyword <span className='text-l-extra dark:text-d-extra mx-1 font-medium'>"{searchInput?.current?.value}"</span> has resulted in <span className='text-l-extra dark:text-d-extra mx-1 font-medium'>{jobsState.jobs.length}</span> matching search results.
                    </div> :
                    null
            }
        </div>
    )
}

export default JobsSearch