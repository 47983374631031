import axios from 'axios'

const viewReviews = async () => {
  const response = await axios.get("/api/view-reviews")
  return response
}

// const addReview = async (data) => { // I wanna update job details so i used in jobsService.js
//   const response = await axios.post('/api/add-review', data)
//   return response
// }

const updateReview = async ({ job_id, user_id, data }) => {
  const response = await axios.put(`/api/update-review/${job_id}/${user_id}`, data)
  return response
}


const searchReviews = async ({ key }) => {
  const response = await axios.get('/api/search-reviews?key=' + key)
  return response
}

const reviewsService = {
  viewReviews,
  // addReview,
  updateReview,
  searchReviews,
}

export default reviewsService