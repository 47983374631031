import { createSlice } from '@reduxjs/toolkit'

const cart = JSON.parse(localStorage.getItem('cart'))

if (!cart) {
  localStorage.setItem("cart", JSON.stringify([]))
}

const initialState = {
  cart: cart ? cart : [],
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct: (state, data) => {
      const newProduct = {
        ...data.payload.product,
        size: data.payload.product.sizes[0],
        color: data.payload.product.colors[0],
        quantity: 1
      };

      const existingProduct = state.cart.find((product) => product.id + product.size + product.color === newProduct.id + newProduct.size + newProduct.color);

      if (!existingProduct) {
        state.cart = [...state.cart, newProduct];
        localStorage.setItem("cart", JSON.stringify(state.cart));
      } else {
        console.log("Product already exists in the cart.");
      }
    },

    deleteProduct: (state, data) => {
      const productIdSizeColor = data.payload.productIdSizeColor;

      state.cart = state.cart.filter((product) => product.id + product.size + product.color !== productIdSizeColor);
      localStorage.setItem("cart", JSON.stringify(state.cart));
    },

    increaseQuantity: (state, data) => {
      const productIdSizeColor = data.payload.productIdSizeColor;
      const productIndex = state.cart.findIndex((product) => product.id + product.size + product.color === productIdSizeColor);

      if (productIndex !== -1) {
        state.cart[productIndex].quantity += 1;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      } else {
        console.log("Product not found in the cart.");
      }
    },

    decreaseQuantity: (state, data) => {
      const productIdSizeColor = data.payload.productIdSizeColor;
      const productIndex = state.cart.findIndex((product) => product.id + product.size + product.color === productIdSizeColor);

      if (productIndex !== -1) {
        if (state.cart[productIndex].quantity > 1) {
          state.cart[productIndex].quantity -= 1;
          localStorage.setItem("cart", JSON.stringify(state.cart));
        } else {
          console.log("Product quantity cannot be decreased further.");
        }
      } else {
        console.log("Product not found in the cart.");
      }
    },

    changeSize: (state, data) => {
      const productIdSizeColor = data.payload.productIdSizeColor;
      const productIndex = state.cart.findIndex((product) => product.id + product.size + product.color === productIdSizeColor);

      if (productIndex !== -1) {

        const size = data.payload.size;

        const productIndexAlt = state.cart.findIndex((product) => product.id + product.size + product.color === state.cart[productIndex].id + size + state.cart[productIndex].color);

        if (productIndexAlt === -1) {
          state.cart[productIndex].size = size;
          localStorage.setItem("cart", JSON.stringify(state.cart));
          console.log("Product already exists in the cart and size has been changed.");
        } else {
          state.cart = state.cart.filter((product) => product.id + product.size + product.color !== productIdSizeColor);
          localStorage.setItem("cart", JSON.stringify(state.cart));
          console.log("Product already exists in the cart and size has not been changed.");
        }
      } else {
        console.log("Product not found in the cart.");
      }
    },

    changeColor: (state, data) => {
      const productIdSizeColor = data.payload.productIdSizeColor;
      const productIndex = state.cart.findIndex((product) => product.id + product.size + product.color === productIdSizeColor);

      if (productIndex !== -1) {

        const color = data.payload.color;

        const productIndexAlt = state.cart.findIndex((product) => product.id + product.size + product.color === state.cart[productIndex].id + state.cart[productIndex].size + color);

        if (productIndexAlt === -1) {
          state.cart[productIndex].color = color;
          localStorage.setItem("cart", JSON.stringify(state.cart));
          console.log("Product already exists in the cart and color has been changed.");
        } else {
          state.cart = state.cart.filter((product) => product.id + product.size + product.color !== productIdSizeColor);
          localStorage.setItem("cart", JSON.stringify(state.cart));
          console.log("Product already exists in the cart and color has not been changed.");
        }
      } else {
        console.log("Product not found in the cart.");
      }
    },

    reset: (state) => {
      state.cart = [];
      localStorage.setItem("cart", JSON.stringify([]));
    },
  }
})

export const { addProduct, deleteProduct, increaseQuantity, decreaseQuantity, changeSize, changeColor, reset } = cartSlice.actions
export default cartSlice.reducer