import React from 'react'
import Container from '../Components/Container'
import { FaBoxOpen } from 'react-icons/fa'

const LayoutNotFound = ({ title }) => {
    return (
        <Container>
            <div className='flex justify-center items-center gap-1 md:gap-2 h-[250px]'>
                <div className='text-queen dark:text-king text-2xl md:text-4xl'>
                    <FaBoxOpen />
                </div>
                <h1 className='text-dark dark:text-light text-xl md:text-2xl'>
                    {title} not found
                </h1>
            </div>
        </Container>
    )
}

export default LayoutNotFound