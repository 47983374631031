import axios from 'axios' 

const viewFavorites = async () => {
  const response = await axios.get("/api/view-favorites")
  return response
}

const addFavorite = async ({id}) => {
  const response = await axios.post('/api/add-favorite/'+id,)
  return response
}

const searchFavorites = async ({ key }) => {
  const response = await axios.get('/api/search-favorites?key=' + key)
  return response
}

const favoritesService = {
  viewFavorites,
  addFavorite,
  searchFavorites,
}

export default favoritesService