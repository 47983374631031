import React from 'react'

const ServiceImage = ({ service }) => {
  return (
    <div className='border border-queen/50 dark:border-king/50 rounded-md mx-auto w-full h-[250px]'>
      <img src={process.env.REACT_APP_API_BASE_URL + "/" + service.image} alt="" className='h-full mx-auto' />
    </div>
  )
}

export default ServiceImage