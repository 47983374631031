import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewBrands } from "../../../Redux/Features/Brands/brandsSlice";
import { viewTypes, searchTypes, deleteType, updateType, addType } from "../../../Redux/Features/Types/typesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Types = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const brandsState = useSelector((state) => state.brands);
    const typesState = useSelector((state) => state.types);
    const searchInput = useRef();
    const nameInput = useRef();
    const brandInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchTypesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchTypes(data))
    }

    const addTypeAlt = () => {
        let data = {
            name: nameInput.current.value,
            brand_id: brandInput.current.value
        }

        dispatch(addType(data))
    }

    const updateTypeAlt = (type_id) => {
        let data = {
            name: nameInput.current.value,
            brand_id: brandInput.current.value
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateType({ data, id: type_id }))
        }
    }

    const deleteTypeAlt = (type_id) => {
        const data = {
            id: type_id
        }

        dispatch(deleteType(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewBrands())
        dispatch(viewTypes())
    }, [])

    useEffect(() => {
        if ((Date.now() - typesState.time < 100) && (typesState.status === 200 || typesState.status === 201) && typesState.message && (typesState.operation === "viewTypes" || typesState.operation === "searchTypes" || typesState.operation === "addType" || typesState.operation === "updateType" || typesState.operation === "deleteType")) {
            // toastify("success", typesState.status, typesState.message)
        }
        else if ((Date.now() - typesState.time < 100) && (typesState.status === 400 || typesState.status === 401 || typesState.status === 403 /*|| typesState.status === 404*/) && typesState.message && (typesState.operation === "viewTypes" || typesState.operation === "searchTypes" || typesState.operation === "addType" || typesState.operation === "updateType" || typesState.operation === "deleteType")) {
            toastify("error", typesState.status, typesState.message)
        }
        else if ((Date.now() - typesState.time < 100) && typesState.status === 500 && typesState.message && (typesState.operation === "viewTypes" || typesState.operation === "searchTypes" || typesState.operation === "addType" || typesState.operation === "updateType" || typesState.operation === "deleteType")) {
            toastify("warn", typesState.status, typesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [typesState.status, typesState.message, typesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Types
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by brand or name"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchTypesAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    typesState.isLoading && typesState.operation !== "addType" && typesState.operation !== "updateType" && typesState.operation !== "deleteType" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Brand
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="4"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Types
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        typesState.types.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={brandInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Brand</option>
                                                        {
                                                            brandsState.brands.map((brand) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={brand.id}>{brand.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "Brand"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addTypeAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && typesState.isLoading && typesState.operation === "addType" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && typesState.errors && typesState.operation === "addType" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        typesState.errors && typesState.errors.brand_id && typesState.operation === "addType" ?
                                                            typesState.errors && typesState.errors.brand_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        typesState.errors && typesState.errors.name && typesState.operation === "addType" ?
                                                            typesState.errors && typesState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="4"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Types Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <select ref={brandInput} className="bg-transparent focus:outline-none">
                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Brand</option>
                                                        {
                                                            brandsState.brands.map((brand) => {
                                                                return (
                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={brand.id}>{brand.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> :
                                                    "Brand"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addTypeAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && typesState.isLoading && typesState.operation === "addType" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && typesState.errors && typesState.operation === "addType" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        typesState.errors && typesState.errors.brand_id && typesState.operation === "addType" ?
                                                            typesState.errors && typesState.errors.brand_id : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        typesState.errors && typesState.errors.name && typesState.operation === "addType" ?
                                                            typesState.errors && typesState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        typesState.types.map((type, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === type.id && typesState.errors && typesState.operation === "updateType" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === type.id ?
                                                                    <select ref={brandInput} defaultValue={type.brand?.id} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Brand</option>
                                                                        {
                                                                            brandsState.brands.map((brand) => {
                                                                                return (
                                                                                    <option className='bg-queenAlt dark:bg-kingAlt text-sm' value={brand.id}>{brand.name}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select> :
                                                                    type.brand?.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === type.id ?
                                                                    <input ref={nameInput} defaultValue={type.name} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                                    type.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === type.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(type.id); updateTypeAlt(type.id); setShowUpdateErrors(type.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(type.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === type.id && typesState.isLoading && typesState.operation === "updateType" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(type.id); deleteTypeAlt(type.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === type.id && typesState.isLoading && typesState.operation === "deleteType" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === type.id && typesState.errors && typesState.operation === "updateType" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        typesState.errors && typesState.errors.brand_id && typesState.operation === "updateType" ?
                                                                            typesState.errors && typesState.errors.brand_id : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        typesState.errors && typesState.errors.name && typesState.operation === "updateType" ?
                                                                            typesState.errors && typesState.errors.name : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Types