import axios from 'axios'

const viewServices = async () => {
  const response = await axios.get('/api/view-services')
  return response
}

const addService = async (data) => {
  const response = await axios.post('/api/add-service', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewService = async ({ id }) => {
  const response = await axios.get('/api/view-service/' + id)
  return response
}

const updateService = async ({ id, data }) => {
  const response = await axios.post('/api/update-service/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteService = async ({ id }) => {
  const response = await axios.delete('/api/delete-service/' + id)
  return response
}

const searchServices = async ({ key }) => {
  const response = await axios.get('/api/search-services?key=' + key)
  return response
}

const addRequest = async ({ id }) => {
  const response = await axios.post('/api/add-request/' + id)
  return response
}

const servicesService = {
  viewServices,
  addService,
  viewService,
  updateService,
  deleteService,
  searchServices,
  addRequest
}

export default servicesService