import axios from 'axios'

const viewStores = async () => {
  const response = await axios.get('/api/view-stores')
  return response
}

const addStore = async (data) => {
  const response = await axios.post('/api/add-store', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const viewStore = async ({ id }) => {
  const response = await axios.post('/api/view-store/' + id)
  return response
}

const updateStore = async ({ id, data }) => {
  const response = await axios.post('/api/update-store/' + id, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const deleteStore = async ({ id }) => {
  const response = await axios.delete('/api/delete-store/' + id)
  return response
}

const searchStores = async ({ key }) => {
  const response = await axios.get('/api/search-stores?key=' + key)
  return response
}

const storesService = {
  viewStores,
  addStore,
  viewStore,
  updateStore,
  deleteStore,
  searchStores
}

export default storesService