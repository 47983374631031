import { createSlice, createAsyncThunk } from '@reduxjs/toolkit' 
import invoicesService from './invoicesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  invoices: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewInvoices = createAsyncThunk(
  'invoices/viewInvoices',
  async (_, thunkAPI) => {
    try {
      const response = await invoicesService.viewInvoices()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addInvoice = createAsyncThunk(
  'invoices/addInvoice',
  async (data, thunkAPI) => {
    try {
      const response = await invoicesService.addInvoice(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewInvoice = createAsyncThunk(
  'invoices/viewInvoice',
  async (data, thunkAPI) => {
    try {
      const response = await invoicesService.viewInvoice(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateInvoice = createAsyncThunk(
  'invoices/updateInvoice',
  async (data, thunkAPI) => {
    try {
      const response = await invoicesService.updateInvoice(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteInvoice = createAsyncThunk(
  'invoices/deleteInvoice',
  async (data, thunkAPI) => {
    try {
      const response = await invoicesService.deleteInvoice(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchInvoices = createAsyncThunk(
  'invoices/searchInvoices',
  async (data, thunkAPI) => {
    try {
      const response = await invoicesService.searchInvoices(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.invoices = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewInvoices.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.invoices = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewInvoices"
        state.time = Date.now()
      })
      .addCase(viewInvoices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invoices = (action.payload)[0].invoices
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewInvoices.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addInvoice.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addInvoice"
        state.time = Date.now()
      })
      .addCase(addInvoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invoices = [...state.invoices, (action.payload)[0].invoice]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addInvoice.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewInvoice.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewInvoice"
        state.time = Date.now()
      })
      .addCase(viewInvoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.invoices.length === 0) {
          state.invoices = [(action.payload)[0].invoice]
        }
        else {
          state.invoices = state.invoices.map(invoice => {
            if (invoice.id === (action.payload)[0].invoice.id) {
              return (action.payload)[0].invoice
            }
            else {
              return invoice
            }
          })
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewInvoice.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateInvoice.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateInvoice"
        state.time = Date.now()
      })
      .addCase(updateInvoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        if (state.invoices.length === 0) {
          state.invoices = [(action.payload)[0].invoice]
        }
        else {
          state.invoices = state.invoices.map(invoice => {
            if (invoice.id === (action.payload)[0].invoice.id) {
              return (action.payload)[0].invoice
            }
            else {
              return invoice
            }
          })
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateInvoice.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteInvoice.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteInvoice"
        state.time = Date.now()
      })
      .addCase(deleteInvoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invoices = state.invoices.filter(invoice => {
          return invoice.id !== (action.payload)[0].invoice.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteInvoice.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchInvoices.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.invoices = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchInvoices"
        state.time = Date.now()
      })
      .addCase(searchInvoices.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.invoices = (action.payload)[0].invoices
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchInvoices.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = invoicesSlice.actions
export default invoicesSlice.reducer