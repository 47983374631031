import React from 'react'
import { Link } from 'react-router-dom'
import { FaChevronRight, FaMapMarkerAlt } from 'react-icons/fa'

const LayoutBanner = ({ hrefs }) => {
    return (
        // <div className='h-[25vh] bg-gradient-to-r from-queen/75 to-l-extra/75 dark:from-king/75 dark:to-d-extra/75 flex justify-center items-center'>
        <div className='min-h-[20vh] md:min-h-[30vh] bg-queen dark:bg-king flex justify-center items-center'>
            <ul className='flex flex-wrap justify-center items-center gap-1 text-lg sm:text-xl md:text-2xl font-medium'>
                {
                    hrefs.map((href, idx) => {
                        return (
                            <li className={`${idx !== hrefs.length - 1 ? "text-light/90" : "text-dark/90"} flex flex-wrap justify-center items-center gap-0.5 relative`}>
                                <Link to={href.to} className={`${idx !== hrefs.length - 1 ? "hover:border-light/90 duration-150" : "cursor-none"} hidden lg:inline pb-0.5 border-b md:border-b-2 border-transparent`} >
                                    {href.title.length > 36 ? href.title.substr(0, 36) + "..." : href.title}
                                </Link>
                                <Link to={href.to} className={`${idx !== hrefs.length - 1 ? "hover:border-light/90 duration-150" : "cursor-none"} lg:hidden pb-0.5 border-b md:border-b-2 border-transparent`} >
                                    {href.title.length > 12 ? href.title.substr(0, 12) + "..." : href.title}
                                </Link>
                                {
                                    idx !== hrefs.length - 1 ?
                                        <span className=''><FaChevronRight /></span> :
                                        <span className='absolute -top-3 -right-3 md:-top-4 md:-right-4 text-xl md:text-2xl rotate-[30deg] opacity-90'><FaMapMarkerAlt /></span>
                                }
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default LayoutBanner