import React, { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset, set } from "../Redux/Features/Auth/authSlice";
import { toggle } from '../Redux/Features/Theme/themeSlice';
import { deleteUser, updateUser } from "../Redux/Features/Users/usersSlice";
import { Sidenav, initTE } from "tw-elements";
import { FaBars, FaBookReader, FaBookmark, FaBriefcase, FaBullhorn, FaBusinessTime, FaChartBar, FaChevronLeft, FaChevronRight, FaCubes, FaEdit, FaFileInvoice, FaFileInvoiceDollar, FaHome, FaHornbill, FaImages, FaLaptopCode, FaMoon, FaPuzzlePiece, FaSave, FaSignOutAlt, FaSitemap, FaSpinner, FaStore, FaSun, FaTachometerAlt, FaTag, FaTimes, FaTrash, FaUsers } from 'react-icons/fa';
import { confirmModal, toastify } from '../Helper';
import logo from '../Images/logo.png';

const SideNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameInput = useRef();
  const emailInput = useRef();
  const passwordInput = useRef();
  const usersState = useSelector((state) => state.users);
  const authState = useSelector((state) => state.auth);
  const themeState = useSelector((state) => state.theme);
  const [isOpen, setIsOpen] = useState(true) // Sidenave
  const [openUpdate, setOpenUpdate] = useState(false)
  const [showUpdateErrors, setShowUpdateErrors] = useState(false)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)

  const toggleTheme = () => {
    dispatch(toggle());
    setTimeout(() => {
      // navigate(0);
    }, 1000);
  }

  const updateUserAlt = () => {
    let data = {
      name: nameInput.current.value,
      email: emailInput.current.value,
      password: passwordInput.current.value,
    }

    data = Object.entries(data).reduce((acc, [key, value]) => {
      if (value !== '' && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {});

    if (Object.keys(data).length > 0) {
      dispatch(updateUser({ data, id: authState.auth.user.id }))
      setShowUpdateErrors(true);
    }
  }

  const deleteUserAlt = () => {
    const data = {
      id: authState.auth.user.id
    }

    dispatch(deleteUser(data))
  }

  useEffect(() => {
    initTE({ Sidenav });
    document
      .getElementById("slim-toggler")
      .addEventListener("click", () => {
        const instance = Sidenav.getInstance(
          document.getElementById("sidenav")
        );
        instance.toggleSlim();
      });
  }, [])

  useEffect(() => {
    if ((Date.now() - authState.time < 100) && authState.status === 200 && authState.message && authState.operation === "logout") {
      // toastify("success", authState.status, authState.message)
      navigate("/login")
    }
    else if ((Date.now() - authState.time < 100) && (authState.status === 400 || authState.status === 401 || authState.status === 403) && authState.message && authState.operation === "logout") {
      toastify("error", authState.status, authState.message)
    }
    else if ((Date.now() - authState.time < 100) && authState.status === 500 && authState.message && authState.operation === "logout") {
      toastify("warn", authState.status, authState.message)
      setTimeout(() => {
        navigate(0)
      }, 5000);
    }
  }, [authState.status, authState.message, authState.errors])

  useEffect(() => {
    if ((Date.now() - usersState.time < 100) && (usersState.status === 200) && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
      // toastify("success", usersState.status, usersState.message)
      // Update Auth State
      if (usersState.operation === "updateUser") {
        const users = (usersState.users).filter(user => {
          return user.id === authState.auth.user.id
        })
        dispatch(set({ user: users[0] }));
      }
      if (usersState.operation === "deleteUser") {
        const users = (usersState.users).filter(user => {
          return user.id === authState.auth.user.id
        })
        if (!users[0]) {
          dispatch(reset());
          localStorage.clear();
        }
      }
    }
    else if ((Date.now() - usersState.time < 100) && (usersState.status === 400 || usersState.status === 401 || usersState.status === 403 || usersState.status === 404) && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
      // toastify("error", usersState.status, usersState.message)
    }
    else if ((Date.now() - usersState.time < 100) && usersState.status === 500 && usersState.message && (usersState.operation === "updateUser" || usersState.operation === "deleteUser")) {
      // toastify("warn", usersState.status, usersState.message)
      // setTimeout(() => {
      //   navigate(0)
      // }, 5000);
    }
  }, [usersState.status, usersState.message, usersState.errors])

  return (
    <div>
      {/* Sidenav */}
      <nav id="sidenav" className="bg-queenAlt dark:bg-kingAlt border-r border-queen dark:border-king group fixed left-0 top-0 z-[10] h-screen w-60 -translate-x-full overflow-hidden data-[te-sidenav-slim='true']:hidden data-[te-sidenav-slim-collapsed='true']:w-[60px] data-[te-sidenav-slim='true']:w-[60px] data-[te-sidenav-hidden='false']:translate-x-0 [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='false']]:hidden [&[data-te-sidenav-slim-collapsed='true'][data-te-sidenav-slim='true']]:[display:unset]" data-te-sidenav-init data-te-sidenav-hidden="false" data-te-sidenav-mode="side" data-te-sidenav-slim="true" data-te-sidenav-content="#slim-content" data-te-sidenav-slim-collapsed="true">
        <h1 className="p-1">
          <Link title='LB' to={`/`} className="text-queen dark:text-king hover:bg-light dark:hover:bg-dark transition-all duration-150 flex items-center gap-1 cursor-pointer truncate px-4 py-2 text-[0.875rem] outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
            {/* <FaHome className="text-xl md:text-2xl font-semibold" /> */}
            {/* <span className="text-xl md:text-2xl font-semibold group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">L<span className='text-queen dark:text-king'>B</span></span> */}

            {/* <img className='w-6 lg:w-10' src={logo} alt="Logo" /> */}
            <FaTachometerAlt className="text-lg md:text-xl font-medium mr-1" />
            <span className="font-semibold text-base lg:text-xl group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden opacity-90" data-te-sidenav-slim="false">WMK Tech</span>
          </Link>
        </h1>

        <div className="text-dark dark:text-light ml-6 flex flex-col justify-center text-sm gap-1">
          {/* <a target='_blank' href={`${authState.auth.user.image ? process.env.REACT_APP_API_BASE_URL + authState.auth.user.image : null}`} style={{ backgroundImage: `url(${authState.auth.user.image ? process.env.REACT_APP_API_BASE_URL + authState.auth.user.image : null})` }} className="-ml-2 bg-cover bg-center relative my-2 w-20 h-20 rounded-full overflow-hidden shadow-md shadow-queen/50 dark:shadow-king/50 group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false" rel="noreferrer">
            {
              !authState.auth.user.image ?
                <FaUser className="text-4xl absolute top-5 left-6" /> :
                null
            }
          </a> */}

          <span className="text-dark dark:text-light group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">
            {
              openUpdate ?
                <input ref={nameInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New name...' type='text' /> :
                authState.auth.user.name
            }
          </span>
          <span className="text-dark dark:text-light group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">
            {
              openUpdate ?
                <input ref={emailInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New email...' type='text' /> :
                authState.auth.user.email
            }
          </span>
          <span className="text-dark dark:text-light group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">
            {
              openUpdate ?
                <input ref={passwordInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50 bg-transparent focus:outline-none' placeholder='New password...' type='password' /> :
                null
            }
          </span>

          <span className="text-dark dark:text-light group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">
            {
              showUpdateErrors && usersState.errors && usersState.errors.name && usersState.operation === "updateUser" ?
                <span className='block text-[12px] text-danger mb-1'>- {usersState.errors && usersState.errors.name}</span> : null
            }
            {
              showUpdateErrors && usersState.errors && usersState.errors.email && usersState.operation === "updateUser" ?
                <span className='block text-[12px] text-danger mb-1'>- {usersState.errors && usersState.errors.email}</span> : null
            }
            {
              showUpdateErrors && usersState.errors && usersState.errors.password && usersState.operation === "updateUser" ?
                <span className='block text-[12px] text-danger mb-1'>- {usersState.errors && usersState.errors.password}</span> : null
            }
          </span>

          <div
            className="mb-1 flex w-full group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false"
            role="group"
          >
            {
              openUpdate ?
                <button
                  title='Save Account'
                  onClick={() => { setOpenUpdate(false); updateUserAlt(); /*setShowUpdateErrors(true)*/ }}
                  type="button"
                  className="text-light rounded-l flex-1 bg-king px-4 py-1 leading-normal text-lighttransition duration-150 ease-in-out hover:bg-king/90 focus:bg-king/90 focus:outline-none focus:ring-0 active:bg-king/90"
                  data-te-ripple-init=""
                  data-te-ripple-color="light"
                >
                  <FaSave className='text-xs mx-auto' />
                </button> :
                <button
                  title='Edit Account'
                  onClick={() => setOpenUpdate(true)}
                  type="button"
                  className="text-light rounded-l flex-1 bg-king px-4 py-1 leading-normal text-lighttransition duration-150 ease-in-out hover:bg-king/90 focus:bg-king/90 focus:outline-none focus:ring-0 active:bg-king/90"
                  data-te-ripple-init=""
                  data-te-ripple-color="light"
                >
                  {
                    usersState.isLoading && usersState.operation === "updateUser" ?
                      <FaSpinner className=' animate-spin text-xs mx-auto' /> :
                      <FaEdit className='text-xs mx-auto' />
                  }
                </button>
            }
            {
              showUpdateErrors && usersState.errors && usersState.operation === "updateUser" ?
                <button
                  title='Close Errors'
                  type="button"
                  className="text-light border-e flex-1 bg-queen px-4 py-1 leading-normal text-lighttransition duration-150 ease-in-out hover:bg-queen/90 focus:bg-queen/90 focus:outline-none focus:ring-0 active:bg-queen/90"
                  data-te-ripple-init=""
                  data-te-ripple-color="light"
                  onClick={() => { setShowUpdateErrors(false) }}
                >
                  <FaTimes className='text-xs mx-auto' />
                </button> :
                null
            }
            <button
              title='Delete Account'
              type="button"
              className="text-light flex-1 bg-queen px-4 py-1 leading-normal text-lighttransition duration-150 ease-in-out hover:bg-queen/90 focus:bg-queen/90 focus:outline-none focus:ring-0 active:bg-queen/90"
              data-te-ripple-init=""
              data-te-ripple-color="light"
              onClick={() => { setOpenDeleteUserModal(true) }}
            >
              {
                usersState.isLoading && usersState.operation === "deleteUser" ?
                  <FaSpinner className=' animate-spin text-xs mx-auto' /> :
                  <FaTrash className='text-xs mx-auto' />
              }
            </button>
            <button
              title='Logout'
              type="button"
              className="text-light rounded-r flex-1 bg-red-500 px-4 py-1 leading-normal text-lighttransition duration-150 ease-in-out hover:bg-red-600 focus:bg-red-600 focus:outline-none focus:ring-0 active:bg-red-600"
              data-te-ripple-init=""
              data-te-ripple-color="light"
              onClick={() => { dispatch(logout()) }}
            >
              {
                authState.isLoading && authState.operation === "logout" ?
                  <FaSpinner className=' animate-spin text-xs mx-auto' /> :
                  <FaSignOutAlt className='text-xs mx-auto' />
              }
            </button>
          </div>
        </div>

        <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />

        <ul data-te-sidenav-menu-ref className="relative m-0 list-none px-[0.2rem]">
          <li className="relative">
            <Link title='Dashboard' to={`/admin/dashboard`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaChartBar className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Dashboard</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Users' to={`/admin/users`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaUsers className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Users</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Slides' to={`/admin/slides`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaImages className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Slides</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Services' to={`/admin/services`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaBriefcase className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Services</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Requests' to={`/admin/requests`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaBusinessTime className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Requests</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Stores' to={`/admin/stores`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaStore className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Stores</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Brands' to={`/admin/brands`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaBookmark className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Brands</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Types' to={`/admin/types`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaPuzzlePiece className="text-lg md:text-xl font-medium ml-0.5" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Types</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Categories' to={`/admin/categories`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaTag className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Categories</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Products' to={`/admin/products`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaCubes className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Products</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Favorites' to={`/admin/favorites`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaSitemap className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Favorites</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Orders' to={`/admin/orders`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaFileInvoice className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Orders</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Invoices' to={`/admin/invoices`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaFileInvoiceDollar className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Invoices</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Jobs' to={`/admin/jobs`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaLaptopCode className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Jobs</span>
            </Link>
          </li>
          <li className="relative">
            <Link title='Reviews' to={`/admin/reviews`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaBookReader className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Reviews</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
          <li className="relative">
            <Link title='Advertises' to={`/admin/advertises`} className="text-dark dark:text-light hover:bg-light dark:hover:bg-dark cursor-pointer flex items-center gap-2 truncate px-4 py-2 transition duration-150 outline-none hover:outline-none focus:outline-none active:outline-none data-[te-sidenav-state-focus]:outline-none motion-reduce:transition-none " data-te-sidenav-link-ref>
              <FaBullhorn className="text-lg md:text-xl font-medium" />
              <span className="text-sm md:text-base font-normal group-[&[data-te-sidenav-slim-collapsed='true']]:data-[te-sidenav-slim='false']:hidden" data-te-sidenav-slim="false">Advertises</span>
            </Link>
          </li>
          <hr className="my-1 border-none bg-queen dark:bg-king" style={{ height: "0.5px" }} />
        </ul>
      </nav>

      <div id="slim-content" className="flex !pl-[77px] fixed z-[10]">
        {/* Toggler */}
        <button className={isOpen ? "border-b border-queen dark:border-king text-dark dark:text-light bg-queenAlt dark:bg-kingAlt hover:bg-light dark:hover:bg-dark transition-all duration-150 inline-block px-3 py-2 text-xs font-medium uppercase leading-tight" : "hidden"} aria-haspopup="true" id="slim-toggler">
          <FaBars className="text-sm" />
        </button>
        {/* Toggler */}
        <button onClick={() => toggleTheme()} className="border-b border-queen dark:border-king text-dark dark:text-light bg-queenAlt dark:bg-kingAlt hover:bg-light dark:hover:bg-dark transition-all duration-150 inline-block px-3 py-2 text-xs font-medium uppercase leading-tight">
          {
            themeState.theme === "dark" ?
              <FaSun className='text-sm' /> :
              <FaMoon className='text-sm' />
          }
        </button>
        {/* Toggler */}
        <button onClick={() => setIsOpen(!isOpen)} className="border-b border-r border-queen dark:border-king text-dark dark:text-light bg-queenAlt dark:bg-kingAlt hover:bg-light dark:hover:bg-dark transition-all duration-150 inline-block px-3 py-2 text-xs font-medium uppercase leading-tight" data-te-sidenav-toggle-ref data-te-target="#sidenav" aria-controls="#sidenav" aria-haspopup="true">
          {
            isOpen ?
              <FaChevronLeft className="text-sm" /> :
              <FaChevronRight className="text-sm" />
          }
        </button>
      </div>
      {/* Modals */}
      {
        confirmModal(openDeleteUserModal, setOpenDeleteUserModal, "Delete Account", deleteUserAlt, null, usersState.isLoading)
      }
      <Outlet />
    </div>
  )
}

export default SideNav