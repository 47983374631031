import React, { useEffect, useRef, useState } from 'react'
import { updateRequest } from '../../../Redux/Features/Requests/requestsSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toastify } from '../../../Helper';
import { FaSpinner } from 'react-icons/fa';

const RequestUsers = ({ users }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const requestsState = useSelector((state) => state.requests);
    const [updateUser, setUpdateUser] = useState(null)

    const updateRequestAlt = (isReviewed, service_id, user_id) => {
        setUpdateUser(user_id)
        let data = {
            is_reviewed: isReviewed
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateRequest({ data, service_id, user_id }))
        }
    }

    useEffect(() => {
        if ((Date.now() - requestsState.time < 100) && requestsState.status === 200 && requestsState.message && requestsState.operation === "updateRequest") {
            toastify("success", requestsState.status, requestsState.message)
        }
        else if ((Date.now() - requestsState.time < 100) && (requestsState.status === 400 || requestsState.status === 401 || requestsState.status === 403 || requestsState.status === 404) && requestsState.message && requestsState.operation === "updateRequest") {
            toastify("error", requestsState.status, requestsState.message)
        }
        else if ((Date.now() - requestsState.time < 100) && requestsState.status === 500 && requestsState.message && requestsState.operation === "updateRequest") {
            toastify("warn", requestsState.status, requestsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [requestsState.status, requestsState.message, requestsState.errors])

    return (
        <div className="mx-auto max-w-6xl overflow-x-auto p-1">
            {
                <table className="w-full text-left bg-queenAlt dark:bg-kingAlt text-dark dark:text-light">
                    <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-bold">
                        <tr>
                            <th scope="col" className="px-4 py-2">
                                Requestd by user
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Phone
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Email
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Address
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Is Reviewed
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-sm md:text-base'>
                        {
                            users.map((user, idx) => {
                                return (
                                    <tr className="border-b border-queen/25 dark:border-king/25">
                                        <td className="px-4 py-2 ">{user.name}</td>
                                        <td className="px-4 py-2 ">{user.phone}</td>
                                        <td className="px-4 py-2 ">{user.email}</td>
                                        <td className="px-4 py-2 ">{user.address}</td>
                                        <td className="px-4 py-2 flex items-center gap-1">
                                            <select onChange={(e) => updateRequestAlt(e.target.value, user.service_id, user.id)} defaultValue={user.service_is_reviewed} className="bg-transparent focus:outline-none">
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Reviewed</option>
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                            </select>
                                            {
                                                updateUser === user.id && requestsState.isLoading && requestsState.operation === "updateRequest" ?
                                                    <FaSpinner className='text-lg animate-spin inline' /> :
                                                    null
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div >
    )
}

export default RequestUsers