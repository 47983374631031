import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import productsService from './productsService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  products: [],
  message: null,
  errors: null, 
  status: null,
  operation: null,
  time: null
}

export const viewProducts = createAsyncThunk(
  'products/viewProducts',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.viewProducts(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addProduct = createAsyncThunk(
  'products/addProduct',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.addProduct(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewProduct = createAsyncThunk(
  'products/viewProduct',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.viewProduct(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateProduct = createAsyncThunk(
  'products/updateProduct',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.updateProduct(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteProduct = createAsyncThunk(
  'products/deleteProduct',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.deleteProduct(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchProducts = createAsyncThunk(
  'products/searchProducts',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.searchProducts(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addRate = createAsyncThunk(
  'products/addRate',
  async (data, thunkAPI) => {
    try {
      const response = await productsService.addRate(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.products = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewProducts.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.products = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewProducts"
        state.time = Date.now()
      })
      .addCase(viewProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.products = (action.payload)[0].products
        state.translatedProduct = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewProducts.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addProduct.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addProduct"
        state.time = Date.now()
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.products = [...state.products, (action.payload)[0].product]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewProduct.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewProduct"
        state.time = Date.now()
      })
      .addCase(viewProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.products = state.products.map(product => {
          if (product.id === (action.payload)[0].product.id) {
            return (action.payload)[0].product
          }
          else {
            return product
          }
        })

        const foundProduct = state.products.find(product => product.id === action.payload[0].product.id);
        if (!foundProduct) {
          state.products.push(action.payload[0].product);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateProduct.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateProduct"
        state.time = Date.now()
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.products = state.products.map(product => {
          if (product.id === (action.payload)[0].product.id) {
            return (action.payload)[0].product
          }
          else {
            return product
          }
        })

        const foundProduct = state.products.find(product => product.id === action.payload[0].product.id);
        if (!foundProduct) {
          state.products.push(action.payload[0].product);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteProduct.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteProduct"
        state.time = Date.now()
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.products = state.products.filter(product => {
          return product.id !== (action.payload)[0].product.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchProducts.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.products = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchProducts"
        state.time = Date.now()
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.products = (action.payload)[0].products
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchProducts.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addRate.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addRate"
        state.time = Date.now()
      })
      .addCase(addRate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.products = state.products.map(product => {
          if (product.id === (action.payload)[0].product.id) {
            return (action.payload)[0].product
          }
          else {
            return product
          }
        })

        const foundProduct = state.products.find(product => product.id === action.payload[0].product.id);
        if (!foundProduct) {
          state.products.push(action.payload[0].product);
        }

        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addRate.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = productsSlice.actions
export default productsSlice.reducer