import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewInvoices, searchInvoices, updateInvoice } from "../../../Redux/Features/Invoices/invoicesSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaChevronDown, FaChevronUp, FaEdit, FaSave, FaSpinner, FaTimes } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';
import InvoiceProducts from './InvoiceProducts';

const Invoices = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const invoicesState = useSelector((state) => state.invoices);
    const searchInput = useRef();
    const notesInput = useRef();
    const isPaidInput = useRef();
    const [openInvoice, setOpenInvoice] = useState(null)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadUpdate, setLoadUpdate] = useState(null)

    const searchInvoicesAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchInvoices(data))
    }

    const updateInvoiceAlt = (invoice_id) => {
        let data = {
            notes: notesInput.current.value,
            is_paid: isPaidInput.current.value
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateInvoice({ data, id: invoice_id }))
        }
    }

    useEffect(() => {
        setOpenInvoice(invoicesState?.invoices[0]?.id)
    }, [invoicesState.invoices])

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewInvoices())
    }, [])

    useEffect(() => {
        if ((Date.now() - invoicesState.time < 100) && invoicesState.status === 200 && invoicesState.message && (invoicesState.operation === "viewInvoices" || invoicesState.operation === "searchInvoices" || invoicesState.operation === "updateInvoice")) {
            // toastify("success", invoicesState.status, invoicesState.message)
        }
        else if ((Date.now() - invoicesState.time < 100) && (invoicesState.status === 400 || invoicesState.status === 401 || invoicesState.status === 403 /*|| invoicesState.status === 404*/) && invoicesState.message && (invoicesState.operation === "viewInvoices" || invoicesState.operation === "searchInvoices" || invoicesState.operation === "updateInvoice")) {
            toastify("error", invoicesState.status, invoicesState.message)
        }
        else if ((Date.now() - invoicesState.time < 100) && invoicesState.status === 500 && invoicesState.message && (invoicesState.operation === "viewInvoices" || invoicesState.operation === "searchInvoices" || invoicesState.operation === "updateInvoice")) {
            toastify("warn", invoicesState.status, invoicesState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [invoicesState.status, invoicesState.message, invoicesState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Invoices
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by user email, order identifier or notes"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => { searchInvoicesAlt() }}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    invoicesState.isLoading && invoicesState.operation !== "updateInvoice" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        User
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Order
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Products
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Total Price
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Notes
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Is Paid
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="8"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Invoices
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        invoicesState.invoices.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Order
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Products
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Total Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Notes
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Is Paid
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="8"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Invoices Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            User
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Order
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Products
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Total Price
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Notes
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Is Paid
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        invoicesState.invoices.map((invoice, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === invoice.id && invoicesState.errors && invoicesState.operation === "updateInvoice" ? "" : "border-b border-queen/50 dark:border-king/50 "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{invoice.user.email}</td>
                                                        <td className="px-4 py-2">{invoice.order.identifier}</td>
                                                        <td className="px-4 py-2">{invoice.order?.products?.reduce((accumulator, product) => accumulator + parseInt(product.quantity), 0)}</td>
                                                        <td className="px-4 py-2">${invoice.order?.products?.reduce((accumulator, product) => accumulator + (parseInt(product.quantity) * parseFloat(product.unitPrice)), 0)}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === invoice.id ?
                                                                    <input ref={notesInput} defaultValue={invoice.notes} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Notes' type='text' /> :
                                                                    invoice.notes || "..."
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === invoice.id ?
                                                                    <select ref={isPaidInput} defaultValue={invoice.is_paid} className="bg-transparent focus:outline-none">
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="">Is Paid</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="1">Yes</option>
                                                                        <option className='bg-queenAlt dark:bg-kingAlt text-sm' value="0">No</option>
                                                                    </select> :
                                                                    <span>{invoice.is_paid == "0" ? "No" : "Yes"}</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                <button
                                                                    onClick={() => setOpenInvoice(openInvoice === invoice.id ? null : invoice.id)}
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                >
                                                                    {
                                                                        openInvoice === invoice.id ?
                                                                            <FaChevronUp className='text-base md:text-lg' /> :
                                                                            <FaChevronDown className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                                {
                                                                    openUpdate === invoice.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(invoice.id); updateInvoiceAlt(invoice.id); setShowUpdateErrors(invoice.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(invoice.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === invoice.id && invoicesState.isLoading && invoicesState.operation === "updateInvoice" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === invoice.id && invoicesState.errors && invoicesState.operation === "updateInvoice" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50 text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        invoicesState.errors && invoicesState.errors.notes && invoicesState.operation === "updateInvoice" ?
                                                                            invoicesState.errors && invoicesState.errors.notes : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        invoicesState.errors && invoicesState.errors.is_paid && invoicesState.operation === "updateInvoice" ?
                                                                            invoicesState.errors && invoicesState.errors.is_paid : "..."
                                                                    }
                                                                </td>
                                                                <td className="pl-4 pr-0 md:px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                    {
                                                        openInvoice === invoice.id ?
                                                            <td colspan="8" className='bg-light dark:bg-dark'>
                                                                <InvoiceProducts products={invoice.order.products} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Invoices