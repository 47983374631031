import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LayoutBanner from '../../Components/LayoutBanner';
import { toastify } from '../../Helper';
import Container from '../../Components/Container';
import ProductsSearch from '../../Components/Products/ProductsSearch';
import ProductsFilter from '../../Components/Products/ProductsFilter';
import ProductsCards from '../../Components/Products/ProductsCards';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';
import { viewProducts } from '../../Redux/Features/Products/productsSlice';

const Products = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const productsState = useSelector((state) => state.products);
    const [products, setProducts] = useState(productsState.products);

    // const handleParams = () => {
    //     const params = new URLSearchParams();
    //     params.append('param1', 'value1');

    //     const queryString = params.toString();
    //     navigate(`/products?${queryString}`);
    // }; 

    useEffect(() => {
        const data = {
            params: location.search
        }

        dispatch(viewProducts(data))
    }, [location.search])

    useEffect(() => {
        setProducts(productsState.products)
    }, [productsState.products])

    useEffect(() => {
        if ((Date.now() - productsState.time < 100) && productsState.status === 200 && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "searchProducts")) {
            // toastify("success", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && (productsState.status === 400 || productsState.status === 401 || productsState.status === 403 /*|| productsState.status === 404*/) && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "searchProducts")) {
            toastify("error", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && productsState.status === 500 && productsState.message && (productsState.operation === "viewProducts" || productsState.operation === "searchProducts")) {
            toastify("warn", productsState.status, productsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [productsState.status, productsState.message, productsState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Products", to: "/products" }]} />
            {
                productsState.isLoading && productsState.operation === "viewProducts" ?
                    <LayoutLoading title={"products"} /> :
                    productsState.products.length === 0 && productsState.operation === "viewProducts" ?
                        <LayoutNotFound title={"Products"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Products", to: "/products" }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4 flex flex-col gap-4">
                                        <ProductsSearch />
                                        <ProductsFilter setProducts={setProducts} />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <ProductsCards products={products} />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Products

// const string = location.search;
// const queryString = string.startsWith("?") ? string.slice(1) : string;
// const pairs = queryString.split("&");
// const result = pairs.reduce((obj, pair) => {
//     const [key, value] = pair.split("=");
//     if (key && value) {
//         obj[key] = value;
//     }
//     return obj;
// }, {});
// console.log("result: ", result); // {is_new: 'true',is_offer: 'false', salary: '100'}

// let queryStringAlt = Object.entries(result)
//     .map(([key, value]) => `${key}=${value}`)
//     .join('&');

// if (queryStringAlt) {
//     queryStringAlt = '?' + queryStringAlt;
// }

// console.log("result alt: ", queryStringAlt); // ?is_new=true&is_offer=false&salary=100