import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addRate, viewProduct } from '../../Redux/Features/Products/productsSlice';
import { addFavorite } from '../../Redux/Features/Favorites/favoritesSlice'
import { addProduct } from '../../Redux/Features/Cart/cartSlice'
import { FaRegStar, FaStar, FaShoppingBag, FaHeart, FaLightbulb, FaSpinner, FaRegHeart } from 'react-icons/fa'
import { toastify } from '../../Helper';


const ProductDetails = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const productsState = useSelector((state) => state.products);
  const favoritesState = useSelector((state) => state.favorites);
  const [openRate, setOpenRate] = useState(false)
  const [star, setStar] = useState(product.rating?.filter((rate) => {
    return rate.rater_id == authState?.auth?.user?.id
  })[0]?.rate)

  const addRateAlt = (rate) => {
    const data = {
      rate
    }

    dispatch(addRate({ data, id: product.id }))
  }

  const addProductAlt = () => {
    dispatch(addProduct({ product }))
  }

  const addFavoriteAlt = () => {
    dispatch(addFavorite({ id: product.id }))
  }

  useEffect(() => {
    if ((Date.now() - productsState.time < 100) && productsState.status === 200 && productsState.message && productsState.operation === "addRate") {
      // toastify("success", productsState.status, productsState.message)
      setOpenRate(false)
    }
    else if ((Date.now() - productsState.time < 100) && (productsState.status === 400 || productsState.status === 401 || productsState.status === 403 || productsState.status === 404) && productsState.message && (productsState.operation === "addRate")) {
      toastify("error", productsState.status, productsState.message)
    }
    else if ((Date.now() - productsState.time < 100) && productsState.status === 500 && productsState.message && (productsState.operation === "addRate")) {
      toastify("warn", productsState.status, productsState.message)
      setTimeout(() => {
        // navigate(0)
      }, 5000);
    }
  }, [productsState.status, productsState.message, productsState.errors])

  useEffect(() => {
    if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 200 && favoritesState.message && favoritesState.operation === "addFavorite") {
      if (favoritesState.target == product.id) {
        // toastify("success", favoritesState.status, favoritesState.message)
        setTimeout(() => {
          dispatch(viewProduct({ id: product.id }))
        }, 1000);
      }
    }
    else if ((Date.now() - favoritesState.time < 100) && (favoritesState.status === 400 || favoritesState.status === 401 || favoritesState.status === 403 || favoritesState.status === 404) && favoritesState.message && (favoritesState.operation === "addFavorite")) {
      toastify("error", favoritesState.status, favoritesState.message)
    }
    else if ((Date.now() - favoritesState.time < 100) && favoritesState.status === 500 && favoritesState.message && (favoritesState.operation === "addFavorite")) {
      toastify("warn", favoritesState.status, favoritesState.message)
      setTimeout(() => {
        // navigate(0)
      }, 5000);
    }
  }, [favoritesState.status, favoritesState.message, favoritesState.errors])

  return (
    <div>
      <div className='flex flex-col gap-1 text-dark dark:text-light'>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Name:</span>
          <span className='text-base md:text-lg font-medium'>{product.name}</span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>
            Description:
            <span className='ml-1 text-base md:text-lg font-normal'>{product.description}</span>
          </span>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Brand:</span>
            <span className='text-base md:text-lg font-normal'>{product.category.type.brand.name}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Type:</span>
            <span className='text-base md:text-lg font-normal'>{product.category.type.name}</span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Category:</span>
            <span className='text-base md:text-lg font-normal'>{product.category.name}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Gender:</span>
            <span className='text-base md:text-lg font-normal capitalize'>{product.gender}</span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Sizes:</span>
            <span className='flex gap-0.5 mt-1 text-base md:text-lg font-normal'>
              {
                product.sizes.map((size, idx) => {
                  return (
                    <span>
                      {size}
                      {idx !== product.sizes.length - 1 && ", "}
                    </span>
                  )
                })
              }
            </span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Colors:</span>
            <span className='flex gap-0.5 mt-1'>
              {
                product.colors.map((color) => {
                  return (
                    <FaLightbulb style={{ color: color }} className='inline text-base md:text-lg font-normal' />
                  )
                })
              }
            </span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Is New:</span>
            <span className='text-base md:text-lg font-normal'>{product.is_new === 0 ? "No" : "Yes"}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Is Offer:</span>
            <span className='text-base md:text-lg font-normal'>{product.is_offer === 0 ? "No" : "Yes"}</span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Is Available:</span>
            <span className='text-base md:text-lg font-normal'>{product.is_avilable === 0 ? "No" : "Yes"}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Price:</span>
            <span className='text-base md:text-lg font-normal flex justify-between items-center gap-2'>
              {
                parseFloat(product.price.oldPrice) ?
                  <span className='text-dark/50 dark:text-light/50 relative' style={{ textDecoration: 'line-through' }}>
                    ${parseFloat(product.price.oldPrice)}
                  </span> : null
              }
              <span>${product.price.newPrice}</span>
            </span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Reviews:</span>
            <span className='text-base md:text-lg font-normal'>{product.rating?.length || 0}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Rate:</span>
            <div className='text-base md:text-lg font-normal flex gap-1 items-center text-l-extra dark:text-d-extra'>
              {
                product.rating && Array(Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                  return <FaStar />;
                })
              }
              {
                product.rating ?
                  Array(5 - Math.ceil(product.rating?.reduce((accumulator, rate) => accumulator + parseInt(rate.rate), 0) / product.rating?.length)).fill(0).map((star) => {
                    return <FaRegStar />;
                  }) :
                  Array(5).fill(0).map((star) => {
                    return <FaRegStar />;
                  })
              }
            </div>
          </div>
        </div>
        <div className='grid md:grid-cols-3 gap-2 mt-4'>


          <button onClick={() => addProductAlt()} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
            <FaShoppingBag className='text-lg' />Add Cart
          </button>

          {
            authState.auth && authState.auth.user ?
              <button onClick={() => addFavoriteAlt()} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                {
                  favoritesState.isLoading && favoritesState.operation === "addFavorite" ?
                    <FaSpinner className='text-xl animate-spin' /> :
                    product.users?.find(user => user.id == authState.auth.user.id) ?
                      <><FaHeart className='text-lg' />Remove Favourite</> :
                      <><FaRegHeart className='text-lg' />Add Favourite</>

                }
              </button> :
              <Link to="/login" className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                <><FaRegHeart className='text-lg' />Add Favourite</>
              </Link>
          }

          {
            authState.auth && authState.auth.user ?
              <button onClick={() => setOpenRate((prev) => !prev)} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                {
                  productsState.isLoading && productsState.operation === "addRate" ?
                    <FaSpinner className='text-xl animate-spin' /> :
                    <><FaStar className='text-lg' />Give Rate</>

                }
              </button> :
              <Link to="/login" className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                <><FaStar className='text-lg' />Give Rate</>
              </Link>
          }

          {/* 
            <button onClick={() => setOpenRate((prev) => !prev)} className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
              <FaStar className='text-lg' />Give Rate
            </button>
          */}
        </div>
      </div>
      {
        openRate &&
        <div className='mt-4 text-dark dark:text-light text-base md:text-lg font-normal'>
          {
            authState?.auth && authState?.auth?.user ?
              <div className='flex flex-wrap gap-1 items-center'>
                Give product rate between zero and five:
                <div className='flex gap-1 items-center text-base md:text-lg font-normal text-l-extra dark:text-d-extra'>
                  {
                    Array(5).fill(0).map((star_, idx) => {
                      return (
                        <button
                          onClick={() => { setStar(star === idx + 1 ? idx : idx + 1); addRateAlt(star === idx + 1 ? idx : idx + 1) }}
                          className='hover:opacity-80 duration-150'
                        >
                          {
                            idx + 1 <= star ?
                              <FaStar /> :
                              <FaRegStar />
                          }
                        </button>
                      )
                    })
                  }
                </div>
              </div> :
              <div className=''>
                You must login first, from <Link to={"/login"} className='text-l-extra dark:text-d-extra border-b border-l-extra/50 dark:border-d-extra/50 hover:opacity-80 duration-150'>here</Link>
              </div>
          }
        </div>
      }
    </div>
  )
}

export default ProductDetails