import axios from 'axios'

const register = async (data) => {
  const response = await axios.post('/api/register', data)
  if (response.data) {
    localStorage.setItem('auth', JSON.stringify(response.data))
  }
  return response
}

const login = async (data) => {
  const response = await axios.post('/api/login', data)
  if (response.data) {
    localStorage.setItem('auth', JSON.stringify(response.data))
  }
  return response
}

const logout = async () => {
  const response = await axios.get('/api/logout')
  if (response.data) {
    localStorage.clear()
  }
  return response
}

const forgotPassword = async (data) => {
  const response = await axios.post('/api/forgot-password', data)
  return response
}

const resetPassword = async (data) => {
  const response = await axios.post('/api/reset-password', data)
  return response
}

const authService = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword
}

export default authService