import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewJob } from '../../Redux/Features/Jobs/jobsSlice';
import { toastify } from '../../Helper';
import LayoutBanner from '../../Components/LayoutBanner';
import Container from '../../Components/Container';
import JobDragAndDrop from '../../Components/Jobs/JobDragAndDrop';
import JobDetails from '../../Components/Jobs/JobDetails';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';

const Job = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const jobsState = useSelector((state) => state.jobs);
    const [job, setJob] = useState(null)
    const [file, setFile] = useState(null);
    const [disableUpload, setDisableUpload] = useState(false);

    useEffect(() => {
        dispatch(viewJob({ id }))
    }, [dispatch, id])

    useEffect(() => {
        const job = (jobsState.jobs.filter((job) => {
            return parseInt(job.id) === parseInt(id)
        }))[0]
        setJob(job)
    }, [jobsState.jobs])

    useEffect(() => {
        if ((Date.now() - jobsState.time < 100) && jobsState.status === 200 && jobsState.message && jobsState.operation === "viewJob") {
            // toastify("success", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && (jobsState.status === 400 || jobsState.status === 401 || jobsState.status === 403 /*|| jobsState.status === 404*/) && jobsState.message && (jobsState.operation === "viewJob")) {
            toastify("error", jobsState.status, jobsState.message)
        }
        else if ((Date.now() - jobsState.time < 100) && jobsState.status === 500 && jobsState.message && (jobsState.operation === "viewJob")) {
            toastify("warn", jobsState.status, jobsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [jobsState.status, jobsState.message, jobsState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Jobs", to: "/jobs" }, { title: job?.position || "Unknown", to: `/jobs/${id}` }]} />
            {
                jobsState.isLoading && jobsState.operation === "viewJob" ?
                    <LayoutLoading title={"job"} /> :
                    !job ?
                        <LayoutNotFound title={"Job"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Jobs", to: "/jobs" }, { title: job.position, to: `/jobs/${id}` }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4">
                                        <JobDragAndDrop disableUpload={disableUpload} setFile={setFile} />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <JobDetails job={job} setDisableUpload={setDisableUpload} file={file} />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Job