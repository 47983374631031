import React from 'react'

const SectionText = ({ text }) => {
    return (
        <p className='text-dark/75 dark:text-light/75 text-base md:text-2xl text-center font-light max-w-[750px] mx-auto scale-y-125 my-2 mb-8' style={{ "letterSpacing": "0px", "lineHeight": "24px" }}>
            {text}
        </p>
    )
}

export default SectionText