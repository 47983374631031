import React from 'react'
import SectionContainer from "../SectionContainer"
import SectionHead from "../SectionHead"
import ProductCard from "../Products/ProductCard"
import SectionLink from "../SectionLink"
import SectionText from '../SectionText'
import SectionCard from '../SectionCard'

const OffersProducts = ({ offersProducts }) => {
    return (
        <SectionContainer>
            <SectionHead title={"Offers Products"} />
            <SectionText text={"Discover incredible deals on a wide range of products. From electronics to fashion, our limited-time offers provide amazing value without compromising quality. Shop now and save!"} />
            <div className='my-4 md:my-6 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                {
                    offersProducts.length === 0 ?
                        Array(5).fill(0).map((item) => {
                            return (
                                <SectionCard />
                            )
                        }) :
                        offersProducts.slice(0, 5).map((product) => {
                            return (
                                <ProductCard product={product} target={"offer"} />
                            )
                        })
                }
            </div>
            <SectionLink title={"View More Offers"} to={"/products?is_offer=true"} />
        </SectionContainer>
    )
}

export default OffersProducts