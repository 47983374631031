import React from 'react'
import LayoutBanner from '../Components/LayoutBanner'
import Container from "../Components/Container"
import ProfileDetails from '../Components/Profile/ProfileDetails'
import ProfileOrders from '../Components/Profile/ProfileOrders' 

const Profile = () => {
    return (
        <div>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Profile", to: "/user/profile" }]} />
            <Container>
                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                    <div className="sm:col-span-3">
                        <ProfileDetails/>
                    </div>
                    <div className="sm:col-span-9 overflow-x-auto">
                        <ProfileOrders/>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Profile