import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { FaArrowLeft, FaArrowRight, FaEllipsisH } from 'react-icons/fa';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';
import ServiceCard from "./ServiceCard"

const ServicesCards = () => {
    const servicesState = useSelector((state) => state.services);
    const [services, setServices] = useState(servicesState.services);

    const Services = ({ currentServices }) => {
        return (
            // <div className="grid gap-2 grid-cols-1 xs:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-3">
                {
                    currentServices.map((service) => (
                        <ServiceCard service={service} />
                    ))}
            </div>
        );
    }

    const PaginatedServices = ({ servicesPerPage }) => {
        const [serviceOffset, setServiceOffset] = useState(0);
        const endOffset = serviceOffset + servicesPerPage;
        const currentServices = services?.slice(serviceOffset, endOffset);
        const pageCount = Math.ceil(services?.length / servicesPerPage);
        const handlePageClick = (event) => {
            const newOffset = (event.selected * servicesPerPage) % services.length;
            setServiceOffset(newOffset);
        };

        return (
            <div>
                <Services currentServices={currentServices} />
                <ReactPaginate
                    breakLabel={<FaEllipsisH className='text-base text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    nextLabel={<FaArrowRight className='text-xl text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount || 0}
                    previousLabel={<FaArrowLeft className='text-xl text-dark/75 dark:text-light/75 hover:opacity-80 transition-all duration-150' />}
                    renderOnZeroPageCount={null}
                    containerClassName='flex justify-center items-center gap-1 my-4 md:my-8'
                    pageClassName='!text-dark dark:!text-light bg-transparent border border-l-extra/75 dark:border-d-extra/75 shadow-none px-3 py-1 rounded-md hover:opacity-80 duration-150 cursor-pointer'
                    activeClassName='border !border-transparent !bg-l-extra/75 dark:!bg-d-extra/75 shadow-inner !shadow-light px-3 py-1'
                    activeLinkClassName='text-light dark:text-light'
                    previousClassName='px-2'
                    nextClassName='px-2'
                    disabledClassName='opacity-25'
                    disabledLinkClassName='cursor-not-allowed'
                />
            </div>
        );
    }

    useEffect(() => {
        setServices(servicesState.services)
    }, [servicesState.services])

    return (
        <>
            {
                servicesState.isLoading && servicesState.operation === "searchServices" ?
                    <LayoutLoading title={"services"} /> :
                    servicesState.services.length === 0 && servicesState.operation === "searchServices" ?
                        <LayoutNotFound title={"Services"} /> :
                        <div id="container">
                            <PaginatedServices servicesPerPage={6} />
                        </div>
            }
        </>
    )
}

export default ServicesCards