import React from 'react'
import Container from '../Components/Container'

const NotFound = () => {
  return (
    <Container>
      <div className='flex justify-center items-center gap-1 md:gap-2 h-[250px]'>
        <div className='text-light text-lg md:text-xl bg-queen dark:bg-king px-4 py-1 rounded-sm shadow-inner'>
          404
        </div>
        <h1 className='text-dark dark:text-light text-xl md:text-2xl'>
          Page not found
        </h1>
      </div>
    </Container>
  )
}

export default NotFound