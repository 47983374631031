import React from 'react'
import { useSelector } from 'react-redux';
import { FileUploader } from "react-drag-drop-files";

const JobDragAndDrop = ({ disableUpload, setFile }) => {
    const themeState = useSelector((state) => state.theme);
    const jobsState = useSelector((state) => state.jobs);

    return (
        <div className='h-[250px] flex flex-col gap-1'>
            <FileUploader disabled={disableUpload} classes={themeState.theme === 'light' ? 'drop-style-light' : 'drop-style-dark'} handleChange={(files) => setFile(files)} name="file" types={["PDF", "DOCX"]} />
            {
                jobsState.errors && jobsState.errors.resume ?
                    <span className='text-danger'>{jobsState.errors.resume}</span> :
                    null
            }
        </div>
    )
}

export default JobDragAndDrop