import React from 'react'

const OrderProducts = ({ products }) => {
    return (
        <div className="mx-auto max-w-6xl overflow-x-auto">
            {
                <table className="w-full text-left text-dark dark:text-light">
                    <thead className="border-b border-queen/25 dark:border-king/25 text-base md:text-lg font-semibold">
                        <tr>
                            <th scope="col" className="px-4 py-2">
                                Information
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Name
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Color
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Size
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Unit Price
                            </th>
                            <th scope="col" className="px-4 py-2">
                                Quantity
                            </th>
                        </tr>
                    </thead>
                    <tbody className='text-sm md:text-base'>
                        {
                            products.map((product, idx) => {
                                return (
                                    <tr className="border-b border-queen/25 dark:border-king/25">
                                        <td className="px-4 py-2">{product.brand} - {product.type} - {product.category} </td>
                                        <td className="px-4 py-2 ">{product.name}</td>
                                        {/* <td className="px-4 py-2 ">{product.color}</td> */}
                                        <td className="px-4 py-2 flex items-center gap-1">
                                            <div className="w-3 h-3" style={{ backgroundColor: product.color }}></div>
                                            {product.color}
                                        </td>
                                        <td className="px-4 py-2 ">{product.size}</td>
                                        <td className="px-4 py-2">${product.unitPrice}</td>
                                        <td className="px-4 py-2">{product.quantity} </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            }
        </div >
    )
}

export default OrderProducts