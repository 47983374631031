import { createSlice } from '@reduxjs/toolkit'

const theme = localStorage.getItem('theme')
if(!theme){
  localStorage.setItem("theme","dark")
}

const initialState = {
  theme: theme === "light" ? "light" : theme === "dark" ? "dark" : "dark"
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggle: (state) => {
      if(state.theme === "light")
      {
        localStorage.setItem("theme","dark")
        state.theme = "dark"
      }
      else{
        localStorage.setItem("theme","light")
        state.theme = "light"
      }
    }
  }
})

export const {toggle} = themeSlice.actions
export default themeSlice.reducer