import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from '../../Redux/Features/Orders/ordersSlice'
import { reset } from '../../Redux/Features/Cart/cartSlice';
import { FaShippingFast, FaSpinner } from 'react-icons/fa'
import { toastify } from '../../Helper'

const CartDetails = ({ cart }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const authState = useSelector((state) => state.auth);
    const cartState = useSelector((state) => state.cart);
    const ordersState = useSelector((state) => state.orders);

    const addOrderAlt = () => {
        const products = cartState.cart.map(product => {
            // Destructuring 
            // const { id, category: { name: categoryName, type: { name: typeName, brand: { name: BrandName } } }, name, color, size, price: { newPrice: unitPrice }, quantity } = product;
            // return { product_id: id, brand: BrandName, type: typeName, category: categoryName, name, color, size, unitPrice, quantity };

            return {
                product_id: product.id + "",
                brand: product.category.type.brand.name,
                type: product.category.type.name,
                category: product.category.name,
                name: product.name,
                color: product.color,
                size: product.size,
                unitPrice: product.price.newPrice,
                quantity: product.quantity,
            }
        });

        const data = {
            products
        }

        dispatch(addOrder(data))
    }

    useEffect(() => {
        if ((Date.now() - ordersState.time < 100) && ordersState.status === 201 && ordersState.message && ordersState.operation === "addOrder") {
            // toastify("success", ordersState.status, ordersState.message)
            dispatch(reset())
            setTimeout(() => {
                navigate("/user/profile")
            }, 1000);
        }
        else if ((Date.now() - ordersState.time < 100) && (ordersState.status === 400 || ordersState.status === 401 || ordersState.status === 403) && ordersState.message && ordersState.operation === "addOrder") {
            toastify("error", ordersState.status, ordersState.message)
        }
        else if ((Date.now() - ordersState.time < 100) && ordersState.status === 500 && ordersState.message && ordersState.operation === "addOrder") {
            toastify("warn", ordersState.status, ordersState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [ordersState.status, ordersState.message, ordersState.errors])

    return (
        <div className='flex flex-col gap-2'>
            <p className='text-base md:text-lg font-normal text-dark dark:text-light max-w-full'>
                You can place an order for our products by specifying the products along with their
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>sizes</span>,
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>colors</span>, and
                <span className='text-l-extra dark:text-d-extra mx-1 font-medium inline-block'>quantities</span>,
                and leave the rest to us. We will reach out to you immediately.
            </p>

            <div className='flex flex-col gap-1 text-dark dark:text-light'>
                <div className='flex gap-1'>
                    <span className='text-base md:text-lg font-medium'>Totla Items:</span>
                    <span className='text-base md:text-lg font-normal'>{cart.length}</span>
                </div>
                <div className='flex gap-1'>
                    <span className='text-base md:text-lg font-medium'>Totla Quantities:</span>
                    <span className='text-base md:text-lg font-normal'>{cart.reduce((accumulator, product) => accumulator + product.quantity, 0)}</span>
                </div>
                <div className='flex gap-1'>
                    <span className='text-base md:text-lg font-medium'>Totla Price:</span>
                    <span className='text-base md:text-lg font-normal'>${cart.reduce((accumulator, product) => accumulator + (product.price.newPrice * product.quantity), 0)}</span>
                </div>
            </div>

            <div className='mt-2'>
                {
                    authState.auth && authState.auth.user ?
                        <button onClick={() => addOrderAlt()} disabled={cart.length === 0} className='w-full sm:w-fit disabled:opacity-75 disabled:cursor-not-allowed text-base font-medium flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                            {
                                ordersState.isLoading && ordersState.operation === "addOrder" ?
                                    <><FaSpinner className='text-lg animate-spin' /> Add Order</> :
                                    <><FaShippingFast className='text-lg' /> Add Order</>
                            }
                        </button> :
                        <Link to="/login" className='w-full sm:w-fit text-base font-medium flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
                            <FaShippingFast className='text-lg' /> Add Order
                        </Link>
                }
            </div>
        </div>
    )
}

export default CartDetails