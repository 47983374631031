import axios from 'axios'

const viewCategories = async () => {
  const response = await axios.get('/api/view-categories')
  return response
}

const addCategory = async (data) => {
  const response = await axios.post('/api/add-category', data, {
    headers: {
      'Content-Category': 'multipart/form-data'
    }
  })
  return response
}

const viewCategory = async ({ id }) => {
  const response = await axios.post('/api/view-category/' + id)
  return response
}

const updateCategory = async ({ id, data }) => {
  const response = await axios.put('/api/update-category/' + id, data)
  return response
}

const deleteCategory = async ({ id }) => {
  const response = await axios.delete('/api/delete-category/' + id)
  return response
}

const searchCategories = async ({ key }) => {
  const response = await axios.get('/api/search-categories?key=' + key)
  return response
}

const categoriesService = {
  viewCategories,
  addCategory,
  viewCategory,
  updateCategory,
  deleteCategory,
  searchCategories
}

export default categoriesService