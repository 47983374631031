import React from 'react'
import SectionContainer from "../SectionContainer"
import SectionHead from "../SectionHead"
import ProductCard from "../Products/ProductCard"
import SectionLink from "../SectionLink"
import SectionText from '../SectionText'
import SectionCard from '../SectionCard'

const NewestProducts = ({ newestProducts }) => {
    return (
        <SectionContainer>
            <SectionHead title={"Newest Products"} />
            <SectionText text={"We're thrilled to introduce our latest products, blending cutting-edge technology with stylish design. Explore our newest collection for innovative gadgets and accessories today!"}/>
            <div className='my-4 md:my-6 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                {
                    newestProducts.length === 0 ?
                        Array(5).fill(0).map((item)=>{
                            return(
                                <SectionCard />
                            )
                        }) :
                        newestProducts.slice(0, 5).map((product) => {
                            return (
                                <ProductCard product={product} target={"new"} />
                            )
                        })
                }
            </div>
            <SectionLink title={"View More Newest"} to={"/products?is_new=true"} />
        </SectionContainer>
    )
}

export default NewestProducts