import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import advertisesService from './advertisesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  advertises: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewAdvertises = createAsyncThunk(
  'advertises/viewAdvertises',
  async (_, thunkAPI) => {
    try {
      const response = await advertisesService.viewAdvertises()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addAdvertise = createAsyncThunk(
  'advertises/addAdvertise',
  async (data, thunkAPI) => {
    try {
      const response = await advertisesService.addAdvertise(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewAdvertise = createAsyncThunk(
  'advertises/viewAdvertise',
  async (data, thunkAPI) => {
    try {
      const response = await advertisesService.viewAdvertise(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateAdvertise = createAsyncThunk(
  'advertises/updateAdvertise',
  async (data, thunkAPI) => {
    try {
      const response = await advertisesService.updateAdvertise(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteAdvertise = createAsyncThunk(
  'advertises/deleteAdvertise',
  async (data, thunkAPI) => {
    try {
      const response = await advertisesService.deleteAdvertise(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchAdvertises = createAsyncThunk(
  'advertises/searchAdvertises',
  async (data, thunkAPI) => {
    try {
      const response = await advertisesService.searchAdvertises(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const advertisesSlice = createSlice({
  name: 'advertises',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.advertises = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewAdvertises.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.advertises = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewAdvertises"
        state.time = Date.now()
      })
      .addCase(viewAdvertises.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = (action.payload)[0].advertises
        state.translatedAdvertise = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewAdvertises.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addAdvertise.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addAdvertise"
        state.time = Date.now()
      })
      .addCase(addAdvertise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = [...state.advertises, (action.payload)[0].advertise]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addAdvertise.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewAdvertise.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewAdvertise"
        state.time = Date.now()
      })
      .addCase(viewAdvertise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = state.advertises.map(advertise => {
          if (advertise.id === (action.payload)[0].advertise.id) {
            return (action.payload)[0].advertise
          }
          else {
            return advertise
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewAdvertise.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateAdvertise.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateAdvertise"
        state.time = Date.now()
      })
      .addCase(updateAdvertise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = state.advertises.map(advertise => {
          if (advertise.id === (action.payload)[0].advertise.id) {
            return (action.payload)[0].advertise
          }
          else {
            return advertise
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateAdvertise.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteAdvertise.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteAdvertise"
        state.time = Date.now()
      })
      .addCase(deleteAdvertise.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = state.advertises.filter(advertise => {
          return advertise.id !== (action.payload)[0].advertise.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteAdvertise.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchAdvertises.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.advertises = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchAdvertises"
        state.time = Date.now()
      })
      .addCase(searchAdvertises.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.advertises = (action.payload)[0].advertises
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchAdvertises.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = advertisesSlice.actions
export default advertisesSlice.reducer