import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import categoriesService from './categoriesService'

const initialState = {
  isLoading: null,
  isSuccess: null,
  categories: [],
  message: null,
  errors: null,
  status: null,
  operation: null,
  time: null
}

export const viewCategories = createAsyncThunk(
  'categories/viewCategories',
  async (_, thunkAPI) => {
    try {
      const response = await categoriesService.viewCategories()
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const addCategory = createAsyncThunk(
  'categories/addCategory',
  async (data, thunkAPI) => {
    try {
      const response = await categoriesService.addCategory(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const viewCategory = createAsyncThunk(
  'categories/viewCategory',
  async (data, thunkAPI) => {
    try {
      const response = await categoriesService.viewCategory(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (data, thunkAPI) => {
    try {
      const response = await categoriesService.updateCategory(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (data, thunkAPI) => {
    try {
      const response = await categoriesService.deleteCategory(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const searchCategories = createAsyncThunk(
  'categories/searchCategories',
  async (data, thunkAPI) => {
    try {
      const response = await categoriesService.searchCategories(data)
      return thunkAPI.fulfillWithValue([response.data, response.status])
    } catch (error) {
      return thunkAPI.rejectWithValue([error.response.data, error.response.status])
    }
  }
)

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = null
      state.isSuccess = null
      state.categories = []
      state.message = null
      state.errors = null
      state.status = null
      state.operation = null
      state.time = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(viewCategories.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.categories = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewCategories"
        state.time = Date.now()
      })
      .addCase(viewCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = (action.payload)[0].categories
        state.translatedCategory = null
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewCategories.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(addCategory.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "addCategory"
        state.time = Date.now()
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = [...state.categories, (action.payload)[0].category]
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(viewCategory.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "viewCategory"
        state.time = Date.now()
      })
      .addCase(viewCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = state.categories.map(category => {
          if (category.id === (action.payload)[0].category.id) {
            return (action.payload)[0].category
          }
          else {
            return category
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(viewCategory.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(updateCategory.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "updateCategory"
        state.time = Date.now()
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = state.categories.map(category => {
          if (category.id === (action.payload)[0].category.id) {
            return (action.payload)[0].category
          }
          else {
            return category
          }
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "deleteCategory"
        state.time = Date.now()
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = state.categories.filter(category => {
          return category.id !== (action.payload)[0].category.id
        })
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })

      .addCase(searchCategories.pending, (state) => {
        state.isLoading = true
        state.isSuccess = null
        state.categories = []
        state.message = null
        state.errors = null
        state.status = null
        state.operation = "searchCategories"
        state.time = Date.now()
      })
      .addCase(searchCategories.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.categories = (action.payload)[0].categories
        state.message = (action.payload)[0].message
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
      .addCase(searchCategories.rejected, (state, action) => {
        state.isLoading = false
        state.isSuccess = false
        state.message = (action.payload)[0].message
        state.errors = (action.payload)[0].errors
        state.status = (action.payload)[1]
        state.time = Date.now()
      })
  },
})

export const { reset } = categoriesSlice.actions
export default categoriesSlice.reducer