import React, { useEffect } from 'react'
import { useNavigate, useHref, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggle } from '../Redux/Features/Theme/themeSlice';
import { logout } from '../Redux/Features/Auth/authSlice';
import { Collapse, Dropdown, initTE } from "tw-elements";
import { FaShoppingCart, FaMoon, FaHeart, FaSun, FaBars, FaSignInAlt, FaSignOutAlt, FaUserAlt, FaChartBar, FaTachometerAlt, FaSpinner } from 'react-icons/fa'
import { BsMoon, BsSun } from 'react-icons/bs'
import { CgShoppingCart } from 'react-icons/cg'
import { BiUser, BiLogOut, BiLogIn, BiHeart } from 'react-icons/bi'
import { BsBarChart } from 'react-icons/bs'
import { toastify } from '../Helper';
import logo from '../Images/logo.png';

const Header = () => {
  const navigate = useNavigate();
  const href = useHref();
  // const location = useLocation();
  const dispatch = useDispatch()
  const themeState = useSelector((state) => state.theme);
  const authState = useSelector((state) => state.auth);
  const cartState = useSelector((state) => state.cart);

  const toggleTheme = () => {
    dispatch(toggle());
    setTimeout(() => {
      // navigate(0);
    }, 1000);
  }

  useEffect(() => {
    initTE({ Collapse, Dropdown });
  }, [])

  useEffect(() => {
    if ((Date.now() - authState.time < 100) && authState.status === 200 && authState.message && authState.operation === "logout") {
      // toastify("success", authState.status, authState.message)
      navigate("/login")
    }
    else if ((Date.now() - authState.time < 100) && (authState.status === 400 || authState.status === 401 || authState.status === 403) && authState.message && authState.operation === "logout") {
      toastify("error", authState.status, authState.message)
    }
    else if ((Date.now() - authState.time < 100) && authState.status === 500 && authState.message && authState.operation === "logout") {
      toastify("warn", authState.status, authState.message)
      setTimeout(() => {
        navigate(0)
      }, 5000);
    }
  }, [authState.status, authState.message, authState.errors])

  return (
    <nav className="bg-light dark:bg-dark shadow-sm shadow-queen/10 dark:shadow-king/10 flex-no-wrap fixed top-0 z-50 flex w-full items-center justify-between py-5 lg:flex-wrap lg:justify-start">
      <div className="flex gap-2 w-full flex-wrap items-center justify-between px-2 md:px-4">
        <button
          aria-controls="navbarSupportedContent1"
          aria-expanded="false"
          aria-label="Toggle navigation"
          className="text-dark dark:text-light block border-0 bg-transparent px-2 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 lg:hidden"
          data-te-collapse-init=""
          data-te-target="#navbarSupportedContent1"
          type="button"
        >
          <FaBars className='text-xl text-dark dark:text-light hover:opacity-75 transition-all duration-150' />
        </button>
        <div
          className="!visible hidden flex-grow basis-[100%] items-center lg:!flex lg:basis-auto"
          data-te-collapse-item=""
          id="navbarSupportedContent1"
        >
          <Link
            className="text-queen dark:text-king font-semibold text-base lg:text-xl my-2 ml-2 flex items-center lg:mb-0 lg:mt-0 hover:opacity-80 transition-all duration-150"
            to="/"
          >
            {/* <img className='w-24 lg:w-32 mr-1' src={logo} alt="Logo" loading="lazy" /> */}
            {/* <FaTachometerAlt className="text-lg md:text-xl font-medium mr-1" /> */}
            <span className="font-semibold text-base lg:text-xl">WMK Tech</span>
          </Link>
          <ul className="text-dark dark:text-light list-style-none mr-auto flex flex-col pl-0 lg:flex-row gap-1 ml-2 lg:ml-8">
            <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/home" ? "text-queen dark:text-king" : null}`}
                to="/home"
              >
                Home
              </Link>
            </li>
            {/* <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/about" ? "text-queen dark:text-king" : null}`}
                to="/about"
              >
                About Us
              </Link>
            </li> */}
            <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/products" ? "text-queen dark:text-king" : null}`}
                to="/products"
              >
                Products
              </Link>
            </li>
            <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/services" ? "text-queen dark:text-king" : null}`}
                to="/services"
              >
                Services
              </Link>
            </li>
            <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/jobs" ? "text-queen dark:text-king" : null}`}
                to="/jobs"
              >
                Jobs
              </Link>
            </li>
            {/* <li
              className="mb-4 lg:mb-0 lg:pr-2"
              data-te-nav-item-ref=""
            >
              <Link
                className={`hover:opacity-75 transition-all duration-150 ${href === "/advertising" ? "text-queen dark:text-king" : null}`}
                to="/advertising"
              >
                Advertising
              </Link>
            </li> */}
          </ul>
        </div>
        <div className="relative flex gap-4 lg:gap-8 items-center mx-2">
          <button title="Theme" onClick={() => toggleTheme()} className='text-l-extra dark:text-d-extra hover:opacity-75 transition-all duration-150'>
            {
              themeState.theme === "dark" ?
                <BsSun className='text-lg lg:text-xl' /> :
                <BsMoon className='text-lg lg:text-xl' />
            }
          </button>

          {
            authState.auth && authState.auth.user && authState.auth.user.role === "user" ?
              <Link
                title="Favourites"
                className={`hover:opacity-75 transition-all duration-150 ${href === "/user/favorites" ? "text-dark dark:text-light" : "text-l-extra dark:text-d-extra"}`}
                to="/user/favorites"
              >
                <BiHeart className='text-xl lg:text-2xl opacity-80' />
              </Link> :
              null
          }
          <Link
            title="Cart"
            className={`relative hover:opacity-75 transition-all duration-150 ${href === "/cart" ? "text-dark dark:text-light" : "text-l-extra dark:text-d-extra"}`}
            to="/cart"
          >
            <CgShoppingCart className='text-xl lg:text-2xl opacity-80' />
            {
              cartState.cart.length > 0 && <span className='absolute -top-2 -right-3 w-5 h-5 flex justify-center items-center text-xs rounded-full bg-queen dark:bg-king border border-light dark:border-dark text-light'>
                {(cartState.cart.reduce((accumulator, product) => accumulator + product.quantity, 0) > 9
                  ? "+9"
                  : cartState.cart.reduce((accumulator, product) => accumulator + product.quantity, 0)
                )
                }
              </span>
            }
          </Link>
          {
            authState.auth && authState.auth.user ?
              <>
                {
                  authState.auth.user.role === "user" ?
                    <Link
                      title="Profile"
                      className={`hover:opacity-75 transition-all duration-150 ${href === "/user" || href === "/user/profile" ? "text-dark dark:text-light" : "text-l-extra dark:text-d-extra"}`}
                      to="/user/profile"
                    >
                      <BiUser className='text-xl lg:text-2xl mb-[2px]' />
                    </Link> :
                    <Link
                      title="Dashboard"
                      className={`hover:opacity-75 transition-all duration-150 ${href === "/admin" || href === "/admin/dashboard" ? "text-dark dark:text-light" : "text-l-extra dark:text-d-extra"}`}
                      to="/admin/dashboard"
                    >
                      <BsBarChart className='text-lg lg:text-xl font-light' />
                    </Link>
                }
                <button title="Logout" onClick={() => dispatch(logout())} className='text-l-extra dark:text-d-extra hover:opacity-75 transition-all duration-150'>
                  {
                    authState.isLoading && authState.operation === "logout" ?
                      <FaSpinner className='text-lg lg:text-xl animate-spin mb-1' /> :
                      <BiLogOut className='text-xl lg:text-2xl' />
                  }
                </button>
              </> :
              <Link
                title='Login'
                className={`hover:opacity-75 transition-all duration-150 ${href === "/login" ? "text-dark dark:text-light" : "text-l-extra dark:text-d-extra"}`}
                to="/login"
              >
                <BiLogIn className='text-xl lg:text-2xl' />
              </Link>
          }
        </div>
      </div>
    </nav>
  )
}

export default Header