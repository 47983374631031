import React, { useEffect, useState } from 'react'
import Container from "../Components/Container"
import Slider from "../Components/Home/Slider"
import NewestProducts from "../Components/Home/NewestProducts"
import OffersProducts from "../Components/Home/OffersProducts"
import NewestServices from "../Components/Home/NewestServices"
import NewestJobs from "../Components/Home/NewestJobs"
import ContainerAlt from '../Components/ContainerAlt'

const Home = () => {
  const [newestProducts, setNewestProducts] = useState("")
  const [offersProducts, setOffersProducts] = useState("")
  const [newestServices, setNewestServices] = useState("")
  const [newestJobs, setNewestJobs] = useState("")

  useEffect(() => {
    fetch(process.env.REACT_APP_API_BASE_URL + '/api/home-informations')
      .then(response => response.json())
      .then(data => {
        setNewestProducts(data?.data?.newestProducts)
        setOffersProducts(data?.data?.offersProducts)
        setNewestServices(data?.data?.newestServices)
        setNewestJobs(data?.data?.newestJobs)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  return (
    <div>
      <Slider /> {/* Loading... Don't Forget! */}
      
      {/* <Container>
        <NewestProducts newestProducts={newestProducts} />
        <OffersProducts offersProducts={offersProducts} />
        <NewestServices newestServices={newestServices} />
        <NewestJobs newestJobs={newestJobs} />
      </Container> */}

      <Container>
        <NewestProducts newestProducts={newestProducts} />
        <OffersProducts offersProducts={offersProducts} />
      </Container>

      <ContainerAlt>
        <NewestServices newestServices={newestServices} />
      </ContainerAlt>

      <Container>
        <NewestJobs newestJobs={newestJobs} />
      </Container>
    </div>
  )
}

export default Home