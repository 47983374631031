import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewRequests, searchRequests } from "../../../Redux/Features/Requests/requestsSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaChevronDown, FaChevronUp, FaSpinner } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';
import RequestUsers from './RequestUsers';

const Requests = () => { 
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const requestsState = useSelector((state) => state.requests);
    const searchInput = useRef();
    const [openRequest, setOpenRequest] = useState(null)

    const searchRequestsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchRequests(data))
    }

    useEffect(() => {
        setOpenRequest(requestsState?.requests[0]?.id)
    }, [requestsState.requests])

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewRequests())
    }, [])

    useEffect(() => {
        if ((Date.now() - requestsState.time < 100) && requestsState.status === 200 && requestsState.message && (requestsState.operation === "viewRequests" || requestsState.operation === "searchRequests" || requestsState.operation === "updateRequest")) {
            // toastify("success", requestsState.status, requestsState.message)
        }
        else if ((Date.now() - requestsState.time < 100) && (requestsState.status === 400 || requestsState.status === 401 || requestsState.status === 403 /*|| requestsState.status === 404*/) && requestsState.message && (requestsState.operation === "viewRequests" || requestsState.operation === "searchRequests" || requestsState.operation === "updateRequest")) {
            toastify("error", requestsState.status, requestsState.message)
        }
        else if ((Date.now() - requestsState.time < 100) && requestsState.status === 500 && requestsState.message && (requestsState.operation === "viewRequests" || requestsState.operation === "searchRequests" || requestsState.operation === "updateRequest")) {
            toastify("warn", requestsState.status, requestsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [requestsState.status, requestsState.message, requestsState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Requestes
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by user email or service title"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => { searchRequestsAlt() }}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    requestsState.isLoading && requestsState.operation !== "updateRequest" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Title
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        ...
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="4"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Requests
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        requestsState.requests.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Title
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Description
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="4"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Requests Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Title
                                        </th>
                                        <th scope="col" className="px-4 py-2">
                                            Description
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px]">
                                            ...
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        requestsState.requests.map((request, idx) => {
                                            return (
                                                <>
                                                    <tr className="border-b border-queen/50 dark:border-king/50">
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">{request.title}</td>
                                                        <td className="px-4 py-2">{request.description}</td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                <button
                                                                    onClick={() => setOpenRequest(openRequest === request.id ? null : request.id)}
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                >
                                                                    {
                                                                        openRequest === request.id ?
                                                                            <FaChevronUp className='text-base md:text-lg' /> :
                                                                            <FaChevronDown className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        openRequest === request.id ?
                                                            <td colspan="4" className='bg-light dark:bg-dark'>
                                                                <RequestUsers users={request.users} />
                                                            </td> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Requests