import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { viewProduct } from '../../Redux/Features/Products/productsSlice';
import { toastify } from '../../Helper';
import LayoutBanner from '../../Components/LayoutBanner';
import Container from '../../Components/Container';
import ProductImages from '../../Components/Products/ProductImages';
import ProductDetails from '../../Components/Products/ProductDetails';
import LayoutLoading from '../../Components/LayoutLoading';
import LayoutNotFound from '../../Components/LayoutNotFound';

const Product = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const productsState = useSelector((state) => state.products);
    const favoritesState = useSelector((state) => state.favorites);
    const [product, setProduct] = useState(null)

    useEffect(() => {
        dispatch(viewProduct({ id }))
    }, [dispatch, id])

    useEffect(() => {
        const product = (productsState.products.filter((product) => {
            return parseInt(product.id) === parseInt(id)
        }))[0]
        setProduct(product)
    }, [productsState.products])

    useEffect(() => {
        if ((Date.now() - productsState.time < 100) && productsState.status === 200 && productsState.message && productsState.operation === "viewProduct") {
            // toastify("success", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && (productsState.status === 400 || productsState.status === 401 || productsState.status === 403 /*|| productsState.status === 404*/) && productsState.message && (productsState.operation === "viewProduct")) {
            toastify("error", productsState.status, productsState.message)
        }
        else if ((Date.now() - productsState.time < 100) && productsState.status === 500 && productsState.message && (productsState.operation === "viewProduct")) {
            toastify("warn", productsState.status, productsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [productsState.status, productsState.message, productsState.errors])

    return (
        <>
            <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Products", to: "/products" }, { title: product?.name || "Unknown", to: `/products/${id}` }]} />
            {
                productsState.isLoading && productsState.operation === "viewProduct" ?
                    <LayoutLoading title={"product"} /> :
                    !product ?
                        <LayoutNotFound title={"Product"} /> :
                        <div>
                            {/* <LayoutBanner hrefs={[{ title: "Home", to: "/home" }, { title: "Products", to: "/products" }, { title: product.name, to: `/products/${id}` }]} /> */}
                            <Container>
                                <div className='grid sm:grid-cols-12 gap-4 md:gap-8 my-4'>
                                    <div className="sm:col-span-6 md:col-span-4">
                                        <ProductImages product={product} />
                                    </div>
                                    <div className="sm:col-span-6 md:col-span-8">
                                        <ProductDetails product={product} />
                                    </div>
                                </div>
                            </Container>
                        </div>
            }
        </>
    )
}

export default Product