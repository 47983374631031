import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addReview } from '../../Redux/Features/Jobs/jobsSlice';
import { FaSpinner, FaUserTie, FaUserTimes } from 'react-icons/fa'
import { toastify } from '../../Helper';

const JobDetails = ({ job, setDisableUpload, file }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const jobsState = useSelector((state) => state.jobs);
  const [openNote, setOpenNote] = useState(false)

  const addReviewAlt = () => {
    if (!authState.auth) {
      setDisableUpload(true);
      setOpenNote(true)
    } else {
      const data = {
        resume: file
      }
      console.log(data)
      dispatch(addReview({ data, id: job.id }))
    }
  }

  useEffect(() => {
    if ((Date.now() - jobsState.time < 100) && jobsState.status === 200 && jobsState.message && jobsState.operation === "addReview") {
      // toastify("success", jobsState.status, jobsState.message)
    }
    else if ((Date.now() - jobsState.time < 100) && (jobsState.status === 400 || jobsState.status === 401 || jobsState.status === 403 || jobsState.status === 404) && jobsState.message && (jobsState.operation === "addReview")) {
      toastify("error", jobsState.status, jobsState.message)
    }
    else if ((Date.now() - jobsState.time < 100) && jobsState.status === 500 && jobsState.message && (jobsState.operation === "addReview")) {
      toastify("warn", jobsState.status, jobsState.message)
      setTimeout(() => {
        // navigate(0)
      }, 5000);
    }
  }, [jobsState.status, jobsState.message, jobsState.errors])

  return (
    <div>
      <div className='flex flex-col gap-1 text-dark dark:text-light'>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Position:</span>
          <span className='text-base md:text-lg font-medium'>{job.position}</span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Identifier:</span>
          <span className='text-base md:text-lg font-normal'>{job.identifier}</span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>
            Description:
            <span className='ml-1 text-base md:text-lg font-normal'>{job.description}</span>
          </span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>
            Knowledge:
            <span className='ml-1 text-base md:text-lg font-normal'>{job.knowledge}</span>
          </span>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Location:</span>
            <span className='text-base md:text-lg font-normal capitalize'>{job.location}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Time:</span>
            <span className='text-base md:text-lg font-normal capitalize'>{job.time}</span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 gap-1'>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Level:</span>
            <span className='text-base md:text-lg font-normal capitalize'>{job.level}</span>
          </div>
          <div className='flex gap-1 items-center'>
            <span className='text-base md:text-lg font-semibold'>Avg Salary:</span>
            <span className='text-base md:text-lg font-normal capitalize'>${job.avg_salary || " ..."}</span>
          </div>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Status:</span>
          <span className='text-base md:text-lg font-normal text-l-extra dark:text-d-extra'>
            ({
              job.users?.filter((user) => {
                return user.id == authState?.auth?.user?.id
              })[0]?.id && job.users?.filter((user) => {
                return user.id == authState?.auth?.user?.id
              })[0]?.pivot?.is_accepted == 0 ?
                <span className=''>We will provide you with an answer as soon as possible.</span> :
                job.users?.filter((user) => {
                  return user.id == authState?.auth?.user?.id
                })[0]?.id && job.users?.filter((user) => {
                  return user.id == authState?.auth?.user?.id
                })[0]?.pivot?.is_accepted == 1 ?
                  <span className=''>We have addressed your inquiry. Kindly check your email for our response.</span> :
                  <span className=''>You have not made a review for this job yet.</span>
            })
          </span>
        </div>
        <div className='flex gap-1 items-center'>
          <span className='text-base md:text-lg font-semibold'>Reviewed by:</span>
          <span className='text-base md:text-lg font-normal'>{job.users?.length} user </span>
        </div>

        <div className='grid md:grid-cols-4 gap-2 mt-4'>
          <button onClick={() => addReviewAlt()}  className='text-base font-semibold flex gap-2 justify-center items-center hover:text-light dark:hover:text-light hover:bg-queen dark:hover:bg-king border hover:border-transparent px-4 py-2 rounded-md border-queen/25 dark:border-king/25 bg-queen/[0.025] dark:bg-king/[0.025] text-queen dark:text-king duration-150 shadow-inner shadow-queen/10 dark:shadow-king/10'>
            {
              job.users?.filter((user) => {
                return user.id == authState?.auth?.user?.id
              })[0]?.id ?
                <>
                  {setDisableUpload(true)}
                  {jobsState.isLoading && jobsState.operation === "addReview" ? <FaSpinner className='text-lg animate-spin' /> : <FaUserTimes className='text-lg' />}
                  Withdraw Now
                </> :
                <>
                  {authState.auth && setDisableUpload(false)}
                  {jobsState.isLoading && jobsState.operation === "addReview" ? <FaSpinner className='text-lg animate-spin' /> : <FaUserTie className='text-lg' />}
                  Apply Now
                </>
            }
          </button>
        </div>
      </div>
      {
        openNote ?
          <div className='mt-4 text-dark dark:text-light text-base md:text-lg font-normal'>
            <div className=''>
              You must login first, from <Link to={"/login"} className='text-l-extra dark:text-d-extra border-b border-l-extra/50 dark:border-d-extra/50 hover:opacity-80 duration-150'>here</Link>
            </div>
          </div> :
          null
      }
    </div>
  )
}

export default JobDetails 