import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewBrands, searchBrands, deleteBrand, updateBrand, addBrand } from "../../../Redux/Features/Brands/brandsSlice";
import { Ripple, Tooltip, initTE } from "tw-elements";
import { FaBoxOpen, FaEdit, FaPlus, FaSave, FaSpinner, FaTimes, FaTrash } from 'react-icons/fa';
import { toastify } from '../../../Helper';
import Container from '../../../Components/Container';

const Brands = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const brandsState = useSelector((state) => state.brands);
    const searchInput = useRef();
    const nameInput = useRef();
    const descriptionInput = useRef();
    const imageInput = useRef();
    const [openAdd, setOpenAdd] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(null)
    const [showAddErrors, setShowAddErrors] = useState(true)
    const [showUpdateErrors, setShowUpdateErrors] = useState(null)
    const [loadAdd, setLoadAdd] = useState(false)
    const [loadUpdate, setLoadUpdate] = useState(null)
    const [loadDelete, setLoadDelete] = useState(null)

    const searchBrandsAlt = () => {
        const data = {
            key: searchInput.current.value
        }
        dispatch(searchBrands(data))
    }

    const addBrandAlt = () => {
        let data = {
            name: nameInput.current.value,
            description: descriptionInput.current.value,
            image: imageInput.current.files[0]
        }

        dispatch(addBrand(data))
    }

    const updateBrandAlt = (brand_id) => {
        let data = {
            name: nameInput.current.value,
            description: descriptionInput.current.value,
            image: imageInput.current.files[0]
        }

        data = Object.entries(data).reduce((acc, [key, value]) => {
            if (value !== '' && value !== undefined) {
                acc[key] = value;
            }
            return acc;
        }, {});

        if (Object.keys(data).length > 0) {
            dispatch(updateBrand({ data, id: brand_id }))
        }
    }

    const deleteBrandAlt = (brand_id) => {
        const data = {
            id: brand_id
        }

        dispatch(deleteBrand(data))
    }

    useEffect(() => {
        initTE({ Ripple, Tooltip });
        dispatch(viewBrands())
    }, [])

    useEffect(() => {
        if ((Date.now() - brandsState.time < 100) && (brandsState.status === 200 || brandsState.status === 201) && brandsState.message && (brandsState.operation === "viewBrands" || brandsState.operation === "searchBrands" || brandsState.operation === "addBrand" || brandsState.operation === "updateBrand" || brandsState.operation === "deleteBrand")) {
            // toastify("success", brandsState.status, brandsState.message)
        }
        else if ((Date.now() - brandsState.time < 100) && (brandsState.status === 400 || brandsState.status === 401 || brandsState.status === 403 /*|| brandsState.status === 404*/) && brandsState.message && (brandsState.operation === "viewBrands" || brandsState.operation === "searchBrands" || brandsState.operation === "addBrand" || brandsState.operation === "updateBrand" || brandsState.operation === "deleteBrand")) {
            toastify("error", brandsState.status, brandsState.message)
        }
        else if ((Date.now() - brandsState.time < 100) && brandsState.status === 500 && brandsState.message && (brandsState.operation === "viewBrands" || brandsState.operation === "searchBrands" || brandsState.operation === "addBrand" || brandsState.operation === "updateBrand" || brandsState.operation === "deleteBrand")) {
            toastify("warn", brandsState.status, brandsState.message)
            setTimeout(() => {
                // navigate(0)
            }, 5000);
        }
    }, [brandsState.status, brandsState.message, brandsState.errors])

    return (
        <Container>
            <div className='mt-4 mb-8'>
                <h1 className='text-center text-2xl md:text-4xl text-queen dark:text-king font-bold mb-8'>
                    Brands
                </h1>
                <div className="relative flex w-full md:max-w-md lg:max-w-lg mx-auto">
                    <input
                        type="search"
                        className="bg-queenAlt dark:bg-kingAlt text-dark dark:text-light placeholder:text-dark/50 dark:placeholder:text-light/50 border-queen/50 focus:border-queen dark:border-king/50 dark:focus:border-king relative m-0 -mr-0.5 block min-w-0 flex-auto rounded-sm border border-solid bg-clip-padding px-3 py-2 text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:outline-none "
                        placeholder="Search by name or description"
                        aria-label="Search"
                        aria-describedby="button-addon1"
                        ref={searchInput}
                        onChange={() => searchBrandsAlt()}
                    />
                </div>
            </div>
            <div className="mx-auto max-w-6xl overflow-x-auto">
                {
                    brandsState.isLoading && brandsState.operation !== "addBrand" && brandsState.operation !== "updateBrand" && brandsState.operation !== "deleteBrand" ?
                        <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                            <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                <tr>
                                    <th scope="col" className="px-4 py-2">
                                        #
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Name
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Description
                                    </th>
                                    <th scope="col" className="px-4 py-2">
                                        Image
                                    </th>
                                    <th scope="col" className="px-4 py-2 w-[100px]">
                                        <button
                                            type="button"
                                            className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                            <FaPlus className='text-base md:text-lg' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className='text-sm md:text-base'>
                                <tr className="border-b border-queen/50 dark:border-king/50 ">
                                    <td
                                        colspan="5"
                                        className="px-4 py-4 text-center">
                                        <FaSpinner className='animate-spin text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                        Loading Brands
                                    </td>
                                </tr>
                            </tbody>
                        </table> :
                        brandsState.brands.length === 0 ?
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50 text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addBrandAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && brandsState.isLoading && brandsState.operation === "addBrand" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && brandsState.errors && brandsState.operation === "addBrand" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.name && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.description && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.image && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    <tr className="border-b border-queen/50 dark:border-king/50 ">
                                        <td
                                            colspan="5"
                                            className="px-4 py-4 text-center">
                                            <FaBoxOpen className='text-queen dark:text-king inline mr-2 text-xl md:text-2xl mb-1' />
                                            No Brands Found
                                        </td>
                                    </tr>
                                </tbody>
                            </table> :
                            <table className="w-full text-left text-dark dark:text-light border border-queen/50 dark:border-king/50">
                                <thead className="border-b border-t bg-queenAlt dark:bg-kingAlt border-queen/50 dark:border-king/50  text-base md:text-lg font-bold">
                                    <tr>
                                        <th scope="col" className="px-4 py-2">
                                            #
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={nameInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                    "Name"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={descriptionInput} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                    "Description"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 relative">
                                            {
                                                openAdd ?
                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                    "Image"
                                            }
                                        </th>
                                        <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                            {
                                                openAdd ?
                                                    <button
                                                        onClick={() => { setOpenAdd(false); setLoadAdd(true); addBrandAlt(); setShowAddErrors(true) }}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaSave className='text-base md:text-lg' />
                                                    </button> :
                                                    <button
                                                        type="button"
                                                        onClick={() => setOpenAdd(true)}
                                                        className="px-2 inline-block w-full leading-tight text-dark dark:text-light hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        {
                                                            loadAdd && brandsState.isLoading && brandsState.operation === "addBrand" ?
                                                                <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                <FaPlus className='text-base md:text-lg' />
                                                        }
                                                    </button>
                                            }
                                        </th>
                                    </tr>
                                    {
                                        showAddErrors && brandsState.errors && brandsState.operation === "addBrand" ?
                                            <tr className='text-queen dark:text-king'>
                                                <th scope="col" className="px-4 py-2">
                                                    ...
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.name && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.name : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.description && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.description : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2">
                                                    {
                                                        brandsState.errors && brandsState.errors.image && brandsState.operation === "addBrand" ?
                                                            brandsState.errors && brandsState.errors.image : "..."
                                                    }
                                                </th>
                                                <th scope="col" className="px-4 py-2 w-[100px] text-right">
                                                    <button
                                                        onClick={() => setShowAddErrors(false)}
                                                        type="button"
                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                        <FaTimes className='text-base md:text-lg' />
                                                    </button>
                                                </th>
                                            </tr> :
                                            null
                                    }
                                </thead>
                                <tbody className='text-sm md:text-base'>
                                    {
                                        brandsState.brands.map((brand, idx) => {
                                            return (
                                                <>
                                                    <tr className={showUpdateErrors === brand.id && brandsState.errors && brandsState.operation === "updateBrand" ? "" : "border-b border-queen/50 dark:border-king/50  "}>
                                                        <td className="px-4 py-2 border-r border-queen/50 dark:border-king/50 w-12">{idx + 1}</td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === brand.id ?
                                                                    <input ref={nameInput} defaultValue={brand.name} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Name' type='text' /> :
                                                                    brand.name
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === brand.id ?
                                                                    <input ref={descriptionInput} defaultValue={brand.description} className='border-b pb-0.5 w-full border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Description' type='text' /> :
                                                                    brand.description || "..."
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2">
                                                            {
                                                                openUpdate === brand.id ?
                                                                    <input ref={imageInput} className='border-b pb-0.5 border-queen/50 dark:border-king/50  bg-transparent focus:outline-none' placeholder='Image' type='file' /> :
                                                                    brand.image ?
                                                                        <a target='_blank' href={process.env.REACT_APP_API_BASE_URL +"/"+ brand.image} className='hover:opacity-75 transition-all duration-150 pb-0.5 px-1 border-b border-queen/50 dark:border-king/50' rel="noreferrer">View</a> :
                                                                        <span className='px-1'>...</span>
                                                            }
                                                        </td>
                                                        <td className="px-4 py-2 text-right">
                                                            <div
                                                                className="flex w-full"
                                                                role="group"
                                                            >
                                                                {
                                                                    openUpdate === brand.id ?
                                                                        <button
                                                                            onClick={() => { setOpenUpdate(null); setLoadUpdate(brand.id); updateBrandAlt(brand.id); setShowUpdateErrors(brand.id) }}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            <FaSave className='text-base md:text-lg' />
                                                                        </button> :
                                                                        <button
                                                                            onClick={() => setOpenUpdate(brand.id)}
                                                                            type="button"
                                                                            className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                        >
                                                                            {
                                                                                loadUpdate === brand.id && brandsState.isLoading && brandsState.operation === "updateBrand" ?
                                                                                    <FaSpinner className=' animate-spin text-base md:text-lg' /> :
                                                                                    <FaEdit className='text-base md:text-lg' />
                                                                            }
                                                                        </button>
                                                                }
                                                                <button
                                                                    type="button"
                                                                    className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0"
                                                                    onClick={() => { setLoadDelete(brand.id); deleteBrandAlt(brand.id) }}
                                                                >
                                                                    {
                                                                        loadDelete === brand.id && brandsState.isLoading && brandsState.operation === "deleteBrand" ?
                                                                            <FaSpinner className='animate-spin text-base md:text-lg' /> :
                                                                            <FaTrash className='text-base md:text-lg' />
                                                                    }
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {
                                                        showUpdateErrors === brand.id && brandsState.errors && brandsState.operation === "updateBrand" ?
                                                            <tr className='border-b border-queen/50 dark:border-king/50  text-queen dark:text-king'>
                                                                <td className="px-4 py-2">
                                                                    ...
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        brandsState.errors && brandsState.errors.name && brandsState.operation === "updateBrand" ?
                                                                            brandsState.errors && brandsState.errors.name : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        brandsState.errors && brandsState.errors.description && brandsState.operation === "updateBrand" ?
                                                                            brandsState.errors && brandsState.errors.description : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2">
                                                                    {
                                                                        brandsState.errors && brandsState.errors.image && brandsState.operation === "updateBrand" ?
                                                                            brandsState.errors && brandsState.errors.image : "..."
                                                                    }
                                                                </td>
                                                                <td className="px-4 py-2 w-[100px] text-right">
                                                                    <button
                                                                        onClick={() => setShowUpdateErrors(null)}
                                                                        type="button"
                                                                        className="px-2 inline-block w-full leading-tight text-queen dark:text-king hover:opacity-80 transition duration-150 focus:outline-none focus:ring-0">
                                                                        <FaTimes className='text-base md:text-lg' />
                                                                    </button>
                                                                </td>
                                                            </tr> :
                                                            null
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                }
            </div >
        </Container >
    )
}

export default Brands